import * as React from 'react';
import { useState, useEffect } from 'react';
import * as Livestream from '../Types/Livestream';
import Perspective from '../Types/Perspective';
import EventCard from './EventCard';

type Props =
  { events: Livestream.Event[]
  , perspective: Perspective
  , targetBlank?: boolean
  }

const EventGrid = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [filteredEvents, setFilteredEvents] = useState(props.events);

  // This hook serves to re-sort and re-filter the list of events when the search text is changed
  useEffect(() => {
    const filtered = props.events.filter((event) => {
      const searchFields = [
        event.title,
        event.description,
        event.copilotIndividual?.preferredName
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredEvents(filtered);
  }, [searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  return (
    <>
      <div className="row mt-3 align-items-center">
        <div className="col-md-8">
          <div className="input-group align-items-center p-0 bg-white">
            <div className="input-group-prepend ms-2">
              <i className="ai-search"></i>
            </div>
            <input
              className="form-control"
              type="search"
              value={searchText}
              onChange={handleSearchInputChange}
              placeholder="Search by title, description, or host…"
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        {filteredEvents.map((livestream) => (
        <div className="col-md-6 col-lg-4 mb-2">
            <EventCard event={livestream} perspective={props.perspective} targetBlank={props.targetBlank}/>
        </div>
        ))}
      </div>
    </>
  )
}

export default EventGrid;
