import * as React from 'react';
import * as API from '../Utils/API.js';

/**
  * Renders a login button (as a React Element), with the specified HTML class and button text.
  *
  * Note that this uses a hardcoded URL for the login path; if we were to call our API.getEndpoint
  * function when trying to link the URL to the form, we'd get a mismatch between server and client rendering
  * (the API.getEndpoint function does not work on the server, as our endpoints aren't made available until page load).
  */
export const LoginButton = ({className, buttonText }: {className: string, buttonText: string}) => {
  return (
    <form
      className=""
      method='POST'
      action={"/users/auth/auth0?screen_hint=login"}
    >
      <button className={className} type="submit">{buttonText}</button>
      <input
        hidden={true}
        readOnly={true}
        name="authenticity_token"
        value={API.getEndpoint('user_auth0_omniauth_authorize_path').token ?? ''}
      />
    </form>
  )
}

/**
  * Renders a signup button (as a React Element), with the specified HTML class and button text.
  *
  * Note that this uses a hardcoded URL for the signup path; if we were to call our API.getEndpoint
  * function when trying to link the URL to the form, we'd get a mismatch between server and client rendering
  * (the API.getEndpoint function does not work on the server, as our endpoints aren't made available until page load).
  */
export const SignupButton = ({className, buttonText }: {className: string, buttonText: string}) => {
  return (
    <form
      className=""
      method='POST'
      action={"/users/auth/auth0?screen_hint=signup"}
    >
      <button className={className} type="submit">{buttonText}</button>
      <input
        hidden={true}
        readOnly={true}
        name="authenticity_token"
        value={API.getEndpoint('user_auth0_omniauth_authorize_path').token ?? ''}
      />
    </form>
  )
}
