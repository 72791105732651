import * as React from 'react';
import { useState } from 'react';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { formatUSDWithoutCents } from '../Utils/Currency';
import { Package } from '../Types/Package';

function agentPackageDetailPath(pkg: Package) {
  return `/agent/packages/${pkg.id}`
}

export const PackageCard = (pkg: Package) => {
  return (
    <a key={pkg.id} className="text-decoration-none" href={agentPackageDetailPath(pkg)} target="_blank">
      <div className={`card h-100 t--package-card-for-id-${pkg.id}`}>
        {maybeHtml(pkg.imageUrl, (imageUrl) => (
          <img className="card-img-top" src={imageUrl}/>
        ))}
        <div className="card-body d-flex flex-column">
          <h6 className="card-title mb-1">{pkg.title}</h6>
          {maybeHtml(pkg.copilotIndividual, (copilot) => (
            <div className="d-flex align-items-center mt-1 mb-1">
              <div className="avatar avatar-sm">
                  <img className="avatar-img border border-white border-4 border-card rounded-circle" loading='lazy' src={copilot.imageUrl} />
              </div>
              <div className="ms-1">
                <div className="fs-sm">
                  Created by {copilot.preferredName}
                  {maybeHtml(copilot.averageRating, (rating) => (
                    <div className="mt-0">
                      <i className="ai-star me-1" />
                      {rating}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
          {htmlIf(pkg.tags.length > 0,
            <div className="d-flex align-items-center flex-wrap mt-1 mb-2">
              {pkg.tags.map((tag) => (
                <div key={`tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.name}</div>
              ))}
            </div>
          )}
          {/* Description */}
          {/* - unless hide_description
            .fs-sm.mt-2.mb-2= package.description */}
          <div className="text-dark fw-bold mt-auto">
            { pkg.hourlyRateCents
            ? `From ${formatUSDWithoutCents(pkg.hourlyRateCents)}/hour`
            : `From ${formatUSDWithoutCents(pkg.basicAmountCents)}`
            }
          </div>
        </div>
      </div>
    </a>
  )
}

export default PackageCard;
