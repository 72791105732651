import * as React from 'react';
import Select from 'react-select';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../Utils/API';
import { AgentInternal } from '../../Types/Agent';
import { Tag, TagType, tagToReactSelectItem, reactSelectItemToTag } from '../../Types/Tag';
import { htmlIf, formatMultiParagraphString } from '../../Utils/HTML';
import * as Currency from '../../Utils/Currency';
import * as Language from '../../Utils/Language';
import * as Location from '../../Utils/Location';

type Props =
  { agent: AgentInternal
  , allTags: Tag[]
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

const AboutMe = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const [firstName, setFirstName] = useState(props.agent.user.firstName);
  const [lastName, setLastName] = useState(props.agent.user.lastName);
  const [country, setCountry] = useState(Location.countryAsSelectOption(props.agent.user.country));
  const [currency, setCurrency] = useState(Currency.asSelectOption(props.agent.user.currency));
  const [pronouns, setPronouns] = useState(props.agent.pronouns);
  const [businessName, setBusinessName] = useState(props.agent.businessName);
  const [bio, setBio] = useState(props.agent.bio);
  const [selectedLanguages, setSelectedLanguages] = useState(props.agent.languageCodes.map((code) => Language.asSelectOption(code)));

  const [technologyTags, setTechnologyTags] = useState(
    props.agent.tags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))
  );
  const [supportTags, setSupportTags] = useState(
    props.agent.tags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))
  );

  function saveAboutMe() {
    const allSelectedTagItems = Array.from(new Set([...technologyTags, ...supportTags]));
    const tags: Tag[] = allSelectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));

    const postBody = {
      firstName: firstName,
      lastName: lastName,
      country: country.value,
      currency: currency.value,
      pronouns: pronouns,
      businessName: businessName,
      bio: bio,
      languageCodes: selectedLanguages.map((languageItem: ReactSelectOption) => languageItem.value),
      tags: tags,
    }

    API.post("agent_account_update_profile_info_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between py-1">
        <h4 className="mb-0">About Me</h4>
        {htmlIf(!isEditing,
          <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
            <i className="ai-edit-alt me-1"></i>
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-lg-6">
            <h5 className="mb-1">First Name</h5>
            { isEditing
            ? <input
                onChange={(e) => setFirstName(e.target.value)}
                className="form-control"
                value={firstName}
              />
            : <div>{firstName}</div>
            }
          </div>
          <div className="col-lg-6 mt-2 mt-lg-0">
            <h5 className="mb-1">Last Name</h5>
            { isEditing
            ? <input
                onChange={(e) => setLastName(e.target.value)}
                className="form-control"
                value={lastName}
              />
            : <div>{lastName}</div>
            }
          </div>
          <div className="col-lg-6 mt-2">
            <h5 className="mb-1">Country</h5>
            { isEditing
            ? <Select
                className="rounded mt-1 t--country-select"
                classNames={{
                  control: (state) => 'form-control p-1 rounded-2',
                  multiValue: (state) => 'rounded-1',
                  dropdownIndicator: (state) => 'py-0'
                }}
                options={Location.allCountriesAsSelectOptions()}
                onChange={(newCountry: ReactSelectOption) => setCountry(newCountry)}
                value={country}
                placeholder="Select the country you are based in…"
              />
            : <div>{country.label}</div>
            }
          </div>
          <div className="col-lg-6 mt-2">
            <h5 className="mb-1">Currency</h5>
            { isEditing
            ? <Select
                className="rounded mt-1 t--curency-select"
                classNames={{
                  control: (state) => 'form-control p-1 rounded-2',
                  multiValue: (state) => 'rounded-1',
                  dropdownIndicator: (state) => 'py-0'
                }}
                options={Currency.allAsSelectOptions()}
                onChange={(newCurrency: ReactSelectOption) => setCurrency(newCurrency)}
                value={currency}
                placeholder="Select your preferred currency…"
              />
            : <div>{currency.label}</div>
            }
          </div>
          <div className="col-lg-6 mt-2">
            <h5 className="mb-1">Pronouns</h5>
            { isEditing
            ? <input
                onChange={(e) => setPronouns(e.target.value)}
                className="form-control"
                value={pronouns}
              />
            : <div>{pronouns}</div>
            }
          </div>
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Business Name</h5>
            { isEditing
            ? <input
                onChange={(e) => setBusinessName(e.target.value)}
                className="form-control"
                value={businessName}
                placeholder="What is the name of your business?"
              />
            : <div>{businessName}</div>
            }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Introduce Yourself</h5>
          { isEditing
          ? <textarea
              onChange={(e) => setBio(e.target.value)}
              className="form-control"
              rows={5}
              placeholder='Help CoPilots get to know you…'
              maxLength={5000}
              value={bio}
            />
          : <div>{formatMultiParagraphString(bio)}</div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Languages</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={Language.allAsSelectOptions()}
              onChange={(newLanguages: ReactSelectOption[]) => setSelectedLanguages(newLanguages)}
              value={selectedLanguages}
              placeholder="Select any languages you speak fluently…"
            />
          : <div>
              {selectedLanguages.map((languageItem) => (
                <div key={languageItem.value} className="badge bg-accent text-dark me-1 d-inline-block">{languageItem.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">My Technologies</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newTechnologyTags: ReactSelectOption[]) => setTechnologyTags(newTechnologyTags)}
              value={technologyTags}
            />
          : <div>
              {technologyTags.map((tag) => (
                <div key={tag.label} className="badge bg-accent text-dark me-1 mb-1 d-inline-block">{tag.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <h5 className="mb-1">Where I Need Support</h5>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newSupportTags: ReactSelectOption[]) => setSupportTags(newSupportTags)}
              value={supportTags}
            />
          : <div>
              {supportTags.map((tag) => (
                <div key={tag.label} className="badge bg-accent text-dark me-1 mb-1 d-inline-block">{tag.label}</div>
              ))}
            </div>
          }
        </div>
        {htmlIf(isEditing,
          <button onClick={saveAboutMe} className="btn btn-primary mt-3 px-3">Save</button>
        )}
      </div>
    </div>
  )
}

export default AboutMe;
