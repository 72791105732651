import * as React from 'react';
import { Copilot } from '../Types/Copilot';
import { CopilotSearchSort } from './Components/CopilotSearchSort';
import { parameterize } from '../Utils/HTML';
import { CurrencyCode, ExchangeRate } from '../Utils/Currency';

type Props =
  { copilots: Copilot[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  }

const CopilotList = (props: Props) => {
  return (
    <div className="card">
      <div className="card-body">
        <h4 className="fw-bold">Select a CoPilot</h4>
        <CopilotSearchSort copilots={props.copilots}
          displayCurrency={props.displayCurrency} exchangeRates={props.exchangeRates}
          featuredTags={[]}
          cardButtons={
            [
              { btnClass: "btn btn-outline-primary"
              , btnLabel: "View Profile"
              , onClick: (event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot) => {
                window.open(`/agent/copilots/profile/${copilot.id}-${parameterize(copilot.preferredName)}`, '_blank')
              }
              }
            ]
          }
        />
      </div>
    </div>
  )
}

export default CopilotList;
