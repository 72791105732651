import * as React from 'react';
import { useState, useRef } from 'react';
import * as API from '../Utils/API.js';
import * as Currency from '../Utils/Currency'
import * as Language from '../Utils/Language';
import * as Location from '../Utils/Location';
import Select from 'react-select';
import { Property } from '../Types/Property';
import { Tag, TagType, tagToReactSelectItem, reactSelectItemToTag } from '../Types/Tag';
import { htmlIf } from '../Utils/HTML';

type Props =
  { property: Property
  , allTags: Tag[]
  , submitPath: string
  , authToken: string
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

enum Page
  { BioTags
  }

const PropertyProfileWizard = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(Page.BioTags);

  const [bio, setBio] = useState(props.property.bio);

  // const [selectedCountry, setSelectedCountry] = useState(Location.countryAsSelectOption(props.property.country));
  // const [selectedCurrency, setSelectedCurrency] = useState(Currency.asSelectOption(props.property.currency));

  const [technologyTags, setTechnologyTags] = useState(
    props.property.tags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))
  );
  const [supportTags, setSupportTags] = useState(
    props.property.tags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))
  );

  const [showErrors, setShowErrors] = useState(false);

  function triggerSave() {
    if (bio?.length > 0) {
      document.getElementById("submitButton").click();
    } else {
      setShowErrors(true);
    }
  }

  // Due to the way Wicked (our onboarding wizard gem) works, we need to submit values by POSTing them in a form (instead of
  // a typical API call). Note some important elements here:
  // props.submitPath - this is the submit path (wizard_path), from Rails
  // hidden field for `authenticity_token` set to `props.authToken` - this is the form_authenticity_token, from Rails
  // hidden input field for `_method` set to `PUT` — this processes the request as a PUT request, despite the form method being POST.
  // mapping array elements into individual hidden inputs, with the same name (structured as an array) — this is the only way to post arrays in a form.
  const SubmitForm = () => {
    const allSelectedTagItems = Array.from(new Set([...technologyTags, ...supportTags]));
    const tags = allSelectedTagItems.map((item) => reactSelectItemToTag(item, props.allTags));

    return (
      <form action={props.submitPath} method="POST">
        <button className="d-none" id="submitButton" type="submit">Save</button>
        <input type="hidden" name="authenticity_token" value={props.authToken} />
        <input type="hidden" name="_method" value="PUT" />
        <input type="hidden" name="bio" value={bio} />
        {/* <input type="hidden" name="country" value={selectedCountry.value} />
        <input type="hidden" name="currency" value={selectedCurrency.value} /> */}
        {tags.map((tag) => (
          <input type="hidden" name="tagIds[]" value={tag.id} />
        ))}
      </form>
    )
  }

  const ViewBioTagsPage = () => {
    return (
      <div>
        <h4 className="text-center">Let’s get to know you better</h4>
        <div className="row mt-5 justify-content-center">
          <div className="align-items-center">
            <div className="d-flex align-items-center">
              <div className="fs-md fw-bold text-dark">What technologies are relevant to your hotel?</div>
              <span className="ms-1 fs-md text-secondary">(Please select all that apply)</span>
            </div>
            <Select
              className="rounded mt-1 t--technology-tag-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newTechnologyTags: ReactSelectOption[]) => setTechnologyTags(newTechnologyTags)}
              value={technologyTags}
              placeholder="Select from the dropdown…"
            />
            <div className="mt-3 d-flex align-items-center">
              <div className="fs-md fw-bold text-dark">What skills are relevant to your hotel?</div>
              <span className="ms-1 fs-md text-secondary">(Please select all that apply)</span>
            </div>
            <Select
              className="rounded mt-1 t--support-tag-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newSupportTags: ReactSelectOption[]) => setSupportTags(newSupportTags)}
              value={supportTags}
              placeholder="Select from the dropdown…"
            />
            <div className="mt-3 d-flex align-items-center">
              <div className="fs-md fw-bold text-dark">Please add a description of your property</div>
              <span className="ms-1 fs-md text-secondary">(This is visible to CoPilots)</span>
            </div>
            <textarea
              className="form-control mt-1 t--business-name"
              placeholder="Please be detailed here, as it will save you time later! We recommend including your service star level, number of keys, location and any guest-facing details you might want your CoPilot to know."
              value={bio}
              rows={5}
              onChange={(event) => setBio(event.target.value)}
            />
            {htmlIf(showErrors && (bio?.length === 0 || bio === undefined || bio === null),
              <div className="fs-sm mt-1 text-danger fw-bold">Please provide a property description.</div>
            )}
            {/* <div className="mt-3 fs-md fw-bold text-dark">Country</div>
            <Select
              className="rounded mt-1 t--country-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={Location.allCountriesAsSelectOptions()}
              onChange={(newCountry: ReactSelectOption) => setSelectedCountry(newCountry)}
              value={selectedCountry}
              placeholder="Select the country you are based in…"
            />
            <div className="mt-3 fs-md fw-bold text-dark">Currency</div>
            <Select
              className="rounded mt-1 t--currency-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={Currency.allAsSelectOptions()}
              onChange={(newCurrency: ReactSelectOption) => setSelectedCurrency(newCurrency)}
              value={selectedCurrency}
              placeholder="Select your preferred currency…"
            /> */}
          </div>
          <div className="text-center mt-3">
            <>
              <button className="btn btn-primary t--save-profile-wizard" onClick={triggerSave}>Save</button>
              {SubmitForm()}
            </>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mx-6">
      <div className="card-body">
        {(() => {
          switch (currentPage) {
            case Page.BioTags:
              return ViewBioTagsPage();
            default:
              return null;
          }
        })() as React.ReactNode}
      </div>
    </div>
  )
}

export default PropertyProfileWizard;
