import * as React from 'react';
import { useState, useEffect } from 'react';
import { RequestCard } from '../Types/RequestCard';
import AgentRequestCard from './Components/AgentRequestCard';
import { CustomerRequestStatusBadge } from '../Common/RequestStatusBadge';
import { htmlIf } from '../Utils/HTML';

type Props =
  { requests: RequestCard[]
  }

const agentNewRequestPath = '/agent/new-request'

const Requests = (props: Props) => {
  enum SortOptions
    { RecentlyUpdated
    , NameAsc
    , NameDesc
    , CreatedAtAsc
    , CreatedAtDesc
    }

  const allSortOptions = [SortOptions.RecentlyUpdated, SortOptions.NameAsc, SortOptions.NameDesc, SortOptions.CreatedAtAsc, SortOptions.CreatedAtDesc]

  function sortOptionDisplayName(sortOption: SortOptions): string {
    switch (sortOption) {
      case SortOptions.RecentlyUpdated:
        return "Recently Updated"
      case SortOptions.NameAsc:
        return "Name (A–Z)"
      case SortOptions.NameDesc:
        return "Name (Z–A)"
      case SortOptions.CreatedAtAsc:
        return "Submission Date (Oldest to Newest)"
      case SortOptions.CreatedAtDesc:
        return "Submission Date (Newest to Oldest"
    }
  }
  const sortRequests = (requests: RequestCard[]): RequestCard[] => {
    switch (sortOption) {
      case SortOptions.RecentlyUpdated:
        return requests.sort((a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime());
      case SortOptions.NameAsc:
        return requests.sort((a, b) => a.title.localeCompare(b.title));
      case SortOptions.NameDesc:
        return requests.sort((a, b) => b.title.localeCompare(a.title));
      case SortOptions.CreatedAtAsc:
        return requests.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      case SortOptions.CreatedAtDesc:
        return requests.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      default:
        return requests;
    }
  }

  const [sortOption, setSortOption] = useState(SortOptions.RecentlyUpdated);
  const [allRequests, setAllRequests] = useState(props.requests);
  const [searchText, setSearchText] = useState('');
  const [filteredRequests, setFilteredRequests] = useState(props.requests);

  // This hook serves to re-sort and re-filter the list of requests any time one of the following occurs:
  // 1) The underlying list of requests changes (allRequests)
  // 2) The sort option is changed
  // 3) The search text is changed
  useEffect(() => {
    const sortedRequests = sortRequests(allRequests);

    const filtered = sortedRequests.filter((request) => {
      const searchFields = [
        request.title,
        request.description,
        request.copilot?.preferredName,
        CustomerRequestStatusBadge({status: request.status}).props.children
      ];
      // const tagNames = request.tags.map((tag) => tag.name);
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
        // || tagNames.some((tagName) =>
        //   tagName.toLowerCase().includes(searchText.toLowerCase())
        // )
      );
    });

    setFilteredRequests(filtered);
  }, [allRequests, sortOption, searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  const onSelectSortOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = parseInt(event.target.value) as SortOptions;
    setSortOption(selectedSortOption);
  }

  const ViewEmptyState = () => (
    <div className="my-3 text-center">
      <div className="avatar avatar-md">
        <div className="avatar-title bg-accent rounded text-dark h3">
          <i className="ai-file-text" />
        </div>
      </div>
      <div className="fs-lg text-dark mt-2">You don't have any requests — submit one now to get started!</div>
      <a href={agentNewRequestPath} className="btn btn-primary mt-2">Submit a request</a>
    </div>
  )

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="fw-bold">Requests</h4>
        { allRequests.length == 0
        ? <ViewEmptyState />
        :
          <>
            <div className="row mt-3 align-items-center">
              <div className="col-md-8">
                <div className="input-group align-items-center p-0">
                  <div className="input-group-prepend ms-2">
                    <i className="ai-search"></i>
                  </div>
                  <input
                    className="form-control"
                    type="search"
                    value={searchText}
                    onChange={handleSearchInputChange}
                    placeholder="Search by title, description, or CoPilot…"
                  />
                </div>
              </div>
              <div className="col-md-4 mt-2 mt-md-0 text-end">
                <select className="form-select" onChange={onSelectSortOption} value={sortOption}>
                  {allSortOptions.map((option: SortOptions) => (
                    <option key={option} value={option}>
                      {sortOptionDisplayName(option)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row mt-3">
              {filteredRequests.map((request) => (
                <AgentRequestCard
                  request={request}
                  key={`${request.requestType}-${request.requestId}`}
                />
              ))}
            </div>
          </>
        }
      </div>
    </div>
  )
};


export default Requests;
