import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../Utils/API.js';
import { Copilot } from '../Types/Copilot';
import { RequestCard, RequestType } from '../Types/RequestCard';
import { htmlIf } from '../Utils/HTML';
import { CopilotSearchSort } from './Components/CopilotSearchSort';
import { DismissibleModal } from '../Components/Modal';
import { emptyClickHandler } from './Components/CopilotCard'
import { CurrencyCode, ExchangeRate } from '../Utils/Currency';
import Perspective from '../Types/Perspective';

type Props =
  { copilots: Copilot[]
  , request: RequestCard
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  , perspective: Perspective
  }

enum Modal
  { NoModal
  , ReassignModal
  }

function requestDetailsPath(perspective: Perspective, card: RequestCard) {
  switch (perspective) {
    case Perspective.Agent:
      switch (card.requestType) {
        case RequestType.PROJECT_REQUEST:
          return `/agent/requests/p/${card.requestId}`
        case RequestType.HOURLY_CONTRACT:
          return `/agent/requests/h/${card.requestId}`
      }
    case Perspective.Property:
      switch (card.requestType) {
        case RequestType.PROJECT_REQUEST:
          return `/property/requests/p/${card.requestId}`
        case RequestType.HOURLY_CONTRACT:
          return `/property/requests/h/${card.requestId}`
      }
  }

}

const ReassignRequest = (props: Props) => {
  const [modal, setModal] = useState(Modal.NoModal);
  const [selectedCopilot, setSelectedCopilot] = useState<Copilot>();

  function reassignRequest(request: RequestCard) {
    const body = { requestId: request.requestId, requestType: request.requestType, copilotIndividualId: selectedCopilot.id }
    API.post("api_requests_reassign_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        window.location.href = requestDetailsPath(props.perspective, request)
      }
      setModal(Modal.NoModal);
    })
  }

  const ViewReassignModal = () => (
    <DismissibleModal
      title={
        <h4>Reassign this request?</h4>
      }
      body={
        <div>
          Are you sure you want to reassign this request to {selectedCopilot.preferredName}?
          They will receive an email inviting them to claim your request.
        </div>
      }
      footer={
        <>
          <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0" onClick={() => setModal(Modal.NoModal)}>
            No, keep looking
          </button>
          <button className="btn btn-success w-100 w-sm-auto ms-sm-2" onClick={() => reassignRequest(props.request)}>
            Yes, reassign it
          </button>
        </>
      }
      onDismiss={() => setModal(Modal.NoModal)}
    />
  )

  function handleCopilotButtonClicked(event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot) {
    setSelectedCopilot(copilot);
    setModal(Modal.ReassignModal);
  }

  return (
    <>
      <div className="mb-2">
        <a href={requestDetailsPath(props.perspective, props.request)} className="text-decoration-none align-items-center d-flex">
          <i className="ai-arrow-left me-1"/>
          Back
        </a>
      </div>
      <div className="card">
        <div className="card-body">
          <h4 className="fw-bold">Select a Copilot to reassign your request</h4>
          <div className="fs-md">
            Request:
            <span className="ms-1 fw-bold">{props.request.title}</span>
          </div>
          <CopilotSearchSort copilots={props.copilots}
            displayCurrency={props.displayCurrency} exchangeRates={props.exchangeRates}
            featuredTags={[]}
            cardButtons={
              [
                {
                  btnClass: "btn btn-outline-primary t--select-copilot w-100 w-md-auto",
                  btnLabel: "Select CoPilot",
                  onClick: handleCopilotButtonClicked
                },
                { btnClass: "btn btn-link t--view-copilot-profile w-100 w-md-auto",
                  btnLabel: "View Profile",
                  onClick: emptyClickHandler,
                  isViewProfileModalButton: true
                }
              ]
            }
          />
        </div>
      </div>
      {htmlIf(modal === Modal.ReassignModal, <ViewReassignModal />)}
    </>
  )
}

export default ReassignRequest;
