import { Copilot } from './Copilot';
import { Tag } from './Tag';
import { CustomerRating } from './CustomerRating';

// This should be kept in sync with the `to_client_json` method in `models/hourly/contract.rb`.
export type HourlyContract =
  { id: number
  , title: string
  , description: string
  , status: ContractStatus
  , contractLength?: ContractLength
  , copilot: Copilot
  , customerRating?: CustomerRating
  , customerName: string
  , customerUserId: number
  , customerImageUrl: string
  , customerHasActiveSubscription: boolean
  , isAgentVirtuoso: boolean
  , hourlyRateCents: number
  , copilotHourlyRateCents: number
  , claimedAt: string
  , completedAt: string
  , withdrawnAt: string
  , declinedAt: string
  , createdAt: string
  , updatedAt: string
  , workItems: HourlyWorkItem[]
  , payments: HourlyPayment[]
  , offers: HourlyContractOffer[]
  , acceptingOffers: boolean
  , tags: Tag[]
  }

// This should be kept in sync with the Hourly::Contract::Status class in `models/hourly/contract.rb`.
export enum ContractStatus
  { MISSING_PAYMENT_METHOD = 'missing-payment-method'
  , SUBMITTED = 'submitted'
  , DECLINED_PENDING = 'declined-pending'
  , CLAIMED = 'claimed'
  , COMPLETED = 'completed'
  , WITHDRAWN = 'withdrawn'
}

// This should be kept in sync with the Hourly::Contract::Length class in `models/hourly/contract.rb`.
export enum ContractLength
  { SMALL = 'small'
  , MEDIUM = 'medium'
  , LARGE = 'large'
  }

// This should be kept in sync with the `to_client_json` method in `models/hourly/payment.rb`.
export type HourlyPayment =
  { id: number
  , subtotalAmountCents: number
  , copilotAmountDueCents: number
  , platformFeeCents: number
  , paidAt: string
  , transferredToCopilotAt: string
  , refundedAt: string
  , createdAt: string
  , updatedAt: string
  , timeRangeDescription: string
  , totalDurationDescription: string
  }

// This should be kept in sync with the `to_client_json` method in `models/hourly/work_item.rb`.
export type HourlyWorkItem =
  { id: number
  , description: string
  , durationMinutes: number
  , createdAt: string
  , updatedAt: string
  , status: WorkItemStatus
  }

// This should be kept in sync with the Hourly::WorkItem::Status class in `models/hourly/work_item.rb`.
export enum WorkItemStatus
  { UNPAID = 'unpaid'
  , PAYMENT_PENDING = 'payment-pending'
  , AGENT_PAID = 'agent-paid'
  , COPILOT_PAID = 'copilot-paid'
  , REFUNDED = 'refunded'
  }

export type HourlyContractOffer =
  { id: number
  , description: string
  , hourlyRateCents: number
  , createdAt: string
  , updatedAt: string
  , lastEditedAt: string
  , status: ContractOfferStatus
  , copilot: Copilot
  }

// This should be kept in sync with the Hourly::ContractOffer::Status class in `models/hourly/contract_offer.rb`
export enum ContractOfferStatus
  { PENDING = 'pending'
  , ACCEPTED = 'accepted'
  , DECLINED_MANUAL = 'declined-manual'
  , DECLINED_AUTO = 'declined-auto'
  , RESCINDED = 'rescinded'
  }
