import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import * as API from '../Utils/API.js';
import { Copilot } from '../Types/Copilot';
import { Tag, tagToReactSelectItem, reactSelectItemToTag } from '../Types/Tag';
import { ContractLength, HourlyContract } from '../Types/Hourly';
import { CopilotSearchSort } from '../Agent/Components/CopilotSearchSort';
import { CopilotCard, emptyClickHandler } from '../Agent/Components/CopilotCard';
import { RequestType } from '../Types/RequestCard';
import { PaymentMethod, formatCardBrand, formatCardFunding } from '../Types/PaymentMethod';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { CurrencyCode, ExchangeRate, convertUSDCentsToCurrency, formatCurrencyWithCents, currencySymbol, formatUSDWithCents, convertToUSDCents } from '../Utils/Currency';
// import { GiftCardEntry } from './Components/GiftCardEntry';

type Props =
  { copilots: Copilot[]
  , platformFeePercent: number
  , paymentMethods: PaymentMethod[]
  , presetCopilotIndividualId?: number
  , utmLcSource?: string
  , allTags: Tag[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  }

enum Step
  { EnterTitle
  , EnterDescription
  , EnterTags
  , SelectCopilot
  , EnterBudget
  , SubmitRequest
  }

type ReactSelectOption =
  { value: number
  , label: string
  }

const termsAndConditionsPath = '/terms-of-service'
const propertyAccountBillingPath = '/property/account/billing'
const propertyRequestsPath = '/property/requests'
const propertyDashboardPath = '/property'

// When submitting project requests to the pool and accepting offers, we set the price of the request
// to 50¢ in order to allow Stripe to still process a hold on the request.
const projectRequestOfferAmountCents = 50

const NewRequest = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(Step.EnterTitle);
  const [completedSteps, setCompletedSteps] = useState(new Set<Step>());

  const [requestTitle, setRequestTitle] = useState('');
  const [requestDescription, setRequestDescription] = useState('');
  const [selectedTagItems, setSelectedTagItems] = useState<ReactSelectOption[]>([]);

  const [requestType, setRequestType] = useState<RequestType>();
  const [hourlyRateCents, setHourlyRateCents] = useState(0);
  const [subtotalCents, setSubtotalCents] = useState(0);
  const [isAcceptingOffers, setIsAcceptingOffers] = useState(false);

  const [shareRequestWithPool, setShareRequestWithPool] = useState<Boolean>();
  const [selectedCopilot, setSelectedCopilot] = useState<Copilot>(() => {
    const copilotToSelect = props.presetCopilotIndividualId > 0
    ? props.copilots.find(copilot => copilot.id === props.presetCopilotIndividualId)
    : undefined

    if (copilotToSelect) {
      setShareRequestWithPool(false);
      setIsAcceptingOffers(false);
      copilotToSelect.hourlyRateCents
      ?
        setHourlyRateCents(Math.round(
          convertUSDCentsToCurrency(props.displayCurrency, copilotToSelect.hourlyRateCents, props.exchangeRates) / 100
        ) * 100)
      : setHourlyRateCents(0)
    }

    return copilotToSelect;
  });

  const [showErrors, setShowErrors] = useState(false);

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(props.paymentMethods);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(props.paymentMethods[0].id);
  const [showAddPaymentMethodModal, setShowAddPaymentModal] = useState(false);
  const [creditCodeCode, setCreditCodeCode] = useState<string>();

  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const [submitRequestError, setSubmitRequestError] = useState<string>();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });

    setShowErrors(false);
  }, [currentStep])

  useEffect(() => {
    setShowErrors(false);
  }, [requestType])

  function submitRequest() {
    setIsSubmittingRequest(true);

    const tags: Tag[] = selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));

    const sbtlAmountCents =
      props.displayCurrency === 'USD'
      ? subtotalCents
      : convertToUSDCents(props.displayCurrency, subtotalCents, props.exchangeRates)

    const hrlyRateCents =
        props.displayCurrency === 'USD'
        ? hourlyRateCents
        : convertToUSDCents(props.displayCurrency, hourlyRateCents, props.exchangeRates)

    const postBody = {
      requestTitle: requestTitle,
      requestDescription: requestDescription,
      utmLcSource: props.utmLcSource,
      tags: tags,
      requestType: requestType,
      subtotalAmountCents: sbtlAmountCents,
      hourlyRateCents: hrlyRateCents,
      copilotId: (shareRequestWithPool ? null : selectedCopilot.id),
    }

    API.post("property_submit_request_path", postBody).then(function (result) {
      setIsSubmittingRequest(false);

      if (result['error']) {
        if (result['message']) {
          setSubmitRequestError(result['message'])
        } else {
          setSubmitRequestError(
            "Something went wrong. Our team has been notified and will investigate the issue. If you continue to experience problems submitting a request, please email us at hello@letslucia.com"
          )
        }
      } else if (result['success'] === true) {
        window.location.href = propertyRequestsPath
      }
    })
  }

  function platformFeeAmountCents(): number {
    return (subtotalCents * props.platformFeePercent * 0.01)
  }

  function totalAmountCents(): number {
    return (subtotalCents + platformFeeAmountCents())
  }

  const ViewProgressNav = () => {
    const steps = [
      { number: 1, title: 'Title', icon: 'ai-edit', step: Step.EnterTitle },
      { number: 2, title: 'Description', icon: 'ai-edit-alt', step: Step.EnterDescription },
      { number: 3, title: 'Expertise', icon: 'ai-star', step: Step.EnterTags },
      { number: 4, title: 'CoPilot', icon: 'ai-user', step: Step.SelectCopilot },
      { number: 5, title: 'Budget', icon: 'ai-dollar', step: Step.EnterBudget },
      { number: 6, title: 'Review', icon: 'ai-check', step: Step.SubmitRequest }
    ]

    function navItemClickHandler(event: React.MouseEvent<HTMLAnchorElement>, step: Step) {
      if (completedSteps.has(step)) {
        event.preventDefault();
        setCurrentStep(step);
      } else {
        return
      }
    }

    return (
      <div className="card-header">
        {steps.map((stepInfo, index) => (
          <div className={`${currentStep === index ? "bg-white border rounded-2" : ""} ${index > 0 ? "mt-1" : ""}`} key={index}>
            <a className={`px-2 py-1 d-flex align-items-center justify-content-between text-decoration-none
                ${completedSteps.has(stepInfo.step) ? 'cursor-pointer' : ''}`}
              key={index}
              onClick={(e) => navItemClickHandler(e, stepInfo.step)}
            >
              <div className="d-flex align-items-center">
                <i className={`${stepInfo.icon} h4 mb-0 me-2 ${currentStep === index ? "text-primary" : completedSteps.has(stepInfo.step) ? "text-gray-900" : "text-gray-600"}`} />
                <div className={`fs-md ${currentStep === index || completedSteps.has(stepInfo.step) ? "text-dark" : "text-gray-700"} text-nowrap`}>
                  {stepInfo.title}
                </div>
              </div>
              {htmlIf(stepInfo.number !== 6, // Don't show the checkmark on the Review step
                <div className="avatar avatar-xs me-2">
                  <div className={`avatar-title mb-0 ${completedSteps.has(stepInfo.step) ? "bg-primary" : "bg-gray-300"} rounded-circle text-white h6`}>
                    <i className="ai-check" />
                  </div>
                </div>
              )}
            </a>
          </div>
        ))}
      </div>
    )
  }

  const ViewEnterTitlePage = () => {
    function handleNextClicked() {
      if (requestTitle.length > 0) {
        setCurrentStep(Step.EnterDescription);
        setCompletedSteps(completedSteps.add(Step.EnterTitle));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 1 of 6</div>
            <div className="h2 mb-0 text-dark">Get started</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-0">What can we help you with?</h6>
            <div className="fs-md">Keep it short and simple. This will help CoPilots understand what you are looking for.</div>
            <input
              className="form-control mt-1 t--request-title"
              placeholder="Enter job title…"
              value={requestTitle}
              onChange={(event) => setRequestTitle(event.target.value)}
            />
            {htmlIf(showErrors && !requestTitle.length,
              <div className="fs-sm mt-1 text-danger fw-bold">Please enter a title.</div>
            )}
            <div className="fs-sm fw-semibold mt-3 text-gray-700">Some title examples:</div>
            <div className="mt-1 fs-sm">
              <ul className="mb-0">
                <li>OTA Optimization</li>
                <li>Revenue Management</li>
                <li>Train new hires in Cloudbeds</li>
              </ul>
            </div>
          </div>
          <div className="card-footer d-flex border-0">
            <a href={propertyDashboardPath} className="btn btn-outline-secondary">Exit</a>
            <button className="btn btn-primary px-6 ms-2 t--step-1-next t--enter-title-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterDescriptionPage = () => {
    function handleNextClicked() {
      if (requestDescription.length > 0) {
        setCurrentStep(Step.EnterTags);
        setCompletedSteps(completedSteps.add(Step.EnterDescription));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 2 of 6</div>
            <div className="h2 mb-0 text-dark">Description</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-0">What are you looking to get done?</h6>
            <div className="fs-md">Be specific and add details — this will help get your project to the right CoPilot!</div>
            <textarea
              className="form-control mt-1 t--request-description"
              rows={5}
              placeholder="Explain the scope of this request…"
              value={requestDescription}
              onChange={(event) => setRequestDescription(event.target.value)}
            />
            {htmlIf(showErrors && !(requestDescription.length > 0),
              <div className="fs-sm mt-1 text-danger fw-bold">Please enter a description.</div>
            )}
            <div className="mt-3 fs-sm">
              <div className="fw-semibold">A good description includes:</div>
              <ul className="mb-0">
                  <li>Clear expectations about your job and the deliverables</li>
                  <li>The skills required</li>
                  <li>Explanation of scope and timeline</li>
                </ul>
            </div>
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterTitle)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-2-next t--enter-description-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterTagsPage = () => {
    function handleNextClicked() {
      if (selectedTagItems.length > 0) {
        setCurrentStep(Step.SelectCopilot);
        setCompletedSteps(completedSteps.add(Step.EnterTags));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 3 of 6</div>
            <div className="h2 mb-0 text-dark">Expertise</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-1">What technologies, skills and expertise are relevant for this project?</h6>
            <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2 t--select-tags',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.map((tag) => (tagToReactSelectItem(tag)))}
              onChange={(newTags: ReactSelectOption[]) => setSelectedTagItems(newTags)}
              value={selectedTagItems}
              placeholder="Type or select technology, skill or expertise…"
            />
            {htmlIf(showErrors && !(selectedTagItems.length > 0),
              <div className="fs-sm mt-1 text-danger fw-bold">Please select at least one tag.</div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterDescription)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-3-next t--enter-tags-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewSelectCopilotPage = () => {
    function handleSelectCopilotClicked(event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot) {
      setSelectedCopilot(copilot);
      setIsAcceptingOffers(false);
      copilot.hourlyRateCents
      ?
        setHourlyRateCents(Math.round(
          convertUSDCentsToCurrency(props.displayCurrency, copilot.hourlyRateCents, props.exchangeRates) / 100
        ) * 100)
      : setHourlyRateCents(0)
    }

    function handleNextClicked() {
      setCurrentStep(Step.EnterBudget);
      setCompletedSteps(completedSteps.add(Step.SelectCopilot));
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 4 of 6</div>
            <div className="h2 mb-0 text-dark">Select a CoPilot</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-1">Would you like to choose the CoPilot you work with?</h6>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={"card h-100 rounded-2 cursor-pointer t--share-with-pool" + (shareRequestWithPool === true ? " border-primary" : "")}
                  onClick={() => {
                    setShareRequestWithPool(true);
                    setSelectedCopilot(null);
                  }}
                >
                  <div className="card-body px-2 py-3 d-flex align-items-center">
                    <i className={"ai-star lead mb-0 me-1 " + (shareRequestWithPool === true ? "text-primary" : "")} />
                    <div className={shareRequestWithPool === true ? "text-primary" : ""}>Nope — I’m feeling lucky</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2 mt-md-0">
                <div
                  className={"card rounded-2 cursor-pointer t--choose-my-own-copilot" + (shareRequestWithPool === false ? " border-primary" : "")}
                  onClick={() => {
                    setShareRequestWithPool(false);
                    setSelectedCopilot(null);
                  }}
                >
                  <div className="card-body px-2 py-3 d-flex align-items-center">
                    <i className={"ai-user-group lead mb-0 me-1 " + (shareRequestWithPool === false ? "text-primary" : "")} />
                    <div className={shareRequestWithPool === false ? "text-primary" : ""}>Show me my options</div>
                  </div>
                </div>
              </div>
            </div>
            {htmlIf(shareRequestWithPool === false && !selectedCopilot,
              <CopilotSearchSort copilots={props.copilots}
                displayCurrency={props.displayCurrency} exchangeRates={props.exchangeRates}
                featuredTags={selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)))}
                allowedTags={props.allTags}
                cardButtons={
                  [
                    {
                      btnClass: "btn btn-outline-primary t--select-copilot w-100 w-md-auto",
                      btnLabel: "Select CoPilot",
                      onClick: handleSelectCopilotClicked
                    },
                    { btnClass: "btn btn-link t--view-copilot-profile w-100 w-md-auto",
                      btnLabel: "View Profile",
                      onClick: emptyClickHandler,
                      isViewProfileModalButton: true
                    }
                  ]
                }
              />
            )}
            {htmlIf(selectedCopilot,
              <div className="mt-3">
                <div className="fs-md fw-semibold text-gray-900 mb-1">Selected CoPilot</div>
                <CopilotCard copilot={selectedCopilot}
                  displayCurrency={props.displayCurrency} exchangeRates={props.exchangeRates}
                  featuredTags={selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)))}
                  allowedTags={props.allTags}
                  cardButtons={
                    [
                      {
                        btnClass: "btn btn-link text-dark ai-cross fs-xxl t--change-selected-copilot",
                        btnLabel: "",
                        onClick: () => setSelectedCopilot(null)
                      }
                    ]
                  }
                />
              </div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterTags)} className="btn btn-outline-secondary">
              Back
            </button>
            {htmlIf(shareRequestWithPool || selectedCopilot,
              <button className="btn btn-primary px-6 ms-2 t--step-4-next t--select-copilot-next"
                onClick={handleNextClicked}
              >Next</button>
            )}
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterBudgetPage = () => {
    function handleNextClicked() {
      if (requestType === null || requestType === undefined) { setShowErrors(true) }
      else {
        switch (requestType) {
          case RequestType.HOURLY_CONTRACT:
            if ( hourlyRateCents > 0 || isAcceptingOffers) {
              setCurrentStep(Step.SubmitRequest);
              setCompletedSteps(completedSteps.add(Step.EnterBudget));
            }
            else {
              setShowErrors(true);
            }
          case RequestType.PROJECT_REQUEST:
            if ( subtotalCents > 0 || isAcceptingOffers) {
              setCurrentStep(Step.SubmitRequest);
              setCompletedSteps(completedSteps.add(Step.EnterBudget));
            }
            else {
              setShowErrors(true);
            }
        }
      }
    }

    const handleSubtotalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubtotalCents(parseInt(event.target.value) * 100);
    };

    const handleHourlyRateCentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHourlyRateCents(parseInt(event.target.value) * 100);
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 5 of 6</div>
            <div className="h2 mb-0 text-dark">Budget</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold">How would you like to structure your job?</h6>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={"card h-100 rounded-2 cursor-pointer t--hourly-card " + (requestType === RequestType.HOURLY_CONTRACT ? " border-primary" : "")}
                  onClick={() => setRequestType(RequestType.HOURLY_CONTRACT)}
                >
                  <div className="card-body p-3">
                    <i className={"ai-clock h3 mb-1 " + (requestType === RequestType.HOURLY_CONTRACT ? "text-primary fw-bold" : "")} />
                    <h5 className={"mb-1 " + (requestType === RequestType.HOURLY_CONTRACT ? "text-primary" : "")}>Pay by the hour</h5>
                    <div className="fs-sm">Hourly rates are great for long-term work or if you’re unsure how long a task will take.</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2 mt-md-0">
                <div
                  className={"card h-100 rounded-2 cursor-pointer t--project-card " + (requestType === RequestType.PROJECT_REQUEST ? " border-primary" : "")}
                  onClick={() => setRequestType(RequestType.PROJECT_REQUEST)}
                >
                  <div className="card-body p-3">
                    <i className={"ai-tag h3 mb-1 " + (requestType === RequestType.PROJECT_REQUEST ? "text-primary" : "")} />
                    <h5 className={"mb-1 " + (requestType === RequestType.PROJECT_REQUEST ? "text-primary" : "")}>Pay a fixed price</h5>
                    <div className="fs-sm">Fixed rates are ideal for clear and defined tasks or shorter projects.</div>
                  </div>
                </div>
              </div>
            </div>
            {htmlIf(showErrors && (requestType === null || requestType === undefined),
              <div className="mt-1 text-danger fw-bold">Please select a job type before continuing.
              </div>
            )}
            {htmlIf(requestType !== null && requestType !== undefined && shareRequestWithPool,
              <div className="mt-3">
                <h6 className="fw-bold mb-1">Do you know how to price your job?</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={"card rounded-2 cursor-pointer t--set-my-own-price" + (isAcceptingOffers === false ? " border-primary" : "")}
                      onClick={() => {
                        setIsAcceptingOffers(false);
                        setSubtotalCents(0);
                      }}
                    >
                      <div className="card-body px-2 py-3 d-flex align-items-center">
                        <i className={"ai-dollar lead mb-0 me-1 " + (isAcceptingOffers === false ? "text-primary" : "")} />
                        <div className={isAcceptingOffers === false ? "text-primary" : ""}>Yes, I’ll set a price</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2 mt-md-0">
                    <div
                      className={"card h-100 rounded-2 cursor-pointer t--accept-offers" + (isAcceptingOffers === true ? " border-primary" : "")}
                      onClick={() => {
                        setIsAcceptingOffers(true);
                        if (subtotalCents === projectRequestOfferAmountCents) {
                          setSubtotalCents(0);
                        } else {
                          setSubtotalCents(projectRequestOfferAmountCents);
                        }
                        setShowErrors(false);
                      }}
                    >
                      <div className="card-body px-2 py-3 d-flex align-items-center">
                        <i className={"ai-mail lead mb-0 me-1 " + (isAcceptingOffers === true ? "text-primary" : "")} />
                        <div className={isAcceptingOffers === true ? "text-primary" : ""}>No, I want to receive offers from CoPilots</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {htmlIf(requestType === RequestType.PROJECT_REQUEST && isAcceptingOffers === false,
              <div className="mt-3">
                <h6 className="fw-bold">Job Amount</h6>
                <div className="input-group align-items-center p-0">
                  <div className="input-group-prepend ms-2">{currencySymbol(props.displayCurrency)}</div>
                  <input
                    className="form-control t--request-subtotal" type="number"
                    value={subtotalCents / 100}
                    min={0} max={5000}
                    onChange={handleSubtotalChange}
                  />
                </div>
                {htmlIf(showErrors && !(subtotalCents > 0),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                )}
              </div>
            )}
            {htmlIf(requestType === RequestType.HOURLY_CONTRACT && isAcceptingOffers === false,
              <div className="mt-3">
                <h6 className="fw-bold">Hourly Rate</h6>
                <div className="input-group align-items-center p-0">
                  <div className="input-group-prepend ms-2">{currencySymbol(props.displayCurrency)}</div>
                  <input
                    className="form-control t--hourly-rate" type="number"
                    value={hourlyRateCents / 100}
                    min={0} max={5000}
                    onChange={handleHourlyRateCentsChange}
                  />
                </div>
                {htmlIf(showErrors && !(hourlyRateCents > 0),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                )}
              </div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.SelectCopilot)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-5-next t--enter-budget-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  function ViewSubmitRequestPage() {
    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <div className="fs-md">Step 6 of 6</div>
                <div className="h2 mb-0 text-dark">Review and post</div>
              </div>
            </div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <div className="">
              <div className="fw-semibold text-dark">Title</div>
              {requestTitle}
            </div>
            <div className="mt-3">
              <div className="fw-semibold text-dark">Description</div>
              {requestDescription}
            </div>
            <div className="mt-3">
              <div className="fw-semibold text-dark">Skills</div>
              {htmlIf(selectedTagItems.length > 0,
                <div className="d-flex align-items-center flex-wrap">
                  {selectedTagItems.map((tag) => (
                    <div key={`Tag-${tag.value}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.label}</div>
                  ))}
                </div>
              )}
            </div>
            {htmlIf(selectedCopilot !== null && !shareRequestWithPool,
              <div className="mt-3">
                <div className="fw-semibold text-dark">CoPilot</div>
                <div className="d-flex align-items-center mt-1">
                  <div className="avatar avatar-xs">
                    <img className="avatar-img rounded-circle" src={selectedCopilot?.imageUrl}/>
                  </div>
                  <div className="fs-md text-dark ms-1">{selectedCopilot?.preferredName}</div>
                </div>
              </div>
            )}
            <div className="mt-3">
              <div className="fw-semibold text-dark">Project Type</div>
              { requestType === RequestType.HOURLY_CONTRACT ? "Hourly Rate" : requestType === RequestType.PROJECT_REQUEST ? "Fixed Rate" : ""}
            </div>
            <div className="mt-3">
              {htmlIf(requestType == RequestType.HOURLY_CONTRACT,
                <>
                  <div className="fw-semibold text-dark">Rate</div>
                  { isAcceptingOffers
                  ? "TBD – Accepting offers"
                  : formatCurrencyWithCents(props.displayCurrency, hourlyRateCents)
                  }
                </>
              )}
            </div>
            {{
              [RequestType.HOURLY_CONTRACT]: (
                <>
                  <div className="mt-2 fs-sm">
                    At the end of each week, you will be notified once your CoPilot logs the amount of hours they worked.
                    The CoPilot will include a description of the tasks performed during those hours.
                    Your card will be automatically billed for the hours your CoPilot works (along with the Lucia service fee).
                  </div>
                  {htmlIf(props.displayCurrency !== 'USD' && hourlyRateCents > 0,
                    <>
                      <div className="mt-2 fs-sm text-secondary">
                        Note: while prices are displayed in your preferred currency, all transactions on Lucia are processed
                        in USD using the latest exchange rates.
                      </div>
                      <div className="mt-1 fs-sm text-secondary">
                        You will be billed at a rate of approximately {
                          formatUSDWithCents(
                            convertToUSDCents(props.displayCurrency, hourlyRateCents, props.exchangeRates)
                          )
                        } USD/hour (plus the Lucia service fee).
                      </div>
                    </>
                  )}
                </>
              ),
              [RequestType.PROJECT_REQUEST]: (
                <>
                  { isAcceptingOffers
                  ? <div className="mt-2">
                      The price of your request is dependent on the CoPilot offer that you ultimately accept—you’ll be able
                      to review offers and pricing before a CoPilot gets started on your project. Once you select an offer,
                      you will be billed for the amount of the offer (along with the Lucia service fee).
                    </div>
                  : <div>
                      <hr className="mt-2" />
                      <div className="mt-2 fs-md">
                        <div className="row">
                          <div className="col">Subtotal:</div>
                          <div className="col-auto text-end">{formatCurrencyWithCents(props.displayCurrency, subtotalCents)}</div>
                        </div>
                        <div className="row">
                          <div className="col">Service Fee:</div>
                          <div className="col-auto text-end">{formatCurrencyWithCents(props.displayCurrency, platformFeeAmountCents())}</div>
                        </div>
                      </div>
                      <hr className="mt-2" />
                      <div className="mt-1 mb-2 text-dark">
                        <div className="row">
                          <div className="col">Total:</div>
                          <div className="col-auto text-end">{formatCurrencyWithCents(props.displayCurrency, totalAmountCents())}</div>
                        </div>
                      </div>
                      {htmlIf(props.displayCurrency !== 'USD',
                        <>
                          <div className="mt-2 fs-sm text-secondary">
                            Note: while these prices are displayed in your preferred currency, all transactions on Lucia are processed
                            in USD using the latest exchange rates.
                          </div>
                          <div className="mt-1 fs-sm text-secondary">
                            You will be charged a total of approximately {
                              formatUSDWithCents(
                                convertToUSDCents(props.displayCurrency, totalAmountCents(), props.exchangeRates)
                              )
                            } USD for this request.
                          </div>
                        </>
                      )}
                    </div>
                  }
                </>
              )
            }[requestType]}
            <div className="mt-1 fs-sm">
              By placing a request, you agree to the <a href={termsAndConditionsPath} target="_blank">Terms of Service</a>.
            </div>
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterBudget)} className="btn btn-outline-secondary">
              Back
            </button>
            <button
              className="btn btn-primary px-md-6 ms-1 ms-md-2 t--submit-request"
              onClick={submitRequest}
              disabled={isSubmittingRequest}
            >
              Submit request
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      <div className="col-lg-3 d-none d-lg-block">
        <ViewProgressNav />
      </div>
      <div className="col-12 col-lg-9">
        {(() => {
          switch (currentStep) {
            case Step.EnterTitle:
              return ViewEnterTitlePage();
            case Step.EnterDescription:
              return ViewEnterDescriptionPage();
            case Step.EnterTags:
              return ViewEnterTagsPage();
            case Step.SelectCopilot:
              return ViewSelectCopilotPage();
            case Step.EnterBudget:
              return ViewEnterBudgetPage();
            case Step.SubmitRequest:
              return ViewSubmitRequestPage();
            default:
              return null;
          }
        })() as React.ReactNode}
      </div>
    </div>
  );
}

export default NewRequest;
