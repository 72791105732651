import ReactOnRails from 'react-on-rails';

import FAQ from '../src/Common/FAQ';

import OnboardingAgentProfileWizard from '../src/Onboarding/AgentProfileWizard';
import OnboardingPropertyProfileWizard from '../src/Onboarding/PropertyProfileWizard';
import OnboardingAgentFirstRequest from '../src/Onboarding/AgentFirstRequest';

import Messages from '../src/Agent/Messages';

import CopilotProfile from '../src/Common/CopilotProfile';

import AdminAgentList from '../src/Admin/AgentList';
import AdminCopilotApplicationList from '../src/Admin/CopilotApplicationList';
import AdminCopilotIndividualList from '../src/Admin/CopilotIndividualList';
import AdminBlogPostManager from '../src/Admin/BlogPostManager';

import BlogAllPosts from '../src/Blog/AllPosts';

import AgentAccountProfile from '../src/Agent/Account/AboutMe';
import AgentEditSubscriptionButton from '../src/Agent/Account/EditSubscriptionButton';
import AgentPaymentMethods from '../src/Agent/PaymentMethods';
import AgentNewRequest from '../src/Agent/NewRequest';
import AgentEditRequest from '../src/Agent/EditRequest';
import AgentRequests from '../src/Agent/Requests';
import AgentStandaloneRequestCards from '../src/Agent/StandaloneRequestCards';
import AgentProjectRequestDetails from '../src/Agent/ProjectRequestDetails';
import AgentHourlyRequestDetails from '../src/Agent/HourlyRequestDetails';
import AgentCopilotList from '../src/Agent/CopilotList';
import AgentStandaloneCopilotCards from '../src/Agent/StandaloneCopilotCards';
import AgentReassignRequest from '../src/Agent/ReassignRequest';
import AgentPackagePurchase from '../src/Agent/PackagePurchase';

import PropertyAccountProfile from '../src/Property/PropertyInfo';
import PropertyPaymentMethods from '../src/Property/PaymentMethods';
import PropertyNewRequest from '../src/Property/NewRequest';
import PropertyRequestList from '../src/Property/RequestList';

import CopilotAccountProfile from '../src/Copilot/Account/Profile';
import CopilotAccountPackages from '../src/Copilot/Account/Packages';
import CopilotRequests from '../src/Copilot/Requests';
import CopilotProjectRequestDetails from '../src/Copilot/ProjectRequestDetails';
import CopilotHourlyRequestDetails from '../src/Copilot/HourlyRequestDetails';

import LivestreamEventDetail from '../src/Livestream/EventDetail';
import LivestreamEventCard from '../src/Livestream/EventCard';
import LivestreamEventGrid from '../src/Livestream/EventGrid';

// Any component referenced via a Rails call to `react_component` must be defined here, as well as in `server-bundle.js`.
ReactOnRails.register({
  FAQ,

  OnboardingAgentProfileWizard,
  OnboardingPropertyProfileWizard,
  OnboardingAgentFirstRequest,

  Messages,

  CopilotProfile,

  AdminAgentList,
  AdminCopilotApplicationList,
  AdminCopilotIndividualList,
  AdminBlogPostManager,

  BlogAllPosts,

  AgentAccountProfile,
  AgentEditSubscriptionButton,
  AgentPaymentMethods,
  AgentNewRequest,
  AgentEditRequest,
  AgentRequests,
  AgentStandaloneRequestCards,
  AgentProjectRequestDetails,
  AgentHourlyRequestDetails,
  AgentCopilotList,
  AgentStandaloneCopilotCards,
  AgentReassignRequest,
  AgentPackagePurchase,

  PropertyAccountProfile,
  PropertyPaymentMethods,
  PropertyNewRequest,
  PropertyRequestList,

  CopilotAccountProfile,
  CopilotAccountPackages,
  CopilotRequests,
  CopilotProjectRequestDetails,
  CopilotHourlyRequestDetails,

  LivestreamEventDetail,
  LivestreamEventCard,
  LivestreamEventGrid
});
