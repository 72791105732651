export type PaymentMethod =
  { id: string
  , type: string
  , card?: Card
  }

export type Card =
  { brand: string
  , expiration: string
  , funding: string
  , last4: string
  }

export function formatCardBrand(brand: string): string {
  switch (brand) {
    case 'amex':
      return 'American Express';
    case 'diners':
      return 'Diners Club';
    case 'discover':
      return 'Discover';
    case 'jcb':
      return 'JCB';
    case 'mastercard':
      return 'Mastercard';
    case 'unionpay':
      return 'UnionPay';
    case 'visa':
      return 'Visa';
    default:
      return 'Unknown';
  }
}

export function formatCardFunding(funding: string): string {
  switch (funding) {
    case "credit":
      return "Credit";
    case "debit":
      return "Debit";
    case "prepaid":
      return "Prepaid";
    default:
      return "Unknown";
  }
}
