import * as React from 'react';
import { ButtonData, CopilotCard, emptyClickHandler } from './Components/CopilotCard';
import { Copilot } from '../Types/Copilot';
import { CurrencyCode, ExchangeRate } from '../Utils/Currency';

type Props =
  { copilots: Copilot[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  , includeProfileButton?: boolean
  }

const StandaloneCopilotCards = (props: Props) => {
  const cardButtons = () => {
    if (props.includeProfileButton) {
      return (
        [
          { btnClass: "btn btn-outline-primary t--view-copilot-profile w-100 w-md-auto",
          btnLabel: "View Full Profile",
          onClick: emptyClickHandler,
          isViewProfileModalButton: true
          }
        ]
      )
    } else { return [] }
  }

  return (
    <div className="row mt-3">
      {props.copilots.map((copilot) =>
          <div key={copilot.id} className="mt-2">
            <CopilotCard
              copilot={copilot}
              cardButtons={cardButtons()}
              displayCurrency={props.displayCurrency}
              exchangeRates={props.exchangeRates}
              featuredTags={[]}
              key={copilot.id}
            />
          </div>
        )}
    </div>
  );
}

export default StandaloneCopilotCards;
