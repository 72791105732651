import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../Utils/API.js';
import { HourlyContract } from '../Types/Hourly';
import { ProjectRequest } from '../Types/ProjectRequest';
import { RequestType } from '../Types/RequestCard';
import { Tag, reactSelectItemToTag, tagToReactSelectItem } from '../Types/Tag';
import { htmlIf } from '../Utils/HTML';
import Select from 'react-select';
import { CurrencyCode, ExchangeRate, convertUSDCentsToCurrency, formatCurrencyWithCents, currencySymbol, formatUSDWithCents, convertToUSDCents } from '../Utils/Currency';
import Perspective from '../Types/Perspective';

type Props =
  { projectRequest: ProjectRequest
  , hourlyContract: HourlyContract
  , allTags: Tag[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  , perspective: Perspective
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

const EditRequest = (props: Props) => {
  const [requestTitle, setRequestTitle] = useState(props.projectRequest?.title || props.hourlyContract?.title);
  const [requestDescription, setRequestDescription] = useState(props.projectRequest?.description || props.hourlyContract?.description);
  const [selectedTagItems, setSelectedTagItems] = useState<ReactSelectOption[]>(
    (props.projectRequest?.tags || props.hourlyContract?.tags).map((tag) => tagToReactSelectItem(tag))
  );
  const [hourlyRateCents, setHourlyRateCents] = useState(
    convertUSDCentsToCurrency(props.displayCurrency, props.hourlyContract?.hourlyRateCents, props.exchangeRates)
  );
  const [subtotalCents, setSubtotalCents] = useState(
    convertUSDCentsToCurrency(props.displayCurrency, props.projectRequest?.subtotalCents, props.exchangeRates)
  )

  const [showErrors, setShowErrors] = useState(false);
  const [showTitleExamples, setShowTitleExamples] = useState(false);
  const [showDescriptionExamples, setShowDescriptionExamples] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [editRequestError, setEditRequestError] = useState<string>();

  function saveEdits() {
    setIsSaving(true);

    const tags: Tag[] = selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));

    if (props.projectRequest !== null) {
      const sbtlAmountCents =
          props.displayCurrency === 'USD'
          ? subtotalCents
          : convertToUSDCents(props.displayCurrency, subtotalCents, props.exchangeRates)

      const body = {
        requestType: RequestType.PROJECT_REQUEST,
        requestId: props.projectRequest.id,
        title: requestTitle,
        description: requestDescription,
        tags: tags,
        subtotalAmountCents: sbtlAmountCents
      }

      API.post("api_requests_edit_request_path", body).then(function (result) {
        setIsSaving(false);

        if (result['error'] === true) {
          setEditRequestError(
            "Something went wrong. Our team has been notified and will investigate the issue. If you continue to experience problems submitting a request, please email us at hello@letslucia.com"
          )
        } else if (result['success'] === true) {
          switch (props.perspective) {
            case Perspective.Agent:
              window.location.href = `/agent/requests/p/${props.projectRequest.id}`
              break
            case Perspective.Property:
              window.location.href = `/property/requests/p/${props.projectRequest.id}`
              break
          }
        }
      })
    } else if (props.hourlyContract !== null ) {
      const hrlyRateCents =
          props.displayCurrency === 'USD'
          ? hourlyRateCents
          : convertToUSDCents(props.displayCurrency, hourlyRateCents, props.exchangeRates)

      const body = {
        requestType: RequestType.HOURLY_CONTRACT,
        requestId: props.hourlyContract.id,
        title: requestTitle,
        description: requestDescription,
        tags: tags,
        hourlyRateCents: hrlyRateCents
      }

      API.post("api_requests_edit_request_path", body).then(function (result) {
        setIsSaving(false);

        if (result['error'] === true) {
          setEditRequestError(
            "Something went wrong. Our team has been notified and will investigate the issue. If you continue to experience problems submitting a request, please email us at hello@letslucia.com"
          )
        } else if (result['success'] === true) {
          switch (props.perspective) {
            case Perspective.Agent:
              window.location.href = `/agent/requests/h/${props.hourlyContract.id}`
              break
            case Perspective.Property:
              window.location.href = `/property/requests/h/${props.hourlyContract.id}`
              break
          }
        }
      })
    }
  }

  function handleSaveClicked() {
    if (
      requestTitle.length > 0
      && requestDescription.length > 0
      && ((hourlyRateCents > 0 || props.hourlyContract?.acceptingOffers) || !props.hourlyContract)
      && ((subtotalCents >= 100 || props.projectRequest?.acceptingOffers) || !props.projectRequest)
    ) {
      saveEdits();
    }
    else {
      setShowErrors(true);
    }
  }

  const handleHourlyRateCentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setHourlyRateCents(parseInt(event.target.value) * 100);
  }

  const handleSubtotalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubtotalCents(parseInt(event.target.value) * 100);
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="fw-bold mb-3">Edit your request</h4>
        <div>
          <h6 className="fw-bold mb-0">Give your request a brief title.</h6>
          <div className="fs-md">Keep it short and simple. This will help CoPilots understand what you are looking for.</div>
          <input
            className="form-control mt-1 t--request-title"
            placeholder="Enter request title…"
            value={requestTitle}
            onChange={(event) => setRequestTitle(event.target.value)}
          />
          {htmlIf(showErrors && !requestTitle.length,
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter a title.</div>
          )}
          <button className="btn btn-link ps-0 text-decoration-none"
            onClick={() => setShowTitleExamples(!showTitleExamples)}
          >Some title examples</button>
          {htmlIf(showTitleExamples,
            <div className="mt-0 fs-sm">
              <ul>
                <li>AXUS itinerary creation</li>
                <li>Need new client email templates</li>
                <li>Urgent supplier help</li>
              </ul>
            </div>
          )}
        </div>
        <div className="mt-3">
          <h6 className="fw-bold mb-0">What are you looking to get done?</h6>
          <div className="fs-md">This will help get your request to the right CoPilot. Specifics help here.</div>
          <textarea
            className="form-control mt-1 t--request-description"
            rows={5}
            placeholder="Explain the scope of this request…"
            value={requestDescription}
            onChange={(event) => setRequestDescription(event.target.value)}
          />
          {htmlIf(showErrors && !(requestDescription.length > 0),
            <div className="fs-sm mt-1 text-danger fw-bold">Please enter a description.</div>
          )}
          <button className="btn btn-link ps-0 text-decoration-none"
            onClick={() => setShowDescriptionExamples(!showDescriptionExamples)}
          >How to write a great description</button>
          {htmlIf(showDescriptionExamples,
            <div className="mt-0 fs-sm">
              <span className="fw-bold">Pro tip!</span> Copilots look for:
              <ul>
                <li>Clear expectations about your request and the deliverables</li>
                <li>The skills required</li>
                <li>Explanation of scope and timeline</li>
              </ul>
            </div>
          )}
        </div>
        <div className="mt-3">
          <h6 className="fw-bold mb-1">Which skill(s) best match your request?</h6>
          <Select
            className="rounded"
            classNames={{
              control: (state) => 'form-control p-1 rounded-2',
              multiValue: (state) => 'rounded-1',
              dropdownIndicator: (state) => 'py-0'
            }}
            isMulti={true}
            options={props.allTags.map((tag) => (tagToReactSelectItem(tag)))}
            onChange={(newTags: ReactSelectOption[]) => setSelectedTagItems(newTags)}
            value={selectedTagItems}
            placeholder="Type to add skill tags to this request…"
          />
        </div>
        {htmlIf(props.hourlyContract !== null && props.hourlyContract !== undefined && !props.hourlyContract?.acceptingOffers,
          <div className="mt-3">
            <h6 className="fw-bold">Hourly Rate</h6>
            <div className="row mt-2 justify-content-between">
              <div className="col-6">
                <div className="col-sm-10 col-md-8 col-lg-6">
                  <div className={`input-group align-items-center p-0`}>
                    <div className="input-group-prepend ms-2">{currencySymbol(props.displayCurrency)}</div>
                    <input
                      className="form-control t--hourly-rate" type="number"
                      value={hourlyRateCents / 100}
                      min={0} max={5000}
                      onChange={handleHourlyRateCentsChange}
                    />
                  </div>
                </div>
                {htmlIf(showErrors && !(hourlyRateCents > 0),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                )}
                <div className="fs-sm mt-1">This is the rate you will be billed for every hour your CoPilot works.</div>
              </div>
            </div>
          </div>
        )}
        {htmlIf(props.projectRequest !== null && props.projectRequest !== undefined && !props.projectRequest?.acceptingOffers,
          <div className="mt-3">
            <h6 className="fw-bold">Request Amount</h6>
            <div className="row mt-2 justify-content-between">
              <div className="col-6">
                <div className="col-sm-10 col-md-8 col-lg-6">
                  <div className={`input-group align-items-center p-0`}>
                    <div className="input-group-prepend ms-2">{currencySymbol(props.displayCurrency)}</div>
                    <input
                      className="form-control t--request-subtotal" type="number"
                      value={subtotalCents / 100}
                      min={0} max={5000}
                      onChange={handleSubtotalChange}
                    />
                  </div>
                </div>
                {htmlIf(showErrors && !(subtotalCents > 0),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                )}
                <div className="fs-sm mt-1">This is the subtotal you will pay for the request.</div>
              </div>
            </div>
          </div>
        )}
        <div className="mt-3 d-flex">
          <button className="btn btn-primary px-4 t--save-edit-request" onClick={handleSaveClicked}>Save</button>
        </div>
      </div>
    </div>
  )
}

export default EditRequest;
