import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../Utils/API.js';
import { formatUSDWithCents } from '../../Utils/Currency';

type Props = {
  creditCodeCodeSetter: (code: string) => void,
  validationKey?: string
}

export const GiftCardEntry = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [giftCardCode, setGiftCardCode] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [availableBalanceCents, setAvailableBalanceCents] = useState<number>();
  const [giftCardApplied, setGiftCardApplied] = useState(false);

  function validateGiftCard() {
    props.creditCodeCodeSetter(null);
    setGiftCardApplied(false);

    setAvailableBalanceCents(null);
    setErrorMessage(null);

    const body = { creditCode: giftCardCode, validationKey: props.validationKey }
    API.post("agent_validate_credit_code_path", body).then(function (result) {
      if (typeof(result['error']) === 'string') {
        setErrorMessage(result['error'])
      }
      else {
        setAvailableBalanceCents(result['availableBalanceCents'])
      }
    })
  }

  function applyBalance() {
    props.creditCodeCodeSetter(giftCardCode);
    setGiftCardApplied(true);
  }

  function removeGiftCard() {
    props.creditCodeCodeSetter(null);
    setGiftCardApplied(false);

    setAvailableBalanceCents(null);
    setErrorMessage(null);

    setGiftCardCode('');
  }

  return (
    <div className="card rounded-2 mt-3">
      <div
        className="card-body cursor-pointer p-2 d-flex justify-content-between align-items-center fs-md text-dark fw-bold"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div>Add gift card</div>
        <i className={`ai-chevron-${isExpanded ? 'down' : 'right'}`} />
      </div>
      {isExpanded && (
        <div className="card-body p-2 pt-0">
          <div className="row align-items-center g-0 no-gutters">
            <div className="col">
              <input className="form-control t--gift-card-entry"
                placeholder="Enter gift card code…"
                value={giftCardCode}
                onChange={(event) => setGiftCardCode(event.target.value)}
              />
            </div>
            <div className="col-auto ms-2">
              { giftCardApplied
              ? <button className="btn btn-danger" onClick={removeGiftCard}>Remove</button>
              : <button className={`btn ${ availableBalanceCents > 0 ? 'btn-primary' : 'btn-secondary disabled text-white'}`}
                  onClick={applyBalance}
                >
                  Apply
                </button>
              }
            </div>
          </div>
          <div className="mt-1 fs-sm fw-bold">
            { errorMessage?.length > 0 ? (
              <div className="text-danger">
                {errorMessage}
              </div>
            ) : giftCardApplied ? (
              <div className="text-success">
                Gift card applied!
              </div>
            ) : availableBalanceCents > 0 ? (
              <div>
                Balance: {formatUSDWithCents(availableBalanceCents)}
              </div>
            ) : null}
          </div>
          <button className="btn btn-link text-secondary fs-sm fw-bold mt-1 p-0" onClick={validateGiftCard}>
            <i className="ai-gift me-1 fs-xl" />
            <div className="text-decoration-underline">Check Gift Card Balance</div>
          </button>
        </div>
      )}
    </div>
  );
}
