/**
 * A common enum to help determine if a page is being viewed by an Agent or a Copilot.
 */
export enum Perspective
  { Agent = 'agent'
  , Property = 'property'
  , Copilot = 'copilot'
  , Anonymous = 'anonymous'
  // The ViewOnly Perspective is used in cases when we want a component to be view-only (i.e. have no buttons),
  // like in the case of when we display CoPilot profiles to Agents in the New Request Flow.
  , ViewOnly = 'view-only'
  }

export default Perspective

// This list should be kept in sync with the list in `perspective.rb`.
