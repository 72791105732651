import * as React from 'react';
import { ContractStatus } from '../../Types/Hourly';
import { RequestCard, RequestType } from '../../Types/RequestCard';
import { RequestStatus } from '../../Types/ProjectRequest';
import { CustomerRequestStatusBadge } from '../../Common/RequestStatusBadge';

function requestDetailsPath(card: RequestCard) {
  switch (card.requestType) {
    case RequestType.PROJECT_REQUEST:
      return `/property/requests/p/${card.requestId}`
    case RequestType.HOURLY_CONTRACT:
      return `/property/requests/h/${card.requestId}`
  }
}

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    })
  )
}

export const PropertyRequestCard = ({request}: {request: RequestCard}) => {
  return (
    <div className="col-md-6 mb-2">
      <div className="card h-100 rounded-4">
        <a href={requestDetailsPath(request)} className="text-decoration-none">
          <div className="card-body px-3 py-2">
            <div className="row mt-1">
              <div className="col">
                <div className="h6">{request.title}</div>
                { request.copilot === null
                ? <>
                    { request.status === RequestStatus.WITHDRAWN || request.status === RequestStatus.REFUNDED || request.status === ContractStatus.WITHDRAWN
                    ? null
                    : <div className="fs-sm">CoPilot not yet assigned</div>
                    }
                  </>
                : <div className="d-flex align-items-center">
                    <div className="avatar avatar-xs">
                      <img className="avatar-img rounded-circle" src={request.copilot.imageUrl}/>
                    </div>
                    <div className="fs-sm ms-1">{request.copilot.preferredName}</div>
                  </div>
                }
                <div className="fs-xs mt-1">
                  Submitted on {formatDate(request.createdAt)}
                </div>
                <div className="mt-1 d-flex align-items-center">
                  <CustomerRequestStatusBadge status={request.status}/>
                  <div className="ms-1 fs-sm fw-bold">
                    {(() => {
                      switch (request.requestType) {
                        case RequestType.PROJECT_REQUEST:
                          return `Fixed rate`;
                        case RequestType.HOURLY_CONTRACT:
                          return `Hourly rate`;
                        default:
                          return null;
                      }
                    })()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  )
}

export default PropertyRequestCard;
