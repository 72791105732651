import * as React from 'react';
import Select from 'react-select';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../Utils/API';
import { Copilot } from '../../Types/Copilot';
import { Tag, tagToReactSelectItem, reactSelectItemToTag } from '../../Types/Tag';
import { Package, PackageFeatureRow } from '../../Types/Package';
import { htmlIf, formatMultiParagraphString } from '../../Utils/HTML';
import { formatUSDWithCents, formatUSDWithoutCents } from '../../Utils/Currency';
import { DismissibleModal } from '../../Components/Modal';

type Props =
  { individual: Copilot
  , packages: Package[]
  , allTags: Tag[]
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

enum PackageType
  { FIXED
  , HOURLY
  }

enum Modal
  { NoModal
  , AddPackageModal
  , AddFeatureRowModal
  }

enum FeatureRowOption
  { True = 'TRUE'
  , False = 'FALSE'
  , Text = 'TEXT'
  }

const Packages = (props: Props) => {
  const [existingPackages, setExistingPackages] = useState(props.packages);
  const [expandedPackages, setExpandedPackages] = useState<number[]>([]);

  const [modal, setModal] = useState(Modal.NoModal);

  const [newPackageTitle, setNewPackageTitle] = useState('');
  const [newPackageDescription, setNewPackageDescription] = useState('');
  const [newPackageTagItems, setNewPackageTagItems] = useState<ReactSelectOption[]>([]);

  const [newPackageType, setNewPackageType] = useState<PackageType>();
  const [newPackageHourlyRate, setNewPackageHourlyRate] = useState(0);
  const [newPackageBasicAmountCents, setNewPackageBasicAmountCents] = useState(0);
  const [newPackageStandardAmountCents, setNewPackageStandardAmountCents] = useState(0);
  const [newPackagePremiumAmountCents, setNewPackagePremiumAmountCents] = useState(0);

  const [newPackageFeatureRows, setNewPackageFeatureRows] = useState<PackageFeatureRow[]>([]);
  const featureRowTableRef = useRef(null);
  const [newFeatureRowTitle, setNewFeatureRowTitle] = useState('');
  const [basicOption, setBasicOption] = useState(FeatureRowOption.True);
  const [basicText, setBasicText] = useState('');
  const [standardOption, setStandardOption] = useState(FeatureRowOption.True);
  const [standardText, setStandardText] = useState('');
  const [premiumOption, setPremiumOption] = useState(FeatureRowOption.True);
  const [premiumText, setPremiumText] = useState('');

  const [showErrors, setShowErrors] = useState(false);

  const handleToggleExpand = (packageId: number) => {
    setExpandedPackages((prevExpandedPackages) => {
      if (prevExpandedPackages.includes(packageId)) {
        return prevExpandedPackages.filter((id) => id !== packageId);
      } else {
        return [...prevExpandedPackages, packageId];
      }
    });
  };

  const isExpanded = (packageId: number) => {
    return expandedPackages.includes(packageId);
  };

  const renderFeatureRowValue = (value: string) => {
    if (value === null || value.trim() === '') {
      return <i className="lead ai-cross text-danger"></i>;
    } else if (value.toLowerCase() === 'true') {
      return <i className="lead ai-check text-success"></i>;
    } else {
      return value;
    }
  };

  function submitNewPackage() {
    if (
      newPackageTitle.length > 0 &&
      newPackageDescription.length > 0 &&
      newPackageTagItems.length > 0 &&
      ( (newPackageType === PackageType.HOURLY && newPackageHourlyRate > 0) ||
        ( newPackageType === PackageType.FIXED &&
          newPackageBasicAmountCents > 0 &&
          newPackageStandardAmountCents > 0 &&
          newPackagePremiumAmountCents > 0
        )
      )
    ) {
        const tags: Tag[] = newPackageTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));

        const postBody = {
          title: newPackageTitle,
          description: newPackageDescription,
          tags: tags,
          hourlyRateCents: newPackageHourlyRate > 0 ? newPackageHourlyRate : null,
          basicAmountCents: newPackageBasicAmountCents > 0 ? newPackageBasicAmountCents : null,
          standardAmountCents: newPackageStandardAmountCents > 0 ? newPackageStandardAmountCents : null,
          premiumAmountCents: newPackagePremiumAmountCents > 0 ? newPackagePremiumAmountCents : null,
          featureRows: newPackageFeatureRows
        }

        API.post("copilot_account_submit_package_path", postBody).then(function (result) {
          const updatedPackages = result['packages'];
          setExistingPackages(updatedPackages);
          setModal(Modal.NoModal);
        })
    } else {
      setShowErrors(true);
    }
  }

  const ViewModal = () => {
    switch (modal) {
      case Modal.NoModal:
        return null
      case Modal.AddPackageModal:
        return (
          <DismissibleModal
            title={<h4>Create a Package</h4>}
            dialogClass="modal-xl"
            body={
              <div className="">
                <div className="fs-md mt-n1">
                  Note: we recommend writing down your package details somewhere else on your computer and then
                  copying and pasting your full package information here — this will make things easier for you!
                </div>
                <div className="">
                  <h6 className="fw-bold mb-0 mt-3">What do you want to call your package?</h6>
                  <div className="fs-md">Keep it short and simple. This will help customers understand what you’re offering.</div>
                  <input
                    className="form-control mt-1 t--package-title"
                    placeholder="Enter package title…"
                    value={newPackageTitle}
                    onChange={(event) => setNewPackageTitle(event.target.value)}
                  />
                  {htmlIf(showErrors && !(newPackageTitle.length > 0),
                    <div className="fs-sm mt-1 text-danger fw-bold">Please enter a title.</div>
                  )}
                </div>
                <div className="">
                  <h6 className="fw-bold mb-0 mt-3">What technologies, skills and expertise are you offering with this package?</h6>
                  <div className="fs-md mb-1">
                    Adding tags helps customers find packages that are right for them! Add up to 5.
                  </div>
                  <Select
                    className="rounded"
                    classNames={{
                      control: (state) => 'form-control p-1 rounded-2 t--package-tags',
                      multiValue: (state) => 'rounded-1',
                      dropdownIndicator: (state) => 'py-0'
                    }}
                    isMulti={true}
                    options={props.allTags.map((tag) => (tagToReactSelectItem(tag)))}
                    isOptionDisabled={() => newPackageTagItems.length >= 5}
                    onChange={(newTags: ReactSelectOption[]) => setNewPackageTagItems(newTags)}
                    value={newPackageTagItems}
                    placeholder="Type or select technology, skill or expertise…"
                  />
                  {htmlIf(showErrors && !(newPackageTagItems.length > 0),
                    <div className="fs-sm mt-1 text-danger fw-bold">Please select at least one tag.</div>
                  )}
                </div>
                <div className="mt-3">
                  <h6 className="fw-bold">How would you like to structure your package?</h6>
                  <div className="row">
                    <div className="col-md-6">
                      <div
                        className={"card h-100 rounded-2 cursor-pointer t--hourly-card " + (newPackageType === PackageType.HOURLY ? " border-primary" : "")}
                        onClick={() => setNewPackageType(PackageType.HOURLY)}
                      >
                        <div className="card-body p-3">
                          <i className={"ai-clock h3 mb-1 " + (newPackageType === PackageType.HOURLY ? "text-primary fw-bold" : "")} />
                          <h5 className={"mb-1 " + (newPackageType === PackageType.HOURLY ? "text-primary" : "")}>Charge by the hour</h5>
                          <div className="fs-sm">Hourly rates are great for long-term work or if you’re unsure how long a task will take.</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 mt-2 mt-md-0">
                      <div
                        className={"card h-100 rounded-2 cursor-pointer t--fixed-card " + (newPackageType === PackageType.FIXED ? " border-primary" : "")}
                        onClick={() => setNewPackageType(PackageType.FIXED)}
                      >
                        <div className="card-body p-3">
                          <i className={"ai-tag h3 mb-1 " + (newPackageType === PackageType.FIXED ? "text-primary" : "")} />
                          <h5 className={"mb-1 " + (newPackageType === PackageType.FIXED ? "text-primary" : "")}>Charge a fixed price</h5>
                          <div className="fs-sm">Fixed rate packages are ideal for clear and defined tasks. They have three tiers of pricing (Basic, Standard, and Premium).</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {htmlIf(showErrors && (newPackageType === null || newPackageType === undefined),
                    <div className="mt-1 text-danger fw-bold">Please select a package type before continuing.
                    </div>
                  )}
                </div>
                {htmlIf(newPackageType === PackageType.HOURLY,
                  <div className="mt-3">
                    <h6 className="fw-bold">Hourly Rate</h6>
                    <div className="input-group align-items-center p-0">
                      <div className="input-group-prepend ms-2">$</div>
                      <input
                        className="form-control t--hourly-rate" type="number"
                        value={newPackageHourlyRate / 100}
                        min={0} max={5000}
                        onChange={(e) => setNewPackageHourlyRate(parseInt(e.target.value) * 100)}
                      />
                    </div>
                    {htmlIf(showErrors && !(newPackageHourlyRate > 0),
                      <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                    )}
                  </div>
                )}
                {htmlIf(newPackageType === PackageType.FIXED,
                  <div className="mt-3">
                    <h6 className="fw-bold">Package Price (Basic Tier)</h6>
                    <div className="input-group align-items-center p-0">
                      <div className="input-group-prepend ms-2">$</div>
                      <input
                        className="form-control t--basic-amount-center" type="number"
                        value={newPackageBasicAmountCents / 100}
                        min={0} max={5000}
                        onChange={(e) => setNewPackageBasicAmountCents(parseInt(e.target.value) * 100)}
                      />
                    </div>
                    {htmlIf(showErrors && !(newPackageBasicAmountCents > 0),
                      <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                    )}
                    <h6 className="fw-bold mt-3">Package Price (Standard Tier)</h6>
                    <div className="input-group align-items-center p-0">
                      <div className="input-group-prepend ms-2">$</div>
                      <input
                        className="form-control t--standard-amount-cents" type="number"
                        value={newPackageStandardAmountCents / 100}
                        min={0} max={5000}
                        onChange={(e) => setNewPackageStandardAmountCents(parseInt(e.target.value) * 100)}
                      />
                    </div>
                    {htmlIf(showErrors && !(newPackageStandardAmountCents > 0),
                      <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                    )}
                    <h6 className="fw-bold mt-3">Package Price (Premium Tier)</h6>
                    <div className="input-group align-items-center p-0">
                      <div className="input-group-prepend ms-2">$</div>
                      <input
                        className="form-control t--premium-amount-cents" type="number"
                        value={newPackagePremiumAmountCents / 100}
                        min={0} max={5000}
                        onChange={(e) => setNewPackagePremiumAmountCents(parseInt(e.target.value) * 100)}
                      />
                    </div>
                    {htmlIf(showErrors && !(newPackagePremiumAmountCents > 0),
                      <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                    )}
                  </div>
                )}
                <hr className="mt-3"/>
                <div className="mt-3">
                  <h6 className="fw-bold mb-0">Package Inclusions</h6>
                  <div className="fs-md mb-1">
                    Packages have a set of "inclusions" or "features" that are displayed in a table.
                    Think of your inclusions as a way to demonstrate the differences between your Basic,
                    Standard, and Premium tiers. Some inclusions may be offered for all tiers of a package
                    (like an intro call), while others may only be offered for one or two tiers. It may be
                    helpful to take a look at{' '}
                    <a href="/agent/packages" target='_blank'>other packages</a>{' '}
                    (opens in new tab) for inspiration.
                  </div>
                  <table className="table text-center text-nowrap" ref={featureRowTableRef}>
                    <thead>
                      <tr>
                        <th>Inclusion</th>
                        <th>Basic</th>
                        <th>Standard</th>
                        <th>Premium</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {newPackageFeatureRows.map((row, index) => (
                        <tr key={row.id} className="align-middle">
                          <th className="text-start align-middle" style={{whiteSpace: 'normal'}}>{row.name}</th>
                          <td style={{whiteSpace: 'normal'}}>{renderFeatureRowValue(row.basicValue)}</td>
                          <td style={{whiteSpace: 'normal'}}>{renderFeatureRowValue(row.standardValue)}</td>
                          <td style={{whiteSpace: 'normal'}}>{renderFeatureRowValue(row.premiumValue)}</td>
                          <td>
                            <button className="btn btn-sm btn-outline-danger"
                              onClick={() => setNewPackageFeatureRows(prevRows => prevRows.filter((_, i) => i !== index))}
                            >
                              Remove
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="">
                    <button className="btn btn-success" onClick={() => setModal(Modal.AddFeatureRowModal)}>Add inclusion</button>
                  </div>
                </div>
                <div className="">
                  <h6 className="fw-bold mb-0 mt-3">Describe your package to customers</h6>
                  <div className="fs-md">
                    What are you offering? How can this package create value for customers?
                    Why should someone work with you? This is your opportunity to convince a customer
                    that they should buy your package — it‘s really important! Being detailed helps.
                  </div>
                  <textarea
                    className="form-control mt-1 t--package-description"
                    rows={7}
                    placeholder="Describe your package…"
                    value={newPackageDescription}
                    onChange={(event) => setNewPackageDescription(event.target.value)}
                  />
                  {htmlIf(showErrors && !(newPackageDescription.length > 0),
                    <div className="fs-sm mt-1 text-danger fw-bold">Please enter a description.</div>
                  )}
                </div>
              </div>
            }
            footer={
              <div className=''>
                <button className="btn btn-success" onClick={submitNewPackage}>
                  Submit package for review
                </button>
                <button className="btn btn-outline-danger ms-2" onClick={() => {
                  setModal(Modal.NoModal);
                  setShowErrors(false);
                }}>
                  Cancel + discard package
                </button>
              </div>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.AddFeatureRowModal:
        const resetFormToDefault = () => {
          setNewFeatureRowTitle('');
          setBasicOption(FeatureRowOption.True);
          setBasicText('');
          setStandardOption(FeatureRowOption.True);
          setStandardText('');
          setPremiumOption(FeatureRowOption.True);
          setPremiumText('');
        }

        const saveFeatureRow = () => {
          if (newFeatureRowTitle.length > 0) {
            const rowValue = (option: FeatureRowOption, text: string) => {
              switch (option) {
                case FeatureRowOption.True:
                  return 'TRUE'
                case FeatureRowOption.False:
                  return null
                case FeatureRowOption.Text:
                  return text
              }
            }

            const newFeatureRow:PackageFeatureRow = {
              id: newPackageFeatureRows.length,
              name: newFeatureRowTitle,
              description: null,
              basicValue: rowValue(basicOption, basicText),
              standardValue: rowValue(standardOption, standardText),
              premiumValue: rowValue(premiumOption, premiumText),
              position: newPackageFeatureRows.length + 1
            }

            setNewPackageFeatureRows(prevPackageFeatureRows => [...prevPackageFeatureRows, newFeatureRow]);
            setShowErrors(false);
            setModal(Modal.AddPackageModal);
            resetFormToDefault();
          } else {
            setShowErrors(true);
          }
        }

        return (
          <DismissibleModal
            title={<h4>Add an inclusion/feature row</h4>}
            body={
              <div>
                <h6 className="fw-bold mb-0">Title</h6>
                <div className="fs-md">Keep this short!</div>
                <input
                  className="form-control mt-1 t--feature-row-title"
                  placeholder="Enter inclusion name…"
                  value={newFeatureRowTitle}
                  onChange={(event) => setNewFeatureRowTitle(event.target.value)}
                />
                {htmlIf(showErrors && !newFeatureRowTitle.length,
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a title.</div>
                )}
                <div>
                  <h6 className="fw-bold mt-3">Basic Value</h6>
                  <select className="form-select"
                    value={basicOption} onChange={(e) => setBasicOption(e.target.value as FeatureRowOption)}
                  >
                    <option value={FeatureRowOption.True}>✅ (included)</option>
                    <option value={FeatureRowOption.False}>❌ (not included)</option>
                    <option value={FeatureRowOption.Text}>Custom Text</option>
                  </select>
                  {htmlIf(basicOption === FeatureRowOption.Text,
                    <>
                      <div className="fs-sm mt-2">Keep this short too!</div>
                      <input
                        className="form-control mt-1 t--basic-text-input"
                        placeholder="Enter basic value…"
                        value={basicText}
                        onChange={(event) => setBasicText(event.target.value)}
                      />
                    </>
                  )}
                </div>
                <hr className="my-3"/>
                <div className="">
                  <h6 className="fw-bold mt-2">Standard Value</h6>
                  <select className="form-select"
                    value={standardOption} onChange={(e) => setStandardOption(e.target.value as FeatureRowOption)}
                  >
                    <option value={FeatureRowOption.True}>✅ (included)</option>
                    <option value={FeatureRowOption.False}>❌ (not included)</option>
                    <option value={FeatureRowOption.Text}>Custom Text</option>
                  </select>
                  {htmlIf(standardOption === FeatureRowOption.Text,
                    <>
                      <div className="fs-sm mt-2">Keep this short too!</div>
                      <input
                        className="form-control mt-1 t--standard-text-input"
                        placeholder="Enter standard value…"
                        value={standardText}
                        onChange={(event) => setStandardText(event.target.value)}
                      />
                    </>
                  )}
                </div>
                <hr className="my-3"/>
                <div className="">
                  <h6 className="fw-bold mt-2">Premium Value</h6>
                  <select className="form-select"
                    value={premiumOption} onChange={(e) => setPremiumOption(e.target.value as FeatureRowOption)}
                  >
                    <option value={FeatureRowOption.True}>✅ (included)</option>
                    <option value={FeatureRowOption.False}>❌ (not included)</option>
                    <option value={FeatureRowOption.Text}>Custom Text</option>
                  </select>
                  {htmlIf(premiumOption === FeatureRowOption.Text,
                    <>
                      <div className="fs-sm mt-2">Keep this short too!</div>
                      <input
                        className="form-control mt-1 t--standard-text-input"
                        placeholder="Enter standard value…"
                        value={premiumText}
                        onChange={(event) => setPremiumText(event.target.value)}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex mt-3">
                  <button className="btn btn-success" onClick={saveFeatureRow}>Add</button>
                  <button className="btn btn-outline-danger ms-2" onClick={() => {
                    resetFormToDefault();
                    setShowErrors(false);
                    setModal(Modal.AddPackageModal);
                    featureRowTableRef.current?.scrollIntoView({ behavior: "auto", block: "nearest", inline: "nearest"});
                    // ^^^ This is not working right now... not sure why, but not worth the time to fix.
                  }}>Cancel</button>
                </div>
              </div>
            }
            onDismiss={() => setModal(Modal.AddPackageModal)}
          />
        )
    }
  }

  return (
    <>
      {ViewModal()}
      <div className="card card-body bg-faded-success mb-3 text-dark rounded-2">
        <div className="d-flex align-items-center">
          <span className="me-1 badge bg-success">New!</span>
          <div className="h4 mb-0">Submit a package for review by the Lucia team</div>
        </div>
        <div className="mt-3">
          <button className="btn btn-irish-green" onClick={() => setModal(Modal.AddPackageModal)}>
            Create a new package
          </button>
        </div>
      </div>
      <div>
      {existingPackages.map((packageItem) => (
        <div key={packageItem.id} className="card mb-3 rounded-2">
          <div className="card-header border-0" onClick={() => handleToggleExpand(packageItem.id)}>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h5 className="mb-0 me-1">{packageItem.title}</h5>
                {packageItem.active ? (
                  <div className="badge bg-faded-success text-success">Available for Sale</div>
                ) : (
                  <div className="badge bg-faded-danger text-danger">Inactive / Under Review</div>
                )}
              </div>
              <i className={`${isExpanded(packageItem.id) ? 'ai-chevron-up' : 'ai-chevron-down'}`} />
            </div>
          </div>
          {isExpanded(packageItem.id) && (
            <div className="card-body pt-0">
              <p className="card-text">{formatMultiParagraphString(packageItem.description)}</p>
              {packageItem.hourlyRateCents !== null ? (
                <p className="card-text">Hourly Rate: {formatUSDWithCents(packageItem.hourlyRateCents)}</p>
              ) : (
                <div>
                  <p className="card-text">Basic: {formatUSDWithCents(packageItem.basicAmountCents)}</p>
                  <p className="card-text">Standard: {formatUSDWithCents(packageItem.standardAmountCents)}</p>
                  <p className="card-text">Premium: {formatUSDWithCents(packageItem.premiumAmountCents)}</p>
                </div>
              )}
              <table className="table text-center text-nowrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Basic</th>
                    <th>Standard</th>
                    <th>Premium</th>
                  </tr>
                </thead>
                <tbody>
                  {packageItem.featureRows.map((row) => (
                    <tr key={row.id} className="align-middle">
                      <th className="text-start align-middle" style={{whiteSpace: 'normal'}}>{row.name}</th>
                      <td style={{whiteSpace: 'normal'}}>{renderFeatureRowValue(row.basicValue)}</td>
                      <td style={{whiteSpace: 'normal'}}>{renderFeatureRowValue(row.standardValue)}</td>
                      <td style={{whiteSpace: 'normal'}}>{renderFeatureRowValue(row.premiumValue)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
    </>
  )
}

export default Packages;
