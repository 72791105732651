import { Copilot } from "./Copilot"
import { Tag } from "./Tag"

export type Package =
  { id: number
  , active: boolean
  , title: string
  , description: string
  , imageUrl?: string
  , basicAmountCents: number
  , standardAmountCents: number
  , premiumAmountCents: number
  , hourlyRateCents: number
  , tags: Tag[]
  , featureRows: PackageFeatureRow[]
  , copilotIndividual?: Copilot
  }

export enum PackageTier
  { BASIC = 'basic'
  , STANDARD = 'standard'
  , PREMIUM = 'premium'
  }

export type PackageFeatureRow =
  { id: number
  , name: string
  , description: string
  , basicValue: string
  , standardValue: string
  , premiumValue: string
  , position: number
  }
