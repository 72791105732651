import * as React from 'react';
import { Tag } from '../Types/Tag';
import { formatMarkdown, htmlIf } from '../Utils/HTML';

export const RequestDescription = (description: string, tags: Tag[]) => (
  <div className="">
    <h3>Overview</h3>
    <div>{formatMarkdown(description)}</div>
    {htmlIf(tags?.length > 0,
      <>
        {tags?.map((tag) =>
          <div key={`tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.name}</div>
        )}
      </>
    )}
  </div>
)

export default RequestDescription;
