import * as React from 'react';
import { ContractStatus } from '../Types/Hourly';
import { RequestStatus } from "../Types/ProjectRequest";

/**
  * A component that displays a status badge based on the given request or contract status.
  * This should only be used when requests are being displayed to Agent. For
  * displaying requests to Copilots, use CopilotRequestStatusBadge instead.
  * @param {string} status - The status of the request.
  * @return {JSX.Element} - The status badge element.
  * @example <CustomerRequestStatusBadge status={RequestStatus.CLAIMED} />
*/
export const CustomerRequestStatusBadge = ({status}: {status: RequestStatus | ContractStatus}) => {
  switch(status) {
    case RequestStatus.SUBMITTED:
    case ContractStatus.SUBMITTED:
      return <div className="badge border text-secondary fs-xs">Waiting for CoPilot</div>
    case RequestStatus.DECLINED_PENDING:
    case ContractStatus.DECLINED_PENDING:
      return <div className="badge bg-warning text-dark fs-xs">Action Required: Declined by CoPilot</div>
    case RequestStatus.CLAIMED:
    case ContractStatus.CLAIMED:
      return <div className="badge bg-faded-info text-info fs-xs">In Progress</div>
    case RequestStatus.PAID_UNCLAIMED:
      return <div className="badge border text-secondary fs-xs">Waiting for CoPilot</div>
    case RequestStatus.UNDER_REVIEW:
      return <div className="badge bg-faded-warning text-warning fs-xs">Awaiting Review</div>
    case RequestStatus.COMPLETED:
    case ContractStatus.COMPLETED:
      return <div className="badge bg-faded-success text-success fs-xs">Completed</div>
    case RequestStatus.WITHDRAWN:
    case ContractStatus.WITHDRAWN:
      return <div className="badge bg-faded-danger text-danger fs-xs">Cancelled</div>
    case RequestStatus.REFUNDED:
      return <div className="badge bg-faded-danger text-danger fs-xs">Refunded</div>
    default:
      return <div></div>
  }
}

/**
  * A component that displays a status badge based on the given request or contract status.
  * This should only be used when requests are being displayed to Copilots. For
  * displaying requests to Agents, use CustomerRequestStatusBadge instead.
  * @param {string} status - The status of the request.
  * @return {JSX.Element} - The status badge element.
  * @example <CopilotRequestStatusBadge status={RequestStatus.CLAIMED} />
*/
export const CopilotRequestStatusBadge = ({status, isDirectRequest}: {status: RequestStatus | ContractStatus, isDirectRequest?: boolean}) => {
  switch(status) {
    case RequestStatus.SUBMITTED:
    case ContractStatus.SUBMITTED:
    case RequestStatus.PAID_UNCLAIMED:
      return (
        isDirectRequest
        ? <div className="badge bg-faded-success text-success fs-xs">Direct Request</div>
        : <div className="badge bg-faded-secondary text-secondary fs-xs">Open to all CoPilots</div>
      )
    case RequestStatus.DECLINED_PENDING:
    case ContractStatus.DECLINED_PENDING:
      // Copilots should not be able to see requests in DECLINED_PENDING status but we'll keep
      // this here just in case!
      return <div className="badge bg-warning text-dark fs-xs">Declined</div>
    case RequestStatus.CLAIMED:
    case ContractStatus.CLAIMED:
      return <div className="badge bg-faded-info text-info fs-xs">In Progress</div>
    case RequestStatus.UNDER_REVIEW:
      return <div className="badge bg-faded-warning text-warning fs-xs">Under Review</div>
    case RequestStatus.COMPLETED:
    case ContractStatus.COMPLETED:
      return <div className="badge bg-accent text-dark fs-xs">Completed</div>
    case RequestStatus.WITHDRAWN:
    case ContractStatus.WITHDRAWN:
      return <div className="badge bg-faded-danger text-danger fs-xs">Cancelled</div>
    case RequestStatus.REFUNDED:
      return <div className="badge bg-faded-danger text-danger fs-xs">Refunded</div>
    default:
      return <div></div>
  }
}
