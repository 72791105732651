import { Copilot } from "./Copilot"
import { Package } from "./Package"
import Perspective from "./Perspective"

export type Event =
  { id: number
  , title: string
  , description: string
  , audienceType: AudienceType
  , startTime: string
  , durationMinutes: number
  , registrationUrl: string
  , recordingUrl: string
  , copilotIndividual: Copilot
  , package: Package
  , imageUrl: string
  , slug: string
  , createdAt: string
  , onPlatformRegistration: boolean
  }

export enum AudienceType
  { COPILOT = 'copilot'
  , CLIENT = 'client'
  }

export function eventPath(event: Event, perspective: Perspective) {
  switch(perspective) {
    case Perspective.Copilot:
      return `/copilot/livestreams/${event.id}/${event.slug}`
    default:
      return `/agent/livestreams/${event.id}/${event.slug}`
  }
}
