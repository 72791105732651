import * as React from 'react';
import { useState } from 'react';
import * as Livestream from '../Types/Livestream';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { isInTheFuture } from '../Utils/DateTime';
import Perspective from '../Types/Perspective';

type Props =
  { event: Livestream.Event
  , perspective: Perspective
  , targetBlank?: boolean
  }

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    })
  )
}

function formatTime(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit'
    })
  )
}

const EventCard = (props: Props) => {
  return (
    <>
      <a className={`text-decoration-none t--livestream-event-id-${props.event.id}`}
        href={Livestream.eventPath(props.event, props.perspective)}
        target={props.targetBlank ? '_blank' : null}
      >
        <div className="card h-100 rounded">
          <div className="card-img-top bg-secondary rounded-top" style={{ width: '100%', height: '0', paddingTop: '56.25%', position: 'relative' }}>
            {/* Set for a 16:9 aspect ratio */}
            <img
              src={props.event.imageUrl}
              style={{ position: 'absolute', top: '0', left: '0', width: '558px', height: 'auto'}}
              className="rounded-top"
              loading='lazy'
            />
          </div>
          <div className="card-body p-3 d-flex flex-column">
            <div className="fs-md fw-semibold text-gray-900 mb-1">{props.event.title}</div>
            <div className="fs-sm text-gray-900 mt-auto">
              {formatDate(props.event.startTime)} at {formatTime(props.event.startTime)}
              {maybeHtml(props.event.durationMinutes, (duration) => (
                <>
                  {" • "}{duration} minutes
                </>
              ))}
            </div>
            <div className="mt-1 text-primary fw-semibold">
              { isInTheFuture(props.event.startTime)
              ? "Register"
              : props.event.recordingUrl
                ? "Watch now"
                : "Recording coming soon"
              }
            </div>
          </div>
        </div>
      </a>
    </>
  )
}

export default EventCard;
