import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { Copilot } from '../Types/Copilot';
import { ProjectRequest, RequestStatus, RequestOfferStatus, ProjectRequestOffer } from '../Types/ProjectRequest';
import { CopilotRequestStatusBadge } from '../Common/RequestStatusBadge';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, maybeHtml, formatMultiParagraphString } from '../Utils/HTML';
import { formatUSDWithCents } from '../Utils/Currency';
import RequestDescription from '../Common/RequestDescription';
import { ReadOnlyRating } from '../Agent/Components/Ratings';
import { CustomerRating } from '../Types/CustomerRating';

type Props =
  { request: ProjectRequest
  , currentCopilot: Copilot
  , backUrl: string
  , negotiationEnabled: boolean
  }

enum Modal
  { NoModal
  , DeclineRequestModal
  , SubmitForReviewModal
  , ClaimRequestModal
  , SubmitOfferModal
  , EditOfferModal
  , RescindOfferModal
  }

function messagesPathForCustomerUserId(customerUserId: number) {
  return `/copilot/messages?other_user_id=${customerUserId}`
}

const copilotPaymentPercentage = 0.8

const RequestDetails = (props: Props) => {
  const [request, setRequest] = useState(props.request);
  const [modal, setModal] = useState(Modal.NoModal);
  const [showModalErrors, setShowModalErrors] = useState(false);
  const [showOfferExampleText, setShowOfferExampleText] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [offerAmountCents, setOfferAmountCents] = useState(0);
  const [offerDescription, setOfferDescription] = useState('');

  const [declineMessage, setDeclineMessage] = useState('');

  function declineRequest(request: ProjectRequest) {
    setIsSubmitting(true);
    const body = { requestId: request.id, message: declineMessage }
    API.post("copilot_requests_decline_project_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
      }
      setModal(Modal.NoModal);
      setIsSubmitting(false);
    })
  }

  function claimRequest(request: ProjectRequest) {
    setIsSubmitting(true);
    const body = { requestId: request.id, copilotId: props.currentCopilot.id }
    API.post("copilot_requests_claim_project_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
      }
      setModal(Modal.NoModal);
      setIsSubmitting(false);
    })
  }

  function submitForReview(request: ProjectRequest) {
    setIsSubmitting(true);
    const body = { requestId: request.id }
    API.post("copilot_requests_submit_project_request_for_review_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
      }
      setModal(Modal.NoModal)
      setIsSubmitting(false);
    })
  }

  function submitOffer() {
    if (offerDescription.length > 0 && offerAmountCents > 0) {
      setIsSubmitting(true);
      const body =
        { requestId: request.id
        , copilotId: props.currentCopilot.id
        , amountCents: offerAmountCents
        , description: offerDescription
        }
      API.post("copilot_requests_submit_project_request_offer_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setRequest(result['request']);
        }
        setModal(Modal.NoModal);
        setOfferAmountCents(0);
        setOfferDescription('');
        setIsSubmitting(false);
      })
    } else {
      setShowModalErrors(true);
    }
  }

  function rescindOffer() {
    setIsSubmitting(true);
    const offer = request.offers.find((offer) => offer.copilot.id === props.currentCopilot.id)

    const body = { requestOfferId: offer.id }
    API.post("copilot_requests_rescind_project_request_offer_path", body).then(function (result) {
      if (result['error']) {
      } else {
        setRequest(result['request'])
      }
      setModal(Modal.NoModal)
      setIsSubmitting(false);
    })
  }

  function editOffer() {
    if (offerDescription.length > 0 && offerAmountCents > 0) {
      setIsSubmitting(true);
      const offer = request.offers.find((offer) => offer.copilot.id === props.currentCopilot.id)

      const body =
        { requestOfferId: offer.id
        , amountCents: offerAmountCents
        , description: offerDescription
        }

      API.post("copilot_requests_edit_project_request_offer_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setRequest(result['request']);
        }
        setModal(Modal.NoModal);
        setIsSubmitting(false);
        setOfferAmountCents(0);
        setOfferDescription('');
      })
    } else {
      setShowModalErrors(true);
    }
  }

  function formatDate(dateString: string):string {
    const date = new Date(dateString)
    return (
      date.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      })
    )
  }

  const DeclineRequestButton = () => {
    return (
      <button className="btn btn-outline-danger px-3 ms-2" onClick={() => setModal(Modal.DeclineRequestModal)}>
        Decline Request
      </button>
    )
  }

  const ClaimRequestButton = () => {
    return (
      <button className="btn btn-success px-3 ms-2" onClick={() => setModal(Modal.ClaimRequestModal)}>
        Claim Request
      </button>
    )
  }

  const SubmitForReviewButton = () => {
    return (
      <button className="btn btn-success px-3 ms-2" onClick={() => setModal(Modal.SubmitForReviewModal)}>
        Mark Request Complete
      </button>
    )
  }

  const ViewMessagesButton = () => {
    return (
      <a className="btn btn-outline-primary px-3 ms-2" href={messagesPathForCustomerUserId(request.customerUserId)}>
        Messages
      </a>
    )
  }

  const SubmitOfferButton = () => (
    <button className="btn btn-primary px-3 ms-2" onClick={() => setModal(Modal.SubmitOfferModal)}>
      Make an offer
    </button>
  )

  // We use this same body for the SubmitOffer and EditOffer modal, so no reason to repeat it
  const EnterOfferDetailsModalBody = ():React.ReactElement => (
    <div>
      <div className="row">
        <div className="col-md-6">
          <h6 className="fw-bold">Your offered amount</h6>
          <div className="input-group align-items-center p-0">
            <div className="input-group-prepend ms-2">$</div>
            <input
              className="form-control t--offer-amount" type="number"
              value={offerAmountCents / 100} min={0} max={5000}
              onChange={(event) => setOfferAmountCents(parseInt(event.target.value) * 100)}
            />
          </div>
        </div>
        <div className="col-md-6 mt-2 mt-md-0">
          <h6 className="fw-bold">Your take-home earnings</h6>
          <input
            className="form-control"
            value={offerAmountCents ? formatUSDWithCents(offerAmountCents * copilotPaymentPercentage) : '$0'}
            disabled={true}
          />
          {/* </div> */}
        </div>
      </div>
      {htmlIf(showModalErrors && !(offerAmountCents > 0),
          <div className="fs-sm mt-1 text-danger fw-bold">Please enter an amount.</div>
        )}
      <div className="mt-3">
        <h6 className="fw-bold">Message</h6>
        <textarea className="form-control"
          placeholder="Share a message with the agent to give them more information about your offer."
          rows={6}
          value={offerDescription}
          onChange={(event) => setOfferDescription(event.target.value)}
        />
        {htmlIf(showModalErrors && !offerDescription.length,
          <div className="fs-sm mt-1 text-danger fw-bold">Please enter a message.</div>
        )}
        <button className="btn btn-link ps-0 text-decoration-none"
          onClick={() => setShowOfferExampleText(!showOfferExampleText)}
        >Need help writing a great offer message?</button>
        {htmlIf(showOfferExampleText,
          <div className="mt-0 fs-sm">
            <div className="fw-bold">Here's an example:</div>
            Hi there,
            <br />
            I’d love to help you with this! I used to work as a travel agency assistant for 3 years and I’m very familiar with building in Travefy. I’ve also completed their online certification.  Please feel free to reach out with any questions and I look forward to hopefully working with you!
            <br />
            Cheers,
            Lucia
          </div>
        )}
      </div>
    </div>
  )

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.DeclineRequestModal:
        return (
          <DismissibleModal
            title={
              <h4>Decline this request?</h4>
            }
            body={
              <div>
                <div className="">{`Please share a brief message with ${request.customerName} to explain why you’re declining their request:`}</div>
                <textarea className="form-control mt-2"
                  placeholder="Share some context on why you aren’t interested in this request…"
                  rows={4}
                  value={declineMessage}
                  onChange={(event) => setDeclineMessage(event.target.value)}
                />
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, not yet
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2"
                  onClick={() => declineRequest(request)}
                  disabled={isSubmitting}
                >
                  Yes, decline it
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.SubmitForReviewModal:
        return (
          <DismissibleModal
            title={
              <h4>Mark this request complete?</h4>
            }
            body={
              <div>
                Are you sure you want to mark this request as complete and submit it for review?
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, keep it
                </button>
                <button className="btn btn-success w-100 w-sm-auto ms-sm-2"
                  onClick={() => submitForReview(request)}
                  disabled={isSubmitting}
                >
                  Yes, submit for review
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.ClaimRequestModal:
        return (
          <DismissibleModal
            title={<h4>Before you claim this request…</h4>}
            body={
              <div>
                <ol>
                  <li>Do you understand the scope of work required?</li>
                  <li>Do you have experience with all of the technologies that are involved in the task?</li>
                  <li>Does your availability align with the client’s needs?</li>
                </ol>
                <div>Be sure you have the answers you need before claiming the request!</div>
              </div>
            }
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-success w-100 w-sm-auto ms-sm-2"
                  onClick={() => claimRequest(request)}
                  disabled={isSubmitting}
                >
                  Yes, claim it!
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.SubmitOfferModal:
        return (
          <DismissibleModal
            title={
              <>
                <h4 className="mb-1">Make an offer</h4>
                <div className="fs-sm mb-3">Fixed rate request</div>
              </>
            }
            body={EnterOfferDetailsModalBody()}
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                  onClick={submitOffer}
                  disabled={isSubmitting}
                >
                  Submit offer
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.EditOfferModal:
        return (
          <DismissibleModal
            title={
              <>
                <h4 className="mb-1">Edit offer</h4>
                <div className="fs-sm mb-3">Fixed rate request</div>
              </>
            }
            body={EnterOfferDetailsModalBody()}
            footer={
              <>
                <button className="btn btn-outline-danger w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  Cancel
                </button>
                <button className="btn btn-primary w-100 w-sm-auto ms-sm-2"
                  onClick={editOffer}
                  disabled={isSubmitting}
                >
                  Save offer
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.RescindOfferModal:
        return (
          <DismissibleModal
            title={<h4>Rescind your offer?</h4>}
            body={<div>Are you sure you want to rescind your offer to work on this request?</div>}
            footer={
              <>
                <button className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
                  onClick={() => setModal(Modal.NoModal)}
                  disabled={isSubmitting}
                >
                  No, never mind
                </button>
                <button className="btn btn-danger w-100 w-sm-auto ms-sm-2"
                  onClick={() => rescindOffer()}
                  disabled={isSubmitting}
                >
                  Yes, rescind my offer
                </button>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      default:
        return null
    }
  }

  const ViewRequestHeader = () => (
    <div className="card mt-3">
      <div className="card-body">
        <div className="d-flex align-items-center mb-2">
          <h5 className="mb-0">{request.title}</h5>
          {htmlIf(request.isAgentVirtuoso,
            <div className="ms-auto badge bg-info text-white">Virtuoso Member</div>
          )}
        </div>
        <div className="d-flex align-items-center">
          <div className="fs-sm">Requested by {request.customerName}</div>
          <div className="ms-2">
            <CopilotRequestStatusBadge status={request.status} isDirectRequest={request.copilot?.id > 0}/>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="fs-sm">Estimated Earnings</div>
            <div className="lead fw-semibold text-dark mt-1">
              { request.acceptingOffers
                ? "Awaiting offers"
                : formatUSDWithCents(request.copilotAmountDueCents)
              }
            </div>
          </div>
          <div className="col">
            <div className="fs-sm">Submitted On</div>
            <div className="fw-semibold text-dark mt-1">{formatDate(request.createdAt)}</div>
          </div>
          {htmlIf(request.status === RequestStatus.WITHDRAWN && !!request.withdrawnAt,
            <div className="col">
              <div className="fs-sm">Cancelled On</div>
              <div className="fw-semibold text-dark mt-1">{formatDate(request.withdrawnAt)}</div>
            </div>
          )}
          {htmlIf(request.status === RequestStatus.COMPLETED && !!request.completedAt,
            <div className="col">
              <div className="fs-sm">Completed On</div>
              <div className="fw-semibold text-dark mt-1">{formatDate(request.completedAt)}</div>
            </div>
          )}
          {htmlIf(request.status === RequestStatus.REFUNDED && !!request.refundedAt,
            <div className="col">
              <div className="fs-sm">Refunded On</div>
              <div className="fw-semibold text-dark mt-1">{formatDate(request.refundedAt)}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  )

  function prepareToEditOffer(offer: ProjectRequestOffer) {
    setOfferDescription(offer.description);
    setOfferAmountCents(offer.amountCents);
    setModal(Modal.EditOfferModal)
  }

  const ViewOffer = (offer: ProjectRequestOffer) => (
    <div className="card mt-3">
      <div className="card-body">
      <h5 className="d-flex align-items-center justify-content-between mb-0">
          <div>Your offer</div>
          <button className="btn btn-link px-0 text-decoration-none text-primary" onClick={() => prepareToEditOffer(offer)}>
            <i className="ai-edit-alt text-primary me-1" />
            Edit
          </button>
        </h5>
        <div>{formatMultiParagraphString(offer.description)}</div>
        <div className="row mt-3">
          <div className="col-auto">
            <div className="fs-sm">Offered amount</div>
            <div className="fs-lg fw-semibold text-dark mt-1">{formatUSDWithCents(offer.amountCents)}</div>
          </div>
          <div className="col ms-3">
            <div className="fs-sm">Your take-home earnings</div>
            <div className="fs-lg fw-semibold text-dark mt-1">{formatUSDWithCents(offer.amountCents * copilotPaymentPercentage)}</div>
          </div>
        </div>
        <button className="btn btn-outline-danger mt-2" onClick={() => setModal(Modal.RescindOfferModal)}>
          Rescind Offer
        </button>
      </div>
    </div>
  )

  const ViewRating = (customerRating: CustomerRating) => (
    <div className="card mt-3">
      <div className="card-body">
        <h5 className="mb-0">Rating and feedback</h5>
        <div className="row mt-3">
          <div className="col-6 col-md-3">
            <ReadOnlyRating rating={customerRating.rating} starClass={'fs-xl fw-bold text-primary'}/>
            <div className="fs-md fw-semibold text-gray-900">Overall Rating</div>
          </div>
          {maybeHtml(customerRating.qualityRating, (quality) => (
            <div className="col-6 col-md-3">
              <ReadOnlyRating rating={quality} starClass={'fs-md text-primary'}/>
              <div className="fs-md text-gray-900">Quality of Work</div>
            </div>
          ))}
          {maybeHtml(customerRating.communicationRating, (communication) => (
            <div className="col-6 col-md-3">
              <ReadOnlyRating rating={communication} starClass={'fs-md text-primary'}/>
              <div className="fs-md text-gray-900">Communication</div>
            </div>
          ))}
          {maybeHtml(customerRating.timeRating, (time) => (
            <div className="col-6 col-md-3">
              <ReadOnlyRating rating={time} starClass={'fs-md text-primary'}/>
              <div className="fs-md text-gray-900">Timeliness</div>
            </div>
          ))}
        </div>
        {maybeHtml(customerRating.ratingComment, (publicFeedback) => (
          <div className="mt-3">
            <div className="fs-md fw-semibold text-gray-900">Public rating comment</div>
            <div className="fs-md">{publicFeedback}</div>
          </div>
        ))}
        {maybeHtml(customerRating.copilotFeedback, (copilotFeedback) => (
          <div className="mt-3">
            <div className="fs-md fw-semibold text-gray-900">Private feedback</div>
            <div className="fs-md">{copilotFeedback}</div>
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <div>
      {ViewModal()}
      <div className="row align-items-end">
        <div className="col-auto d-flex align-items-center">
          <a href={props.backUrl} className="text-decoration-none align-items-center d-flex">
            <i className="ai-arrow-left me-1"/>
            <i className="ai-grid me-1"/>
            Back
          </a>
        </div>
        <div className="col d-flex justify-content-end mt-1 mt-md-0">
          {/* We only show the Claim button when a request is submitted directly to a copilot or a rate is specified. */}
          {htmlIf((
            ( request.status === RequestStatus.SUBMITTED || request.status === RequestStatus.PAID_UNCLAIMED ) &&
            ( request.copilot?.id === props.currentCopilot.id || !request.acceptingOffers )
          ),
            <ClaimRequestButton />
          )}
          {/* We only show the Decline button when a request is submitted directly to a copilot. Directly submitted requests
              will have the status SUBMITTED or PAID_UNCLAIMED and must also be already assigned to the current copilot.
           */}
          {htmlIf((request.status === RequestStatus.SUBMITTED || request.status === RequestStatus.PAID_UNCLAIMED)
                  && request.copilot?.id === props.currentCopilot.id,
            <DeclineRequestButton />
          )}
          {/* If the agent wanted to accept offers, we show the Make an Offer button instead,
              provided that the Copilot has not already submitted an offer.
           */}
          {htmlIf((
            request.status === RequestStatus.SUBMITTED &&
            request.copilot === null &&
            request.acceptingOffers &&
            request.offers.every((offer) => offer.copilot.id !== props.currentCopilot.id)
          ),
            <>
            { props.negotiationEnabled
            ? <SubmitOfferButton />
            : <ClaimRequestButton />
            }
            </>
          )}
          {htmlIf(request.status === RequestStatus.CLAIMED || request.status === RequestStatus.COMPLETED,
            <ViewMessagesButton />
          )}
          {htmlIf(request.status === RequestStatus.CLAIMED,
            <SubmitForReviewButton />
          )}
        </div>
      </div>
      {ViewRequestHeader()}
      <div className="card card-body mt-3">
        {RequestDescription(request.description, request.tags)}
      </div>
      {htmlIf(request.status === RequestStatus.SUBMITTED,
        <>
        {maybeHtml(request.offers.find((offer) => offer.copilot.id === props.currentCopilot.id), (offer) => (
          <>
            { offer.status === RequestOfferStatus.RESCINDED
            ? <>
                <div className="card card-body mt-3">You rescinded your offer on this request.</div>
              </>
            : ViewOffer(offer)
            }
          </>
        ))}
        </>
      )}
      {maybeHtml(request.customerRating, (rating) => (
        ViewRating(rating)
      ))}
    </div>
  )
};


export default RequestDetails;
