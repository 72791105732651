import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import * as API from '../Utils/API.js';
import { Copilot } from '../Types/Copilot';
import { Tag, tagToReactSelectItem, reactSelectItemToTag } from '../Types/Tag';
import { ContractLength, HourlyContract } from '../Types/Hourly';
import { CopilotSearchSort } from './Components/CopilotSearchSort';
import { CopilotCard, emptyClickHandler } from './Components/CopilotCard';
import { PaymentMethod, formatCardBrand, formatCardFunding } from '../Types/PaymentMethod';
import { RequestType } from '../Types/RequestCard';
import { DismissibleModal } from '../Components/Modal';
import { formatMultiParagraphString, htmlIf, maybeHtml } from '../Utils/HTML';
import { CurrencyCode, ExchangeRate, convertUSDCentsToCurrency, formatCurrencyWithCents, currencySymbol, formatUSDWithCents, formatUSDWithoutCents, convertToUSDCents } from '../Utils/Currency';
import { GiftCardEntry } from './Components/GiftCardEntry';

type Props =
  { copilots: Copilot[]
  , platformFeePercent: number
  , paymentMethods: PaymentMethod[]
  , presetRequestTitle: string
  , presetCopilotIndividualId?: number
  , utmLcSource?: string
  , allTags: Tag[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  // Why are we passing subscription pricing to the New Request flow? In the event that the Agent
  // does not have an active subscription, we offer them an option to upgrade at the end of the
  // New Request flow, and we need to know how much to charge them!
  , monthlySubscriptionPriceCents: number
  , annualSubscriptionPriceCents: number
  , isSubscriptionActive: boolean
  }

  enum Step
  { EnterTags
  , EnterDescription
  , EnterTitle
  , SelectCopilot
  , EnterBudget
  , SubmitRequest
  }

type ReactSelectOption =
  { value: number
  , label: string
  }

enum MembershipOption
  { Free = 'free'
  , Monthly = 'monthly'
  , Annual = 'annual'
  }

const termsAndConditionsPath = '/terms-of-service'
const agentAccountBillingPath = '/agent/account/billing'
const agentRequestsPath = '/agent/requests'
const agentDashboardPath = '/agent'

// When submitting project requests to the pool and accepting offers, we set the price of the request
// to 50¢ in order to allow Stripe to still process a hold on the request.
const projectRequestOfferAmountCents = 50

const NewRequest = (props: Props) => {
  const [currentStep, setCurrentStep] = useState(Step.EnterTags);
  const [completedSteps, setCompletedSteps] = useState(new Set<Step>());

  const [requestTitle, setRequestTitle] = useState(props.presetRequestTitle);

  const [requestDescription, setRequestDescription] = useState('');

  const [selectedTagItems, setSelectedTagItems] = useState<ReactSelectOption[]>([]);

  const [requestType, setRequestType] = useState<RequestType>();
  const [hourlyRateCents, setHourlyRateCents] = useState(0);
  const [subtotalCents, setSubtotalCents] = useState(0);
  const [isAcceptingOffers, setIsAcceptingOffers] = useState(false);

  const [shareRequestWithPool, setShareRequestWithPool] = useState<Boolean>();
  const [selectedCopilot, setSelectedCopilot] = useState<Copilot>(() => {
    const copilotToSelect = props.presetCopilotIndividualId > 0
    ? props.copilots.find(copilot => copilot.id === props.presetCopilotIndividualId)
    : undefined

    if (copilotToSelect) {
      setShareRequestWithPool(false);
      setIsAcceptingOffers(false);
      copilotToSelect.hourlyRateCents
      ?
        setHourlyRateCents(Math.round(
          convertUSDCentsToCurrency(props.displayCurrency, copilotToSelect.hourlyRateCents, props.exchangeRates) / 100
        ) * 100)
      : setHourlyRateCents(0)
    }

    return copilotToSelect;
  });

  const [showErrors, setShowErrors] = useState(false);

  const subscriptionUpgradeRef = useRef(null);
  const [subscriptionUpgradeOption, setSubscriptionUpgradeOption] = useState<MembershipOption>();

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(props.paymentMethods);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(props.paymentMethods[0].id);
  const [showAddPaymentMethodModal, setShowAddPaymentModal] = useState(false);
  const [creditCodeCode, setCreditCodeCode] = useState<string>();

  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const [submitRequestError, setSubmitRequestError] = useState<string>();

  // // This hook creates an event listener that will warn the user if they attempt to
  // // navigate away from the page (either by reloading or going to an external link).
  // // This does not, however, warn the user if they click to another link within our website.
  // // That's decidedly more complex, due to the lack of full page reloads in our application.
  // useEffect(() => {
  //   function handleBeforeUnload(event: BeforeUnloadEvent) {
  //     if (isSubmittingRequest) {
  //       return
  //     }

  //     event.preventDefault();
  //     event.returnValue = 'Are you sure you want to leave?';
  //   }

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "auto" });

    setShowErrors(false);
  }, [currentStep])

  function upgradeSubscriptionAndSubmitRequest() {
    setIsSubmittingRequest(true);

    let postBody = {
      subscriptionDuration: subscriptionUpgradeOption,
      paymentMethodId: selectedPaymentMethodId
    }

    API.post("api_subscriptions_upgrade_agent_subscription_charge_path", postBody).then(function (result) {
      if (result['success']) {
        submitRequest();
      } else {
        setSubmitRequestError(
          "Something went wrong. Our team has been notified and will investigate the issue. If you continue to experience problems submitting a request, please email us at hello@letslucia.com"
        )
        console.log("Failed to upgrade subscription. Request has not been submitted.")
      }
    })
  }

  function submitRequest() {
    setIsSubmittingRequest(true);

    const tags: Tag[] = selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));

    let postBody = {
      requestType: requestType,
      requestTitle: requestTitle,
      requestDescription: requestDescription,
      paymentMethodId: selectedPaymentMethodId,
      copilotId: (shareRequestWithPool ? null : selectedCopilot.id),
      utmLcSource: props.utmLcSource,
      tags: tags
    }

    switch (requestType) {
      case RequestType.PROJECT_REQUEST:
        const sbtlAmountCents =
          subtotalCents === projectRequestOfferAmountCents
          ? projectRequestOfferAmountCents
          : props.displayCurrency === 'USD'
            ? subtotalCents
            : convertToUSDCents(props.displayCurrency, subtotalCents, props.exchangeRates)

        postBody['subtotalAmountCents'] = sbtlAmountCents;
        postBody['creditCodeCode'] = creditCodeCode;
      case RequestType.HOURLY_CONTRACT:
        const hrlyRateCents =
          props.displayCurrency === 'USD'
          ? hourlyRateCents
          : convertToUSDCents(props.displayCurrency, hourlyRateCents, props.exchangeRates)

        postBody['hourlyRateCents'] = hrlyRateCents
    }

    API.post("api_requests_submit_agent_request_path", postBody).then(function (result) {
      setIsSubmittingRequest(false);

      if (result['error']) {
        if (result['message']) {
          setSubmitRequestError(result['message'])
        } else {
          setSubmitRequestError(
            "Something went wrong. Our team has been notified and will investigate the issue. If you continue to experience problems submitting a request, please email us at hello@letslucia.com"
          )
        }
      } else if (result['success'] === true) {
        window.location.href = agentRequestsPath
      }
    })
  }

  function platformFeeAmountCents(): number {
    return (subtotalCents * props.platformFeePercent * 0.01)
  }

  function totalAmountCents(): number {
    return (subtotalCents + platformFeeAmountCents())
  }

  const ViewProgressNav = () => {
    const steps = [
      { number: 1, title: 'Skills', icon: 'ai-star', step: Step.EnterTags },
      { number: 2, title: 'Description', icon: 'ai-edit-alt', step: Step.EnterDescription },
      { number: 3, title: 'Title', icon: 'ai-edit', step: Step.EnterTitle },
      { number: 4, title: 'CoPilot', icon: 'ai-user', step: Step.SelectCopilot },
      { number: 5, title: 'Budget', icon: 'ai-dollar', step: Step.EnterBudget },
      { number: 6, title: 'Review', icon: 'ai-check', step: Step.SubmitRequest }
    ]

    function navItemClickHandler(event: React.MouseEvent<HTMLAnchorElement>, step: Step) {
      if (completedSteps.has(step)) {
        event.preventDefault();
        setCurrentStep(step);
      } else {
        return
      }
    }

    return (
      <div className="card-header">
        {steps.map((stepInfo, index) => (
          <div className={`${currentStep === index ? "bg-white border rounded-2" : ""} ${index > 0 ? "mt-1" : ""}`} key={index}>
            <a className={`px-2 py-1 d-flex align-items-center justify-content-between text-decoration-none
                ${completedSteps.has(stepInfo.step) ? 'cursor-pointer' : ''}`}
              key={index}
              onClick={(e) => navItemClickHandler(e, stepInfo.step)}
            >
              <div className="d-flex align-items-center">
                <i className={`${stepInfo.icon} h4 mb-0 me-2 ${currentStep === index ? "text-primary" : completedSteps.has(stepInfo.step) ? "text-gray-900" : "text-gray-600"}`} />
                <div className={`fs-md ${currentStep === index || completedSteps.has(stepInfo.step) ? "text-dark" : "text-gray-700"} text-nowrap`}>
                  {stepInfo.title}
                </div>
              </div>
              {htmlIf(stepInfo.number !== 6, // Don't show the checkmark on the Review step
                <div className="avatar avatar-xs me-2">
                  <div className={`avatar-title mb-0 ${completedSteps.has(stepInfo.step) ? "bg-primary" : "bg-gray-300"} rounded-circle text-white h6`}>
                    <i className="ai-check" />
                  </div>
                </div>
              )}
            </a>
          </div>
        ))}
      </div>
    )
  }

  const ViewEnterTagsPage = () => {
    function handleNextClicked() {
      if (selectedTagItems.length > 0) {
        setCurrentStep(Step.EnterDescription);
        setCompletedSteps(completedSteps.add(Step.EnterTags));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 1 of 6</div>
            <div className="h2 mb-0 text-dark">Let’s get started</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-0">What are some key skills required for your work?</h6>
            <div className="fs-md mb-1">For best results, add 3-5 skills.</div>
            <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2 t--select-tags',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.map((tag) => (tagToReactSelectItem(tag)))}
              onChange={(newTags: ReactSelectOption[]) => setSelectedTagItems(newTags)}
              value={selectedTagItems}
              placeholder="Search or add up to ten skills…"
            />
            {htmlIf(showErrors && !(selectedTagItems.length > 0),
              <div className="fs-sm mt-1 text-danger fw-bold">Please select at least one skill.</div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <a href={agentDashboardPath} className="btn btn-outline-secondary">Exit</a>
            <button className="btn btn-primary px-6 ms-2 t--step-1-next t--enter-tags-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterDescriptionPage = () => {
    function handleNextClicked() {
      if (requestDescription.length > 0) {
        setCurrentStep(Step.EnterTitle);
        setCompletedSteps(completedSteps.add(Step.EnterDescription));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 2 of 6</div>
            <div className="h2 mb-0 text-dark">Start the conversation</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-0">What are you looking to accomplish?</h6>
            <div className="fs-md">Be specific and add details — this will help get your project to the right CoPilot!</div>
            <textarea
              className="form-control mt-1 t--request-description"
              rows={5}
              placeholder="Explain the scope of this request…"
              value={requestDescription}
              onChange={(event) => setRequestDescription(event.target.value)}
            />
            {htmlIf(showErrors && !(requestDescription.length > 0),
              <div className="fs-sm mt-1 text-danger fw-bold">Please enter a description.</div>
            )}
            <div className="mt-3 fs-sm">
              <div className="fw-semibold">A good description includes:</div>
              <ul className="mb-0">
                  <li>Clear expectations about your job and the deliverables</li>
                  <li>The skills required</li>
                  <li>Explanation of scope and timeline</li>
                </ul>
            </div>
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterTags)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-2-next t--enter-description-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterTitlePage = () => {
    function handleNextClicked() {
      if (requestTitle.length > 0) {
        setCurrentStep(Step.SelectCopilot);
        setCompletedSteps(completedSteps.add(Step.EnterTitle));
      } else {
        setShowErrors(true);
      }
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 3 of 6</div>
            <div className="h2 mb-0 text-dark">Add a title</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold mb-0">Summarize what you need in a few words.</h6>
            <div className="fs-md">Keep it short and simple — this is the first thing CoPilots will see!</div>
            <input
              className="form-control mt-1 t--request-title"
              placeholder="Enter job title…"
              value={requestTitle}
              onChange={(event) => setRequestTitle(event.target.value)}
            />
            {htmlIf(showErrors && !requestTitle.length,
              <div className="fs-sm mt-1 text-danger fw-bold">Please enter a title.</div>
            )}
            <div className="fs-sm fw-semibold mt-3 text-gray-700">Some title examples:</div>
            <div className="mt-1 fs-sm">
              <ul className="mb-0">
                <li>AXUS itinerary creation</li>
                <li>Need new client email templates</li>
                <li>Urgent supplier help</li>
              </ul>
            </div>
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterDescription)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-3-next t--enter-title-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewSelectCopilotPage = () => {
    function handleSelectCopilotClicked(event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot) {
      setSelectedCopilot(copilot);
      setIsAcceptingOffers(false);
      copilot.hourlyRateCents
      ?
        setHourlyRateCents(Math.round(
          convertUSDCentsToCurrency(props.displayCurrency, copilot.hourlyRateCents, props.exchangeRates) / 100
        ) * 100)
      : setHourlyRateCents(0)
    }

    function handleNextClicked() {
      setCurrentStep(Step.EnterBudget);
      setCompletedSteps(completedSteps.add(Step.SelectCopilot));
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 4 of 6</div>
            <div className="h2 mb-0 text-dark">Select a CoPilot</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            {/* <h6 className="fw-bold mb-1">Would you like to choose the CoPilot you work with?</h6> */}
            <div className="row">
              <div className="col-md-6">
                <div
                  className={"card h-100 rounded-2 cursor-pointer t--share-with-pool" + (shareRequestWithPool === true ? " border-primary" : "")}
                  onClick={() => {
                    setShareRequestWithPool(true);
                    setSelectedCopilot(null);
                  }}
                >
                  <div className="card-body px-2 py-3 d-flex align-items-center">
                    <i className={"ai-user-group lead mb-0 me-1 " + (shareRequestWithPool === true ? "text-primary" : "")} />
                    <div className={shareRequestWithPool === true ? "text-primary" : ""}>Share with all CoPilots</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2 mt-md-0">
                <div
                  className={"card rounded-2 cursor-pointer t--choose-my-own-copilot" + (shareRequestWithPool === false ? " border-primary" : "")}
                  onClick={() => {
                    setShareRequestWithPool(false);
                    setSelectedCopilot(null);
                  }}
                >
                  <div className="card-body px-2 py-3 d-flex align-items-center">
                    <i className={"ai-user lead mb-0 me-1 " + (shareRequestWithPool === false ? "text-primary" : "")} />
                    <div className={shareRequestWithPool === false ? "text-primary" : ""}>Request a specific CoPilot</div>
                  </div>
                </div>
              </div>
            </div>
            {htmlIf(shareRequestWithPool === false && !selectedCopilot,
              <CopilotSearchSort copilots={props.copilots}
                displayCurrency={props.displayCurrency} exchangeRates={props.exchangeRates}
                featuredTags={selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)))}
                allowedTags={props.allTags}
                cardButtons={
                  [
                    {
                      btnClass: "btn btn-outline-primary t--select-copilot w-100 w-md-auto",
                      btnLabel: "Select CoPilot",
                      onClick: handleSelectCopilotClicked
                    },
                    { btnClass: "btn btn-link t--view-copilot-profile w-100 w-md-auto",
                      btnLabel: "View Profile",
                      onClick: emptyClickHandler,
                      isViewProfileModalButton: true
                    }
                  ]
                }
              />
            )}
            {htmlIf(selectedCopilot,
              <div className="mt-3">
                <div className="fs-md fw-semibold text-gray-900 mb-1">Selected CoPilot</div>
                <CopilotCard copilot={selectedCopilot}
                  displayCurrency={props.displayCurrency} exchangeRates={props.exchangeRates}
                  featuredTags={selectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)))}
                  allowedTags={props.allTags}
                  cardButtons={
                    [
                      {
                        btnClass: "btn btn-link text-dark ai-cross fs-xxl t--change-selected-copilot",
                        btnLabel: "",
                        onClick: () => setSelectedCopilot(null)
                      }
                    ]
                  }
                />
              </div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.EnterTitle)} className="btn btn-outline-secondary">
              Back
            </button>
            {htmlIf(shareRequestWithPool || selectedCopilot,
              <button className="btn btn-primary px-6 ms-2 t--step-4-next t--select-copilot-next"
                onClick={handleNextClicked}
              >Next</button>
            )}
          </div>
        </div>
      </div>
    )
  }

  const ViewEnterBudgetPage = () => {
    function handleNextClicked() {
      if (requestType === null || requestType === undefined) { setShowErrors(true) }
      else {
        switch (requestType) {
          case RequestType.HOURLY_CONTRACT:
            if ( hourlyRateCents > 0 || isAcceptingOffers) {
              setCurrentStep(Step.SubmitRequest);
              setCompletedSteps(completedSteps.add(Step.EnterBudget));
            }
            else {
              setShowErrors(true);
            }
          case RequestType.PROJECT_REQUEST:
            if ( subtotalCents > 0 || isAcceptingOffers) {
              setCurrentStep(Step.SubmitRequest);
              setCompletedSteps(completedSteps.add(Step.EnterBudget));
            }
            else {
              setShowErrors(true);
            }
        }
      }
    }

    const handleSubtotalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSubtotalCents(parseInt(event.target.value) * 100);
    };

    const handleHourlyRateCentsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setHourlyRateCents(parseInt(event.target.value) * 100);
    }

    return (
      <div className="card">
        <div className="">
          <div className="card-header border-0">
            <div className="fs-md">Step 5 of 6</div>
            <div className="h2 mb-0 text-dark">Budget</div>
          </div>
          <div className="card-body py-3 border-top border-bottom">
            <h6 className="fw-bold">How would you like to structure your job?</h6>
            <div className="row">
              <div className="col-md-6">
                <div
                  className={"card h-100 rounded-2 cursor-pointer t--hourly-card " + (requestType === RequestType.HOURLY_CONTRACT ? " border-primary" : "")}
                  onClick={() => setRequestType(RequestType.HOURLY_CONTRACT)}
                >
                  <div className="card-body p-3">
                    <i className={"ai-clock h3 mb-1 " + (requestType === RequestType.HOURLY_CONTRACT ? "text-primary fw-bold" : "")} />
                    <h5 className={"mb-1 " + (requestType === RequestType.HOURLY_CONTRACT ? "text-primary" : "")}>Pay by the hour</h5>
                    <div className="fs-sm">Hourly rates are great for long-term work or if you’re unsure how long a task will take.</div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 mt-2 mt-md-0">
                <div
                  className={"card h-100 rounded-2 cursor-pointer t--project-card " + (requestType === RequestType.PROJECT_REQUEST ? " border-primary" : "")}
                  onClick={() => setRequestType(RequestType.PROJECT_REQUEST)}
                >
                  <div className="card-body p-3">
                    <i className={"ai-tag h3 mb-1 " + (requestType === RequestType.PROJECT_REQUEST ? "text-primary" : "")} />
                    <h5 className={"mb-1 " + (requestType === RequestType.PROJECT_REQUEST ? "text-primary" : "")}>Pay a fixed price</h5>
                    <div className="fs-sm">Fixed rates are ideal for clear and defined tasks or shorter projects.</div>
                  </div>
                </div>
              </div>
            </div>
            {htmlIf(showErrors && (requestType === null || requestType === undefined),
              <div className="mt-1 text-danger fw-bold">Please select a job type before continuing.
              </div>
            )}
            {htmlIf(requestType !== null && requestType !== undefined && shareRequestWithPool,
              <div className="mt-3">
                <h6 className="fw-bold mb-1">Do you know how to price your job?</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      className={"card rounded-2 cursor-pointer t--set-my-own-price" + (isAcceptingOffers === false ? " border-primary" : "")}
                      onClick={() => {
                        setIsAcceptingOffers(false);
                        setSubtotalCents(0);
                      }}
                    >
                      <div className="card-body px-2 py-3 d-flex align-items-center">
                        <i className={"ai-dollar lead mb-0 me-1 " + (isAcceptingOffers === false ? "text-primary" : "")} />
                        <div className={isAcceptingOffers === false ? "text-primary" : ""}>Yes, I’ll set a price</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mt-2 mt-md-0">
                    <div
                      className={"card h-100 rounded-2 cursor-pointer t--accept-offers" + (isAcceptingOffers === true ? " border-primary" : "")}
                      onClick={() => {
                        setIsAcceptingOffers(true);
                        if (subtotalCents === projectRequestOfferAmountCents) {
                          setSubtotalCents(0);
                        } else {
                          setSubtotalCents(projectRequestOfferAmountCents);
                        }
                        setShowErrors(false);
                      }}
                    >
                      <div className="card-body px-2 py-3 d-flex align-items-center">
                        <i className={"ai-mail lead mb-0 me-1 " + (isAcceptingOffers === true ? "text-primary" : "")} />
                        <div className={isAcceptingOffers === true ? "text-primary" : ""}>No, I want to receive offers from CoPilots</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {htmlIf(requestType === RequestType.PROJECT_REQUEST && isAcceptingOffers === false,
              <div className="mt-3">
                <h6 className="fw-bold">Request Amount</h6>
                <div className="input-group align-items-center p-0">
                  <div className="input-group-prepend ms-2">{currencySymbol(props.displayCurrency)}</div>
                  <input
                    className="form-control t--request-subtotal" type="number"
                    value={subtotalCents / 100}
                    min={0} max={5000}
                    onChange={handleSubtotalChange}
                  />
                </div>
                {htmlIf(showErrors && !(subtotalCents > 0),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                )}
              </div>
            )}
            {htmlIf(requestType === RequestType.HOURLY_CONTRACT && isAcceptingOffers === false,
              <div className="mt-3">
                <h6 className="fw-bold">Hourly Rate</h6>
                <div className="input-group align-items-center p-0">
                  <div className="input-group-prepend ms-2">{currencySymbol(props.displayCurrency)}</div>
                  <input
                    className="form-control t--hourly-rate" type="number"
                    value={hourlyRateCents / 100}
                    min={0} max={5000}
                    onChange={handleHourlyRateCentsChange}
                  />
                </div>
                {htmlIf(showErrors && !(hourlyRateCents > 0),
                  <div className="fs-sm mt-1 text-danger fw-bold">Please enter a number greater than 0.</div>
                )}
              </div>
            )}
          </div>
          <div className="card-footer d-flex border-0">
            <button onClick={() => setCurrentStep(Step.SelectCopilot)} className="btn btn-outline-secondary">
              Back
            </button>
            <button className="btn btn-primary px-6 ms-2 t--step-5-next t--enter-budget-next" onClick={handleNextClicked}>Next</button>
          </div>
        </div>
      </div>
    )
  }

  function ViewSubmitRequestPage() {
    function handleNewPaymentMethodAdded() {
      API.post("agent_fetch_payment_methods_path").then(function (result) {
        setAvailablePaymentMethods(result['paymentMethods']);
        setShowAddPaymentModal(false);
      })
    }

    function handleSubmitRequestClicked() {
      if (!props.isSubscriptionActive && (subscriptionUpgradeOption === null || subscriptionUpgradeOption === undefined)) {
        setShowErrors(true);
        subscriptionUpgradeRef.current.scrollIntoView();
      } else {
        if ([MembershipOption.Monthly, MembershipOption.Annual].includes(subscriptionUpgradeOption)) {
          upgradeSubscriptionAndSubmitRequest();
        } else {
          submitRequest();
        }
      }
    }

    const ViewAddPaymentMethodModal = () => {
      return (
        <DismissibleModal
          title={
            <h4>Add a new payment method</h4>
          }
          body={
            <div>
              Clicking below will open up your Billing settings page in a new tab, where you’ll be able to add
              a new payment method via Stripe. After adding your new payment method, return to this tab and select
              "Done."
            </div>
          }
          footer={
            <div className="text-start">
              <a className="btn btn-primary w-100 w-sm-auto mb-2 mb-sm-0"
                href={agentAccountBillingPath} target="_blank"
              >
                Add new payment method
              </a>
              <button className="btn btn-outline-success w-100 w-sm-auto ms-sm-2"
                onClick={handleNewPaymentMethodAdded}
              >
                Done
              </button>
            </div>
          }
          onDismiss={() => setShowAddPaymentModal(false)}
        />
      )
    }

    const ViewSubscriptionUpgradeOptions = () => {
      const greenCheckItem = (bold: boolean, text: string) => (
        <div className="col-md-6">
          <div className="mt-1 d-flex align-items-center">
            <i className="ai-check fs-xl text-success me-1" />
            <div className={`${bold ? 'fw-semibold' : ''} text-gray-900`}>{text}</div>
          </div>
        </div>
      )

      return (
        <>
          <div className={
            `bg-tan p-2 rounded-1 ${
              showErrors && (subscriptionUpgradeOption === null || subscriptionUpgradeOption === undefined)
              ? 'border border-danger' : ''
            }`} ref={subscriptionUpgradeRef}
          >
            <div className="fs-md fw-semibold text-gray-900">
              Save on your service fee with a Pro membership
            </div>
            <div className="card rounded-1 p-2 bg-tan cursor-pointer mt-2 t--subscription-monthly"
              onClick={() => setSubscriptionUpgradeOption(MembershipOption.Monthly)}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-md fw-semibold text-gray-900">Monthly</div>
                <input className="form-check-input" type="radio" checked={subscriptionUpgradeOption === MembershipOption.Monthly}></input>
              </div>
              <div className="mt-1">{formatUSDWithoutCents(props.monthlySubscriptionPriceCents)}/month</div>
              <div className="row">
                {greenCheckItem(true, '50% off service fees')}
                {greenCheckItem(false, 'Live support')}
                {greenCheckItem(false, 'Dedicated account manager')}
                {greenCheckItem(false, 'White glove onboarding')}
                {greenCheckItem(false, 'Exclusive features & invitations')}
              </div>
            </div>
            <div className="card rounded-1 p-2 bg-tan cursor-pointer mt-2 t--subscription-annual"
              onClick={() => setSubscriptionUpgradeOption(MembershipOption.Annual)}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-md fw-semibold text-gray-900">Annual</div>
                <input className="form-check-input" type="radio" checked={subscriptionUpgradeOption === MembershipOption.Annual}></input>
              </div>
              <div className="mt-1">{formatUSDWithoutCents(props.annualSubscriptionPriceCents / 12)}/month (billed annually)</div>
              <div className="row">
                {greenCheckItem(true, '50% off service fees')}
                {greenCheckItem(false, 'Live support')}
                {greenCheckItem(false, 'Dedicated account manager')}
                {greenCheckItem(false, 'White glove onboarding')}
                {greenCheckItem(false, 'Exclusive features & invitations')}
              </div>
            </div>
            <div className="card rounded-1 p-2 bg-tan cursor-pointer mt-2 t--subscription-free"
              onClick={() => setSubscriptionUpgradeOption(MembershipOption.Free)}
            >
              <div className="d-flex align-items-center justify-content-between">
                <div className="fs-md fw-semibold text-gray-900">No thanks, I’ll skip it for now</div>
                <input className="form-check-input" type="radio" checked={subscriptionUpgradeOption === MembershipOption.Free}></input>
              </div>
            </div>
          </div>
          {htmlIf(showErrors && (subscriptionUpgradeOption === null || subscriptionUpgradeOption === undefined),
            <div className="fs-sm mt-1 text-danger fw-bold">Please select a membership type to proceed.</div>
          )}
        </>
      )
    }

    const ViewCopilot = () => (
      <>
        <div className="fs-md fw-semibold text-gray-900">CoPilot</div>
        <div className="d-flex align-items-center mt-1">
          <div className="avatar avatar-xs">
            <img className="avatar-img rounded-circle" src={selectedCopilot?.imageUrl}/>
          </div>
          <div className="fs-md ms-1">{selectedCopilot?.preferredName}</div>
        </div>
      </>
    )

    const ViewDisclaimers = () => (
      <>
        {htmlIf(requestType === RequestType.HOURLY_CONTRACT,
          <div className="mt-2">
            At the end of each week, you will be notified once your CoPilot logs the number of hours they worked.
            The CoPilot will include a description of the tasks performed during those hours.
            Your card will be automatically billed for the hours your CoPilot works (along with the Lucia service fee).
          </div>
        )}
        {htmlIf(props.displayCurrency !== 'USD',
          <>
            <div className="mt-2">
              Note: while prices are displayed in your preferred currency, all transactions on Lucia are processed
              in USD using the latest exchange rates.
            </div>
            {htmlIf(isAcceptingOffers === false,
              <div className="mt-1">
                {{
                  [RequestType.HOURLY_CONTRACT]:
                    `You will be billed at a rate of approximately ${
                      formatUSDWithCents(
                        convertToUSDCents(props.displayCurrency, hourlyRateCents, props.exchangeRates)
                      )
                    } USD/hour (plus the Lucia service fee).`,
                  [RequestType.PROJECT_REQUEST]:
                    `You will be charged a total of approximately ${
                      formatUSDWithCents(
                        convertToUSDCents(props.displayCurrency, totalAmountCents(), props.exchangeRates)
                      )
                    } USD for this job.`
                }[requestType]}
              </div>
            )}
          </>
        )}
      </>
    )

    return (
      <div>
        { htmlIf(showAddPaymentMethodModal, <ViewAddPaymentMethodModal />)}
        <div className="card">
          <div className="">
            <div className="card-header border-0">
              <div className="fs-md">Step 6 of 6</div>
              <div className="h2 mb-0 text-dark">Submit your request</div>
            </div>
            <div className="card-body py-3 border-top border-bottom">
              <div className="">
                <div className="fs-md fw-semibold text-gray-900">Title</div>
                <div className="fs-md">{requestTitle}</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Description</div>
                <div className="fs-md">{formatMultiParagraphString(requestDescription)}</div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Skills</div>
                <div className="fs-md">
                  {selectedTagItems.map((tagItem) => (
                    <div key={`Tag-${tagItem.value}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tagItem.label}</div>
                  ))}
                </div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Job Type</div>
                <div className="fs-md">
                  {{
                    [RequestType.HOURLY_CONTRACT]: "Hourly rate",
                    [RequestType.PROJECT_REQUEST]: "Fixed rate"
                  }[requestType]}
                </div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">
                  {{
                    [RequestType.HOURLY_CONTRACT]: "Rate",
                    [RequestType.PROJECT_REQUEST]: "Subtotal"
                  }[requestType]}
                </div>
                <div className="fs-md">
                  {{
                    [RequestType.HOURLY_CONTRACT]:
                      isAcceptingOffers ? "TBD – Accepting offers" : formatCurrencyWithCents(props.displayCurrency, hourlyRateCents),
                    [RequestType.PROJECT_REQUEST]:
                      isAcceptingOffers ? "TBD – Accepting offers" : formatCurrencyWithCents(props.displayCurrency, subtotalCents)
                  }[requestType]}
                </div>
              </div>
              <div className="mt-2">
                <div className="fs-md fw-semibold text-gray-900">Service Fee</div>
                <div className="fs-md">
                  { props.isSubscriptionActive
                  ? <div className="d-flex align-items-center">
                      <div className="text-decoration-line-through text-muted">{props.platformFeePercent * 2}%</div>
                      <div className="ms-1">{props.platformFeePercent}%</div>
                      <div className="ms-2 badge bg-faded-success text-success d-flex align-items-center">
                        <i className="ai-star fs-lg me-1" />
                        You’re saving with a Lucia membership!
                      </div>
                    </div>
                  : <>
                    { [MembershipOption.Monthly, MembershipOption.Annual].includes(subscriptionUpgradeOption)
                    ? <div className="d-flex">
                        <div className="text-decoration-line-through">{props.platformFeePercent}%</div>
                        <div className="ms-1 fw-bold">{props.platformFeePercent / 2}%</div>
                        <div className="ms-2 badge bg-faded-success text-success">Great deal!</div>
                      </div>
                    : `${props.platformFeePercent}%`
                    }
                    </>
                  }
                </div>
              </div>
              {htmlIf(!props.isSubscriptionActive,
                <div className="mt-2">
                  {ViewSubscriptionUpgradeOptions()}
                </div>
              )}
              {htmlIf((selectedCopilot !== null && shareRequestWithPool === false),
                <div className="mt-2">
                  {ViewCopilot()}
                </div>
              )}
              <div className="mt-2 fs-sm text-secondary">
                {ViewDisclaimers()}
              </div>
              <div className="mt-2 fs-sm">
                By placing a request, you agree to the <a href={termsAndConditionsPath} target="_blank">Terms of Service</a>.
              </div>
            </div>
            <div className="card-body py-3">
              <h4 className="fw-bold">Payment</h4>
              <div className="card rounded-2">
                <div className="card-header p-2 mx-0 w-100 text-dark fw-bold">
                  Saved payment options
                </div>
                <div className="card-body p-2">
                  {availablePaymentMethods.map((paymentMethod, index) => (
                    <div className={`form-check ${index === availablePaymentMethods.length - 1 ? 'mb-0' : 'mb-1'}`} key={paymentMethod.id}>
                      <input
                        className="form-check-input"
                        type="radio"
                        id={paymentMethod.id}
                        name="pm-radio"
                        value={paymentMethod.id}
                        onChange={(e) => setSelectedPaymentMethodId(e.target.value)}
                        checked={selectedPaymentMethodId === paymentMethod.id}
                        />
                      <label className="form-check-label" htmlFor={paymentMethod.id}>
                        <b>{formatCardBrand(paymentMethod.card.brand)} •••• {paymentMethod.card.last4}</b>
                        <br />
                        expiring {paymentMethod.card.expiration}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="card rounded-2 mt-3">
                <div className="cursor-pointer d-flex card-body p-2 justify-content-between align-items-center fs-md text-dark fw-bold"
                  onClick={() => setShowAddPaymentModal(true)}
                >
                  <div>Add credit/debit card</div>
                  <i className="ai-chevron-right" />
                </div>
              </div>
              {/*
                We only show the gift card entry to users who have their currency set to USD
                (we don't support currency conversion for gift cards). We also don't show it for Hourly requests.
              */}
              {htmlIf(props.displayCurrency === 'USD' && requestType === RequestType.PROJECT_REQUEST,
                <GiftCardEntry creditCodeCodeSetter={setCreditCodeCode} />
              )}
              {htmlIf(requestType === RequestType.PROJECT_REQUEST,
                <div className="mt-3 fs-sm">
                  { isAcceptingOffers
                  ? "A hold will be placed on your selected payment method for $0.50 and you will only be charged for your job after you select a CoPilot offer."
                  : "A hold will be placed on your selected payment method for the total amount of the job. Lucia will process payment for your job as soon as it is claimed by your CoPilot."
                  }
                </div>
              )}
              {htmlIf([MembershipOption.Monthly, MembershipOption.Annual].includes(subscriptionUpgradeOption),
                <div className="mt-2 fs-sm">
                  After clicking submit, you request will be submitted and the card you selected above
                  will be charged for your new membership.
                </div>
              )}
              <div className="mt-3">
                <button
                  onClick={() => setCurrentStep(Step.EnterBudget)}
                  className="btn btn-outline-secondary"
                  disabled={isSubmittingRequest}
                >
                  Back
                </button>
                <button
                  className="btn btn-primary px-md-4 ms-1 ms-md-2 t--submit-request"
                  onClick={handleSubmitRequestClicked}
                  disabled={isSubmittingRequest}
                >
                  {[MembershipOption.Monthly, MembershipOption.Annual].includes(subscriptionUpgradeOption)
                  ? "Submit Request + Start Subscription"
                  : "Submit Request"
                  }
                </button>
              </div>
              {htmlIf(submitRequestError !== null,
                <div className="mt-2 text-danger fw-bold">{submitRequestError}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="row">
    <div className="col-lg-3 d-none d-lg-block">
      <ViewProgressNav />
    </div>
    <div className="col-12 col-lg-9">
    {(() => {
        switch (currentStep) {
          case Step.EnterTitle:
            return ViewEnterTitlePage();
          case Step.EnterDescription:
            return ViewEnterDescriptionPage();
          case Step.EnterTags:
            return ViewEnterTagsPage();
          case Step.SelectCopilot:
            return ViewSelectCopilotPage();
          case Step.EnterBudget:
            return ViewEnterBudgetPage();
          case Step.SubmitRequest:
            return ViewSubmitRequestPage();
          default:
            return null;
        }
      })() as React.ReactNode}
    </div>
  </div>
  );
}

export default NewRequest;
