import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../Utils/API.js';
import { PaymentMethod, formatCardBrand, formatCardFunding } from '../Types/PaymentMethod';
import { DismissibleModal } from '../Components/Modal';

type Props =
  { paymentMethods: PaymentMethod[]
  }

const PaymentMethods = (props: Props) => {
  const [paymentMethods, setPaymentMethods] = useState(props.paymentMethods);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paymentMethodToDelete, setPaymentMethodToDelete] = useState<PaymentMethod>();

  function addNewPaymentMethod() {
    API.post("agent_account_add_new_payment_method_path").then(function (result) {
      window.location.href = result['url']
    })
  }

  function deletePaymentMethod(paymentMethod: PaymentMethod) {
    const postBody = { paymentMethodId: paymentMethod.id }

    API.post("agent_account_delete_payment_method_path", postBody).then(function (result) {
      setPaymentMethods(result['paymentMethods']);
      setShowDeleteModal(false);
      setPaymentMethodToDelete(null);
    })
  }

  const ViewModal = () => {
    const deleteModal = (
      <DismissibleModal
        title={
          <h4>Delete this payment method?</h4>
        }
        body={
          <div>Would you like to remove this payment method from your account? This action cannot be undone.</div>
        }
        footer={
          <>
            <button
              onClick={() => setShowDeleteModal(false)}
              className="btn btn-outline-primary w-100 w-sm-auto mb-2 mb-sm-0"
            >
              No, keep it
            </button>
            <button
              onClick={() => deletePaymentMethod(paymentMethodToDelete)}
              className="btn btn-danger w-100 w-sm-auto ms-sm-2"
            >
              Yes, delete it
            </button>
          </>
        }
        onDismiss={() => setShowDeleteModal(false)}
      />
    )

    const lastRemainingPaymentMethod = (
      <DismissibleModal
        title={
          <h4>Hold up, there!</h4>
        }
        body={
          <div>This is your only payment method on file with Lucia. In order to delete it, you’ll need to add another payment method first.</div>
        }
        onDismiss={() => setShowDeleteModal(false)}
      />
    )

    return (
      paymentMethods.length > 1 ? deleteModal : lastRemainingPaymentMethod
    )
  }

  const ViewPaymentMethodCard = ({paymentMethod}: {paymentMethod: PaymentMethod}) => {
    return (
      <div className="col">
        <div className="card h-100 rounded-3 p-3 p-sm-4 justify-content-center">
          <div className="d-flex align-items-center justify-content-between">
            <div className="fs-sm">
              <div className="text-dark">{formatCardBrand(paymentMethod.card?.brand)} •••• {paymentMethod.card?.last4}</div>
              <div className="text-secondary">{formatCardFunding(paymentMethod.card?.funding)} – Expires {paymentMethod.card?.expiration}</div>
            </div>
            <button
                onClick={() => {
                  setPaymentMethodToDelete(paymentMethod);
                  setShowDeleteModal(true);
                }}
                className="nav-link text-danger fs-xl fw-normal py-1 pe-0 ps-1 ms-1"
              >
                <i className="ai-trash"/>
              </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      { showDeleteModal ? <ViewModal /> : null }
      <div className="row row-cols-1 row-cols-md-2 g-4">
        {paymentMethods.map((paymentMethod, index) => (
          <ViewPaymentMethodCard
            paymentMethod={paymentMethod}
            key={paymentMethod?.id}
          />
        ))}
        <div className="col">
          <div className="card h-100 justify-content-center align-items-center border-dashed rounded-3 py-5 px-2 px-sm-3">
            <div onClick={addNewPaymentMethod} className=" text-primary cursor-pointer stretched-link d-flex align-items-center fw-semibold text-decoration-none">
              <i className="ai-circle-plus fs-xl me-1"/>
              Add new payment method
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};


export default PaymentMethods;
