import { Copilot } from './Copilot';
import { CustomerRating } from './CustomerRating';
import { RequestStatus } from './ProjectRequest';
import { ContractStatus } from './Hourly';

export type RequestCard =
  { requestType: RequestType
  , requestId: number
  , title: string
  , description: string
  , status: RequestStatus | ContractStatus
  , copilot: Copilot
  , customerRating?: CustomerRating
  , customerName: string
  , isAgentVirtuoso: boolean
  , isAcceptingOffers: boolean
  // The copilotRateDescription is either the subtotal of the request (i.e. $100) or the hourly rate (i.e. $100/hour) and
  // is always formatted in USD, regardless of the user's selected currency.
  , copilotRateDescription: string
  , createdAt: string
  , updatedAt: string
  }

export enum RequestType
  { PROJECT_REQUEST = 'ProjectRequest'
  , HOURLY_CONTRACT = 'Hourly::Contract'
  }
