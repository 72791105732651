import * as React from 'react';
import { useEffect } from 'react';

/**
 * Base arguments for creating a modal.
 */
type BaseArgs = {
  headerClass?: string;
  body: React.ReactElement;
  bodyClass?: string;
  dialogClass?: string;
  footer?: React.ReactElement;
  footerClass?: string;
  contentClass?: string;
  scrollable?: boolean;
  centered?: boolean;
};

/**
 * Arguments for creating a modal that includes a header.
 * @interface ModalArgs
 * @extends BaseArgs
 * @property {React.ReactElement} [header] - The header content of the modal.
 */
type ModalArgs = {
  header?: React.ReactElement;
} & BaseArgs;

/**
 * Arguments for creating a modal that can be dismissed.
 * @interface DismissibleModalArgs
 * @extends BaseArgs
 * @property {React.ReactElement} [title] - The title of the modal.
 * @property {Function} onDismiss - The function to call when the modal is dismissed.
 * @property {string} [dismissButtonClass] - The class to apply to the dismiss button.
 */
type DismissibleModalArgs = {
  title?: React.ReactElement;
  onDismiss: () => void;
  dismissButtonClass?: string;
} & BaseArgs;

/**
 * Modal component that displays a modal dialog.
 *
 * @param args - Object containing the following optional properties:
 *   - header: React element to display as the modal header
 *   - headerClass: CSS class to add to the modal header
 *   - body: React element to display as the modal body
 *   - bodyClass: CSS class to add to the modal body
 *   - dialogClass: CSS class to add to the modal dialog
 *   - footer: React element to display as the modal footer
 *   - footerClass: CSS class to add to the modal footer
 *   - contentClass: CSS class to add to the modal content
 *   - scrollable: Whether to enable scrolling inside the modal body (default false)
 *   - centered: Whether to center the modal vertically and horizontally (default false)
 *
 * @returns A React element representing the modal
 */
export const Modal = <Args extends ModalArgs>(args: Args): React.ReactElement => {
  const header = args.header ?? null;
  const footer = args.footer ?? null;

  const dialogClass = [
    args.scrollable ? "modal-dialog-scrollable" : "",
    args.centered ? "modal-dialog-centered" : "",
    args.dialogClass ?? ""
  ].join(" ");

  return (
    <>
      <div className="modal fade show d-block" role="dialog" tabIndex={-1}>
        <div className={"modal-dialog " + dialogClass} role="document">
          <div className={"modal-content " + (args.contentClass ?? "")}>
            {header ? <div className={"modal-header " + (args.headerClass ?? "")}>{header}</div> : null}
            <div className={"modal-body " + (args.bodyClass ?? "")}>{args.body}</div>
            {footer ? <div className={"modal-footer " + (args.footerClass ?? "")}>{footer}</div> : null}
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" data-bs-dismiss="modal"></div>
    </>
  );
};

/**
 * Modal component that displays a modal dialog with only a body.
 *
 * @param bodyClass - CSS class to add to the modal body
 * @param body - React node to display as the modal body
 *
 * @returns A React element representing the modal with only a body
 */
export const BodyOnlyModal = <Body extends React.ReactNode>(
  bodyClass: string,
  body: Body
): React.ReactElement => {
  return Modal({ bodyClass, body: <>{body}</> });
};

/**
 * Modal component that displays a modal dialog with a dismiss button.
 *
 * @param args - Object containing the following required properties:
 *   - onDismiss: Function to call when the modal is dismissed
 *   - body: React element to display as the modal body
 *
 * @param args - And containing the following optional properties:
 *   - title: React element to display as the modal title
 *   - dismissButtonClass: CSS class to add to the dismiss button
 *   - headerClass: CSS class to add to the modal header
 *   - bodyClass: CSS class to add to the modal body
 *   - dialogClass: CSS class to add to the modal dialog
 *   - footer: React element to display as the modal footer
 *   - footerClass: CSS class to add to the modal footer
 *   - contentClass: CSS class to add to the modal content
 *   - scrollable: Whether to enable scrolling inside the modal body (default false)
 *   - centered: Whether to center the modal vertically and horizontally (default false)
 *
 * @returns A React element representing the dismissible modal
 */
export const DismissibleModal = <Args extends DismissibleModalArgs>(args: Args): React.ReactElement => {
  const onEscapePress = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      args.onDismiss();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', onEscapePress);
    return () => {
      document.removeEventListener('keyup', onEscapePress);
    };
  }, []);

  const body = (
    <>
      <div className="d-flex justify-content-between">
        <div className="mr-3">{args.title ?? null}</div>
        <button
          className={"btn-close" + (args.dismissButtonClass ?? "")}
          type="button"
          onClick={args.onDismiss}
        ></button>
      </div>
      {args.body}
    </>
  );

  return (
    <>
      {Modal({ ...args, body: body })}
    </>
  );
};
