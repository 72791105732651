/**
  Replaces all elements in an array that satisfy the given predicate with a new value.
  @param predicate A function to test each element of the array. Returns `true` to replace the element.
  @param newValue The value to replace matching elements with.
  @param array The array to update.
  @returns A new array with the matching elements replaced with the new value.
 */
export function updateWhere<T>(predicate: (a: T) => boolean, newValue: T, array: Array<T>): Array<T> {
  return modifyWhere<T>(predicate, () => newValue, array);
}

/**
  Modifies all elements of an array that match a given predicate using a provided function.
  @param predicate A function that tests whether an element of the array should be modified.
  @param func A function that transforms an element that should be modified.
  @param array An array of elements to be modified.
  @returns A new array with modified elements.
*/
export function modifyWhere<T>(predicate: (a: T) => boolean, func: (a: T) => T, array: Array<T>): Array<T> {
  return array.map((item) => predicate(item) ? func(item) : item);
}
