export type BlogPost =
  { id: number
  , slug: string
  , title: string
  , seoDescription: string
  , coverImageUrl: string
  , createdAt: string
  , updatedAt: string
  , publishedAt: string
  , sections: BlogPostSection[]
  }

export type BlogPostSection =
  { id: number
  , position: number
  , contentType: BlogContentType
  , contentId: number
  , content: BlogContent
  }

export enum BlogContentType
  { TEXT = 'Blog::TextContent'
  , IMAGE = 'Blog::ImageContent'
  , QUOTE = 'Blog::QuoteContent'
  , CTA = 'Blog::CtaContent'
  }

export type BlogContent = BlogTextContent | BlogImageContent | BlogQuoteContent | BlogCtaContent

export type BlogTextContent =
  { id: number
  , text: string
  , htmlClass: string
  }

export type BlogImageContent =
  { id: number
  , imageUrl: string
  , htmlClass: string
  }

export type BlogQuoteContent =
  { id: number
  , quote: string
  , author: string
  }

export type BlogCtaContent =
  { id: number
  , containerClass: string
  , title: string
  , titleClass: string
  , body: string
  , bodyClass: string
  , buttonText: string
  , buttonUrl: string
  , buttonClass: string
  }
