import * as React from 'react';
import { useState, useRef } from 'react';
import * as API from '../Utils/API.js';
import * as Currency from '../Utils/Currency'
import * as Language from '../Utils/Language';
import * as Location from '../Utils/Location';
import Select from 'react-select';
import { AgentInternal } from '../Types/Agent';
import { Tag, TagType, tagToReactSelectItem, reactSelectItemToTag } from '../Types/Tag';

type Props =
  { agent: AgentInternal
  , allTags: Tag[]
  , submitPath: string
  , authToken: string
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

enum Page
  { BasicInfo
  , Tags
  }

const AgentProfileWizard = (props: Props) => {
  const [currentPage, setCurrentPage] = useState(Page.BasicInfo);

  const [businessName, setBusinessName] = useState(props.agent.businessName);
  const [selectedCountry, setSelectedCountry] = useState(Location.countryAsSelectOption(props.agent.user.country));
  const [selectedLanguages, setSelectedLanguages] = useState(props.agent.languageCodes.map((code) => Language.asSelectOption(code)));
  const [selectedCurrency, setSelectedCurrency] = useState(Currency.asSelectOption(props.agent.user.currency));

  const [technologyTags, setTechnologyTags] = useState(
    props.agent.tags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))
  );
  const [supportTags, setSupportTags] = useState(
    props.agent.tags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))
  );

  // Due to the way Wicked (our onboarding wizard gem) works, we need to submit values by POSTing them in a form (instead of
  // a typical API call). Note some important elements here:
  // props.submitPath - this is the submit path (wizard_path), from Rails
  // hidden field for `authenticity_token` set to `props.authToken` - this is the form_authenticity_token, from Rails
  // hidden input field for `_method` set to `PUT` — this processes the request as a PUT request, despite the form method being POST.
  // mapping array elements into individual hidden inputs, with the same name (structured as an array) — this is the only way to post arrays in a form.
  const SubmitForm = () => {
    const languageCodes = selectedLanguages.map((item) => item.value);
    const allSelectedTagItems = Array.from(new Set([...technologyTags, ...supportTags]));
    const tags = allSelectedTagItems.map((item) => reactSelectItemToTag(item, props.allTags));

    return (
      <form action={props.submitPath} method="POST">
        <button className="btn btn-primary t--save-profile-wizard" type="submit">Save</button>
        <input type="hidden" name="authenticity_token" value={props.authToken} />
        <input type="hidden" name="_method" value="PUT" />
        <input type="hidden" name="business_name" value={businessName} />
        <input type="hidden" name="country" value={selectedCountry.value} />
        <input type="hidden" name="currency" value={selectedCurrency.value} />
        {languageCodes.map((code) => (
          <input type="hidden" name="languageCodes[]" value={code} />
        ))}
        {tags.map((tag) => (
          <input type="hidden" name="tagIds[]" value={tag.id} />
        ))}
      </form>
    )
  }

  const ViewBasicInfoPage = () => {
    return (
      <div>
        <h2 className="text-center">Let’s start filling out your profile</h2>
        <div className="row mt-3 justify-content-center">
          <div className="align-items-center">
            <div className="fs-md fw-bold text-dark">Business Name</div>
            <input
              className="form-control mt-1 t--business-name"
              placeholder="Enter the name of your business…"
              value={businessName}
              onChange={(event) => setBusinessName(event.target.value)}
            />
            <div className="mt-3 fs-md fw-bold text-dark">Country</div>
            <Select
              className="rounded mt-1 t--country-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={Location.allCountriesAsSelectOptions()}
              onChange={(newCountry: ReactSelectOption) => setSelectedCountry(newCountry)}
              value={selectedCountry}
              placeholder="Select the country you are based in…"
            />
            <div className="mt-3 fs-md fw-bold text-dark">Currency</div>
            <Select
              className="rounded mt-1 t--currency-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={Currency.allAsSelectOptions()}
              onChange={(newCurrency: ReactSelectOption) => setSelectedCurrency(newCurrency)}
              value={selectedCurrency}
              placeholder="Select your preferred currency…"
            />
            {/* <div className="mt-3 fs-md fw-bold text-dark">Time zone</div>
            Time zone */}
            <div className="mt-3 fs-md fw-bold text-dark">Language(s)</div>
            <Select
              className="rounded mt-1 t--language-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={Language.allAsSelectOptions()}
              onChange={(newLanguages: ReactSelectOption[]) => setSelectedLanguages(newLanguages)}
              value={selectedLanguages}
              placeholder="Select any languages you speak fluently…"
            />
          </div>
          <div className="text-center">
            <button className="mt-3 btn btn-primary justify-self-center t--page-1-continue" onClick={() => setCurrentPage(Page.Tags)}>Continue</button>
          </div>
        </div>
      </div>
    )
  }

  const ViewTagsPage = () => {
    return (
      <div>
        <h2 className="text-center">Let’s get to know you better</h2>
        <div className="row mt-3 justify-content-center">
          <div className="align-items-center">
            <div className="d-flex align-items-center">
              <div className="fs-md fw-bold text-dark">What technologies do you currently use?</div>
              <span className="ms-1 fs-md text-secondary">(Please select all that apply)</span>
            </div>
            <Select
              className="rounded mt-1 t--technology-tag-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newTechnologyTags: ReactSelectOption[]) => setTechnologyTags(newTechnologyTags)}
              value={technologyTags}
              placeholder="Select from the dropdown…"
            />
            <div className="mt-3 d-flex align-items-center">
              <div className="fs-md fw-bold text-dark">What type of support are you looking for?</div>
              <span className="ms-1 fs-md text-secondary">(Please select all that apply)</span>
            </div>
            <Select
              className="rounded mt-1 t--support-tag-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newSupportTags: ReactSelectOption[]) => setSupportTags(newSupportTags)}
              value={supportTags}
              placeholder="Select from the dropdown…"
            />
          </div>
          <div className="text-center mt-3">
            {SubmitForm()}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="mx-6">
      <div className="card-body">
        {(() => {
          switch (currentPage) {
            case Page.BasicInfo:
              return ViewBasicInfoPage();
            case Page.Tags:
              return ViewTagsPage();
            default:
              return null;
          }
        })() as React.ReactNode}
      </div>
    </div>
  )
}

export default AgentProfileWizard;
