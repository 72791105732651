import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Agent, AgentInternal } from '../Types/Agent';

type Props =
  { agents: AgentInternal[]
  }

function agentDetailsPath(agent) {
  return `/admin/agent/${agent.id}`
}

const AgentList = (props: Props) => {
  enum SortOptions
    { NameAsc
    , NameDesc
    , CreatedAsc
    , CreatedDesc
    }

  const allSortOptions = [
    SortOptions.NameAsc,
    SortOptions.NameDesc,
    SortOptions.CreatedAsc,
    SortOptions.CreatedDesc
  ]

  function sortOptionDisplayName(sortOption: SortOptions): string {
    switch (sortOption) {
      case SortOptions.NameAsc:
        return "Name (A–Z)"
      case SortOptions.NameDesc:
        return "Name (Z–A)"
      case SortOptions.CreatedAsc:
        return "Signup Time (Oldest to Newest)"
      case SortOptions.CreatedDesc:
        return "Signup Time (Newest to Oldest)"
    }
  }
  const sortAgents = (agents: AgentInternal[]): AgentInternal[] => {
    switch (sortOption) {
      case SortOptions.NameAsc:
        return agents.sort((a, b) => a.preferredName.localeCompare(b.preferredName));
      case SortOptions.NameDesc:
        return agents.sort((a, b) => b.preferredName.localeCompare(a.preferredName));
      case SortOptions.CreatedAsc:
        return agents.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
      case SortOptions.CreatedDesc:
        return agents.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
      default:
        return agents;
    }
  }

  const [sortOption, setSortOption] = useState(SortOptions.CreatedDesc);
  const [searchText, setSearchText] = useState('');
  const [filteredAgents, setFilteredAgents] = useState(props.agents);

  // This hook serves to re-sort and re-filter the list of Agents any time one of the following occurs:
  // 2) The sort option is changed
  // 3) The search text is changed
  useEffect(() => {
    const sortedAgents = sortAgents(props.agents);

    const filtered = sortedAgents.filter((agent) => {
      const searchFields = [
        `${agent.id}`,
        agent.preferredName,
        agent.user?.firstName,
        agent.user?.lastName,
        agent.user?.email
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredAgents(filtered);
  }, [sortOption, searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  const onSelectSortOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = parseInt(event.target.value) as SortOptions;
    setSortOption(selectedSortOption);
  }

  return (
    <>
      <h3>All Agents</h3>
      <div className="row mt-3 align-items-center">
        <div className="col-md-8">
          <div className="input-group align-items-center p-0 bg-white">
            <div className="input-group-prepend ms-2">
              <i className="ai-search"></i>
            </div>
            <input
              className="form-control"
              type="search"
              value={searchText}
              onChange={handleSearchInputChange}
              placeholder="Search by ID, name, or email…"
            />
          </div>
        </div>
        <div className="col-md-4 mt-2 mt-md-0 text-end">
          <select className="form-select bg-white" onChange={onSelectSortOption} value={sortOption}>
            {allSortOptions.map((option: SortOptions) => (
              <option key={option} value={option}>
                {sortOptionDisplayName(option)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-3 card rounded-1">
        <div className="card-body p-0">
          <div className="table-responsive">
            <table className="table mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {filteredAgents.length === 0 ? (
                  <tr>
                    <td className="py-6 text-center" colSpan={4}>
                      <h3 className="text-secondary mt-3">No Agents were found</h3>
                    </td>
                  </tr>
                ) : (
                  filteredAgents.map((agent) => (
                    <tr key={agent.id}>
                      <td className="text-nowrap py-1">{agent.id}</td>
                      <td className="text-nowrap py-1">{agent.user?.firstName} {agent.user?.lastName}</td>
                      <td className="text-nowrap py-1">{agent.user?.email}</td>
                      <td className="align-middle py-1">
                        <a className="btn btn-outline-primary btn-sm m-0 px-2 py-1" href={agentDetailsPath(agent)}>
                          View Agent
                        </a>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AgentList;
