import * as React from 'react';
import { useState, useEffect } from 'react';
import * as API from '../../Utils/API.js';
import { htmlIf } from '../../Utils/HTML';
import { DismissibleModal } from '../../Components/Modal';
import { getLocalTimeZoneName } from '../../Utils/DateTime';
import DatePicker from "react-datepicker";

type ScheduleMeetingModalProps =
  { schedulingUserId: number
  , invitedUserId: number
  , presetTopic?: string
  , isVisible: boolean
  , onClose: () => void;
  }

export const ScheduleMeetingModal = (props: ScheduleMeetingModalProps) => {
  const [topic, setTopic] = useState(props.presetTopic ?? '');
  const [selectedTime, setSelectedTime] = useState<Date>();
  const [durationMinutes, setDurationMinutes] = useState(60);

  const [showErrors, setShowErrors] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    setTopic(props.presetTopic ?? '')
  }, [props.isVisible])

  function scheduleMeeting() {
    setIsSaving(true);

    const body =
      { topic: topic
      , schedulingUserId: props.schedulingUserId
      , invitedUserId: props.invitedUserId
      , startTimeUtc: selectedTime.toISOString()
      , durationMinutes: durationMinutes
      }

    API.post("api_meetings_schedule_path", body).then(function (result) {
      if (result['error']) {
      } else {
        props.onClose();
      }
      setIsSaving(false);
    })
  }

  function handleScheduleClicked() {
    if (topic.length > 0 && selectedTime !== null && selectedTime !== undefined && durationMinutes > 0 && durationMinutes < 120) {
      scheduleMeeting();
    } else {
      setShowErrors(true);
    }
  }

  function handleCancelClicked() {
    props.onClose();
    setTopic(props.presetTopic ?? '');
    setSelectedTime(null);
    setDurationMinutes(60);
  }

  return (
    <div>
      {htmlIf(props.isVisible,
        <DismissibleModal
          dialogClass='modal-lg'
          title={<h4>Schedule a meeting</h4>}
          body={
            <div>
              <div className="fs-md text-gray-900 mb-1">Meeting Title</div>
              <input className="form-control"
                onChange={(e) => setTopic(e.target.value)}
                value={topic}
                placeholder='Enter meeting name…'
              />
              {htmlIf(showErrors && !(topic.length > 0),
                <div className="fs-sm mt-1 text-danger fw-bold">Please enter a meeting title.</div>
              )}
              <div className="fs-md text-gray-900 mt-2 mb-1">Meeting Date/Time</div>
              <div className="w-100">
                <DatePicker
                  wrapperClassName='w-100'
                  customInput={
                    <input className="form-control"/>
                  }
                  selected={selectedTime}
                  onChange={(date: Date) => setSelectedTime(date)}
                  showTimeSelect
                  dateFormat="MM/dd/yy @ h:mm a"
                  minDate={new Date()} // Prevents dates before today
                  placeholderText='Click to select a date and time…'
                />
              </div>
              <div className="fs-sm text-secondary">All times are shown in your local timezone ({getLocalTimeZoneName()})</div>
              {htmlIf(showErrors && !(selectedTime),
                <div className="fs-sm mt-1 text-danger fw-bold">Please select a date & time for your meeting.</div>
              )}
              <div className="fs-md text-gray-900 mt-2 mb-1">Meeting Length (in minutes)</div>
              <input className="form-control"
                onChange={(e) => setDurationMinutes(parseInt(e.target.value))}
                type='number'
                value={durationMinutes}
                placeholder='Enter meeting length…'
              />
              {htmlIf(showErrors && (durationMinutes >= 120 || !(durationMinutes > 0)),
                <div className="fs-sm mt-1 text-danger fw-bold">Please enter a meeting duration between 1-120 minutes.</div>
              )}
              <div className="mt-3 text-end">
                <button className="btn btn-outline-danger me-3" onClick={handleCancelClicked} disabled={isSaving}>Cancel</button>
                <button className="btn btn-info" onClick={handleScheduleClicked} disabled={isSaving}>
                  <i className="ai-video me-1"/>
                  Schedule Meeting
                </button>
              </div>
            </div>
          }
          onDismiss={props.onClose}
      />
      )}
    </div>
  )
}
