import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../Utils/API.js';
import { ButtonData, CopilotCard } from './CopilotCard';
import { Copilot } from '../../Types/Copilot';
import { Tag } from '../../Types/Tag';
import * as Language from '../../Utils/Language'
import { modifyWhere } from '../../Utils/Array';
import { CurrencyCode, ExchangeRate } from '../../Utils/Currency';

type Props =
  { copilots: Copilot[]
  , cardButtons: ButtonData[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  , featuredTags: Tag[]
  , allowedTags?: Tag[]
  }

// The CopilotSearchSort component is what we use to render a searchable and sortable list of copilots.
// We use this in both the CopilotList and NewRequest components, and, to enable customization of copilot
// card actions, one of the props of this component is an array of ButtonData, allowing us to set desired
// button actions from the parent component.
export const CopilotSearchSort = (props: Props) => {
  enum SortOptions
    { Featured
    , Rating
    , RecentlyActive
    , NameAsc
    , NameDesc
    , HourlyRateAsc
    , HourlyRateDesc
    }

  const allSortOptions = [
    SortOptions.Featured,
    SortOptions.RecentlyActive,
    SortOptions.Rating,
    SortOptions.NameAsc,
    SortOptions.NameDesc,
    SortOptions.HourlyRateAsc,
    SortOptions.HourlyRateDesc
  ]

  function sortOptionDisplayName(sortOption: SortOptions): string {
    switch (sortOption) {
      case SortOptions.Featured:
        return "Featured CoPilots"
      case SortOptions.Rating:
        return "Rating"
      case SortOptions.RecentlyActive:
        return "Recently Active"
      case SortOptions.NameAsc:
        return "Name (A–Z)"
      case SortOptions.NameDesc:
        return "Name (Z–A)"
      case SortOptions.HourlyRateAsc:
        return "Hourly Rate (Low to High)"
      case SortOptions.HourlyRateDesc:
        return "Hourly Rate (High to Low)"
    }
  }
  const sortCopilots = (copilots: Copilot[]): Copilot[] => {
    switch (sortOption) {
      case SortOptions.Featured:
        if (props.featuredTags.length > 0) {
          return copilots.sort((a, b) => {
            // Calculate the number of common tags
            const commonTagsA = a.tags.filter(copilotTag =>
              props.featuredTags.some(featuredTag => featuredTag.id === copilotTag.id)
            ).length;
            const commonTagsB = b.tags.filter(copilotTag =>
              props.featuredTags.some(featuredTag => featuredTag.id === copilotTag.id)
            ).length;

            // If the number of common tags is different, sort by it
            if (commonTagsA !== commonTagsB) {
              return commonTagsB - commonTagsA; // Sort in descending order of common tags
            } else {
              // If the number of common tags is the same, sort by sortScore
              return b.sortScore - a.sortScore; // Sort in descending order of sortScore
            }
          });
        } else {
          // If there are no featured tags, sort by sortScore
          return copilots.sort((a, b) => b.sortScore - a.sortScore);
        }
      case SortOptions.Rating:
        return copilots.sort((a, b) => (b.averageRating || 0) - (a.averageRating || 0));
      case SortOptions.RecentlyActive:
        return copilots.sort((a, b) => new Date(b.lastSeenAt).getTime() - new Date(a.lastSeenAt).getTime());
      case SortOptions.NameAsc:
        return copilots.sort((a, b) => a.preferredName.localeCompare(b.preferredName));
      case SortOptions.NameDesc:
        return copilots.sort((a, b) => b.preferredName.localeCompare(a.preferredName));
      case SortOptions.HourlyRateAsc:
        return copilots.sort((a, b) =>
          a.hourlyRateCents == null || a.hourlyRateCents === 0 ?
          (b.hourlyRateCents == null || b.hourlyRateCents === 0 ? 0 : 1) :
          (b.hourlyRateCents == null || b.hourlyRateCents === 0 ? -1 : a.hourlyRateCents - b.hourlyRateCents)
        );
      case SortOptions.HourlyRateDesc:
        return copilots.sort((a,b) => b.hourlyRateCents - a.hourlyRateCents);
      default:
        return copilots;
    }
  }

  const [sortOption, setSortOption] = useState(SortOptions.Featured);
  const [allCopilots, setAllCopilots] = useState(sortCopilots(props.copilots));
  const [searchText, setSearchText] = useState('');
  const [filteredCopilots, setFilteredCopilots] = useState(props.copilots);

  // This hook serves to re-sort and re-filter the list of copilots any time one of the following occurs:
  // 1) The underlying list of copilots changes (allCopilots)
  // 2) The sort option is changed
  // 3) The search text is changed
  useEffect(() => {
    const sortedCopilots = sortCopilots(allCopilots);

    const filtered = sortedCopilots.filter((copilot) => {
      const searchFields = [
        copilot.preferredName,
        copilot.bio,
        copilot.location,
      ];
      const tagNames = copilot.tags.map((tag) => tag.name);
      const languageNames = copilot.languageCodes.map((code) => Language.name(code));
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        ) ||
        tagNames.some((tagName) =>
          tagName?.toLowerCase().includes(searchText.toLowerCase())
        ) ||
        languageNames.some((language) =>
          language?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredCopilots(filtered);
  }, [allCopilots, sortOption, searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  const onSelectSortOption = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSortOption = parseInt(event.target.value) as SortOptions;
    setSortOption(selectedSortOption);
  }

  return (
    <div>
      <div className="row mt-3 align-items-center">
        <div className="col-md-8">
          <div className="input-group align-items-center p-0">
            <div className="input-group-prepend ms-2">
              <i className="ai-search"></i>
            </div>
            <input
              className="form-control"
              type="search"
              value={searchText}
              onChange={handleSearchInputChange}
              placeholder="Search by name, bio, or skills…"
            />
          </div>
        </div>
        <div className="col-md-4 mt-2 mt-md-0 text-end">
          <select className="form-select" onChange={onSelectSortOption} value={sortOption}>
            {allSortOptions.map((option: SortOptions) => (
              <option key={option} value={option}>
                {sortOptionDisplayName(option)}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-3">
      {filteredCopilots.map((copilot) =>
        <div key={copilot.id} className="mt-2">
          <CopilotCard
            copilot={copilot}
            cardButtons={props.cardButtons}
            featuredTags={props.featuredTags}
            allowedTags={props.allowedTags}
            displayCurrency={props.displayCurrency}
            exchangeRates={props.exchangeRates}
            key={copilot.id}
          />
        </div>
      )}
      </div>
    </div>
  );
}
