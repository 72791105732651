import * as React from 'react';
import { useState, useEffect, Dispatch } from 'react';
import * as API from '../../Utils/API.js';
import { RequestType } from '../../Types/RequestCard';
import { ProjectRequest } from '../../Types/ProjectRequest';
import { HourlyContract } from '../../Types/Hourly';
import { htmlIf, maybeHtml } from '../../Utils/HTML';
import { DismissibleModal } from '../../Components/Modal';
import { CustomerRating } from '../../Types/CustomerRating';

type RatingModalButtonProps = {
  requestType: RequestType,
  requestId: number,
  copilotName: string,
  presetOverallRating?: number,
  buttonClass: string,
  buttonText: string,
  requestSetter: Dispatch<React.SetStateAction<ProjectRequest | HourlyContract>>
}

export const RatingModalButton = (props: RatingModalButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [overallRating, setOverallRating] = useState(props.presetOverallRating);
  const [qualityRating, setQualityRating] = useState<number>();
  const [communicationRating, setCommunicationRating] = useState<number>();
  const [timeRating, setTimeRating] = useState<number>();
  const [ratingComment, setRatingComment] = useState('');
  const [copilotFeedback, setCopilotFeedback] = useState('');
  const [luciaFeedback, setLuciaFeedback] = useState('');

  useEffect(() => {
    setOverallRating(props.presetOverallRating)
  }, [showModal])

  function rateRequest() {
    setIsSaving(true);
    const body =
      { requestType: props.requestType
      , requestId: props.requestId
      , rating: overallRating
      , qualityRating: qualityRating
      , communicationRating: communicationRating
      , timeRating: timeRating
      , ratingComment: ratingComment
      , copilotFeedback: copilotFeedback
      , luciaFeedback: luciaFeedback
      }
    API.post("api_requests_rate_request_path", body).then(function (result) {
      if (result['error']) {
      } else {
        switch (props.requestType) {
          case RequestType.PROJECT_REQUEST:
            props.requestSetter(result['request'])
            break
          case RequestType.HOURLY_CONTRACT:
            props.requestSetter(result['contract'])
            break
        }
        setShowModal(false);
      }
      setIsSaving(false);
    })
  }

  return (
    <>
      <button className={props.buttonClass} onClick={() => setShowModal(true)}>{props.buttonText}</button>
      {htmlIf(showModal,
        <DismissibleModal
          dialogClass='modal-lg'
          title={<div className="fs-xl fw-bold text-gray-900">Add a rating for {props.copilotName}</div>}
          body={
            <>
              <div className="mt-3">
                <div className="fs-md fw-semibold text-gray-900 mb-1">Overall Rating</div>
                <FiveStarRating rating={overallRating} setRating={setOverallRating} starClass='h2 mb-0 text-primary pe-1'/>
              </div>
              <div className="mt-3">
                <div className="fs-md text-gray-900 mb-1">Quality of Work</div>
                <FiveStarRating rating={qualityRating} setRating={setQualityRating} starClass='fs-xxl text-primary pe-1'/>
                <div className="fs-md text-gray-900 mt-2 mb-1">Communication</div>
                <FiveStarRating rating={communicationRating} setRating={setCommunicationRating} starClass='fs-xxl text-primary pe-1'/>
                <div className="fs-md text-gray-900 mt-2 mb-1">Timeliness</div>
                <FiveStarRating rating={timeRating} setRating={setTimeRating} starClass='fs-xxl text-primary pe-1'/>
              </div>
              <div className="mt-3">
                <div className="fs-md text-gray-900 mb-1">
                  <span className="fw-semibold">Public Feedback</span>
                  &nbsp;(recommended)
                </div>
                <textarea
                  className="form-control t--rating-comment"
                  rows={4}
                  placeholder='Please share your feedback on this CoPilot — this feedback will be visible on their profile.'
                  onChange={(e) => setRatingComment(e.target.value) }
                  value={ratingComment || ""}
                />
                <div className="fs-md text-gray-900 mb-1 mt-2">
                  <span className="fw-semibold">Private feedback for CoPilot</span>
                  &nbsp;(optional)
                </div>
                <textarea
                  className="form-control t--copilot-feedback"
                  rows={4}
                  placeholder='Use this space to share any additional feedback with your CoPilot that you want to keep private between the two of you.'
                  onChange={(e) => setCopilotFeedback(e.target.value) }
                  value={copilotFeedback || ""}
                />
                <div className="fs-md text-gray-900 mb-1 mt-2">
                  <span className="fw-semibold">Private feedback to Lucia</span>
                  &nbsp;(optional)
                </div>
                <textarea
                  className="form-control t--lucia-feedback"
                  rows={4}
                  placeholder='Use this space to share confidential feedback about your CoPilot with the Lucia team. This information will not be shared with your CoPilot.'
                  onChange={(e) => setLuciaFeedback(e.target.value) }
                  value={luciaFeedback || ""}
                />
              </div>
            </>
          }
          footer={
            <button className="btn btn-primary" onClick={() => rateRequest()} disabled={isSaving}>
              Save
            </button>
          }
          onDismiss={() => setShowModal(false)}
        />
      )}
    </>
  );
}

export const FiveStarRating = ({ rating, setRating, starClass }) => {
  const [hover, setHover] = useState(null);
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star, index) => {
        const currentRating = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              className="d-none"
              value={currentRating}
              onChange={() => setRating(currentRating)}
            />
            <span
              className="cursor-pointer"
              onMouseEnter={() => setHover(currentRating)}
              onMouseLeave={() => setHover(null)}
            >
              <i className={`ai-star${currentRating <= (hover || rating) ? '-filled' : ''} ${starClass}`}/>
            </span>
          </label>
        );
      })}
    </div>
  );
}

export const ReadOnlyRating = ({ rating, starClass }) => {
  return (
    <div>
      {[1, 2, 3, 4, 5].map((star, index) => {
        const currentRating = index + 1;
        return (
          <span key={index}>
            <i className={`ai-star${currentRating <= rating ? '-filled' : ''} ${starClass}`} />
          </span>
        );
      })}
    </div>
  );
}

export const ViewSubmittedRating = (customerRating: CustomerRating) => {
  return (
    <>
      <div className="mt-3">
        <div className="fs-md fw-semibold text-gray-900 mb-1">Overall Rating</div>
        <ReadOnlyRating rating={customerRating.rating} starClass='h2 mb-0 text-primary pe-1'/>
      </div>
      <div className="mt-3">
        {maybeHtml(customerRating.qualityRating, (quality) => (
          <>
            <div className="fs-md text-gray-900 mb-1">Quality of Work</div>
            <ReadOnlyRating rating={quality} starClass='fs-xxl text-primary pe-1'/>
          </>
        ))}
        {maybeHtml(customerRating.communicationRating, (communication) => (
          <>
            <div className="fs-md text-gray-900 mt-2 mb-1">Communication</div>
            <ReadOnlyRating rating={communication} starClass='fs-xxl text-primary pe-1'/>
          </>
        ))}
        {maybeHtml(customerRating.timeRating, (time) => (
          <>
            <div className="fs-md text-gray-900 mt-2 mb-1">Timeliness</div>
            <ReadOnlyRating rating={time} starClass='fs-xxl text-primary pe-1'/>
          </>
        ))}
      </div>
      <div className="mt-3">
        {maybeHtml(customerRating.ratingComment, (publicFeedback) => (
          <>
            <div className="fs-md text-gray-900 fw-semibold mb-1">Public Rating Comment</div>
            <div className="fs-md mt-1">{publicFeedback}</div>
          </>
        ))}
        {maybeHtml(customerRating.copilotFeedback, (copilotFeedback) => (
          <>
            <div className="fs-md text-gray-900 fw-semibold mt-2 mb-1">Private feedback for CoPilot</div>
            <div className="fs-md mt-1">{copilotFeedback}</div>
          </>
        ))}
        {maybeHtml(customerRating.luciaFeedback, (luciaFeedback) => (
          <>
            <div className="fs-md text-gray-900 fw-semibold mt-2 mb-1">Private feedback for Lucia</div>
            <div className="fs-md mt-1">{luciaFeedback}</div>
          </>
        ))}
      </div>
    </>
  )
}
