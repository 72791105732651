import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../Utils/API.js';
import { Copilot } from '../Types/Copilot';
import { Package, PackageTier } from '../Types/Package'
import { PaymentMethod, formatCardBrand, formatCardFunding } from '../Types/PaymentMethod';
import { DismissibleModal } from '../Components/Modal';
import { htmlIf, maybeHtml } from '../Utils/HTML';
import { formatUSDWithCents } from '../Utils/Currency';
import { GiftCardEntry } from './Components/GiftCardEntry';

type Props =
  { platformFeePercent: number
  , paymentMethods: PaymentMethod[]
  , utmLcSource?: string
  , package: Package
  , tier: PackageTier
  , creditValidationKey?: string
  }

const termsAndConditionsPath = '/terms-of-service'
const agentAccountBillingPath = '/agent/account/billing'
const agentRequestsPath = '/agent/requests'
const agentDashboardPath = '/agent'

const PackagePurchase = (props: Props) => {
  const isHourlyPackage = props.package.hourlyRateCents !== undefined && props.package.hourlyRateCents !== null

  const [quantity, setQuantity] = useState(1);
  const [agentNotes, setAgentNotes] = useState('');
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [showErrors, setShowErrors] = useState(false);

  const [availablePaymentMethods, setAvailablePaymentMethods] = useState(props.paymentMethods);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(props.paymentMethods[0].id);
  const [showAddPaymentMethodModal, setShowAddPaymentModal] = useState(false);
  const [creditCodeCode, setCreditCodeCode] = useState<string>();

  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const [submitRequestError, setSubmitRequestError] = useState<string>();

  function packagePriceCents(): number {
    switch (props.tier) {
      case PackageTier.BASIC:
        return props.package.basicAmountCents * quantity
      case PackageTier.STANDARD:
        return props.package.standardAmountCents * quantity
      case PackageTier.PREMIUM:
        return props.package.premiumAmountCents * quantity
    }
  }

  function packageTierName(): string {
    switch (props.tier) {
      case PackageTier.BASIC:
        return "Basic"
      case PackageTier.STANDARD:
        return "Standard"
      case PackageTier.PREMIUM:
        return "Premium"
    }
  }

  function submitRequest() {
    if (!agreeTerms) {
      setShowErrors(true);
    } else {
      setIsSubmittingRequest(true);

      const postBody = {
        packageId: props.package.id,
        tier: props.tier,
        quantity: quantity,
        agentNotes: agentNotes,
        paymentMethodId: selectedPaymentMethodId,
        creditCodeCode: creditCodeCode,
        utmLcSource: props.utmLcSource,
      }

      API.post("agent_packages_submit_package_purchase_path", postBody).then(function (result) {
        setIsSubmittingRequest(false);

        if (result['error'] === true) {
          setSubmitRequestError(
            "Something went wrong. Our team has been notified and will investigate the issue. If you continue to experience problems purchasing a package, please email us at hello@letslucia.com"
          )
        } else if (result['success'] === true) {
          window.location.href = agentRequestsPath
        }
      })
    }
  }

  function platformFeeAmountCents(): number {
    return (packagePriceCents() * props.platformFeePercent * 0.01)
  }

  function totalAmountCents(): number {
    return (packagePriceCents() + platformFeeAmountCents())
  }

  const handleAgreeTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAgreeTerms(event.target.checked);
  };

  function handleNewPaymentMethodAdded() {
    API.post("agent_fetch_payment_methods_path").then(function (result) {
      setAvailablePaymentMethods(result['paymentMethods']);
      setShowAddPaymentModal(false);
    })
  }

  const ViewAddPaymentMethodModal = () => {
    return (
      <DismissibleModal
        title={
          <h4>Add a new payment method</h4>
        }
        body={
          <div>
            Clicking below will open up your Billing settings page in a new tab, where you’ll be able to add
            a new payment method via Stripe. After adding your new payment method, return to this tab and select
            "Done."
          </div>
        }
        footer={
          <div className="text-start">
            <a className="btn btn-primary w-100 w-sm-auto mb-2 mb-sm-0"
              href={agentAccountBillingPath} target="_blank"
            >
              Add new payment method
            </a>
            <button className="btn btn-outline-success w-100 w-sm-auto ms-sm-2"
              onClick={handleNewPaymentMethodAdded}
            >
              Done
            </button>
          </div>
        }
        onDismiss={() => setShowAddPaymentModal(false)}
      />
    )
  }

  return (
    <div>
      { htmlIf(showAddPaymentMethodModal, <ViewAddPaymentMethodModal />)}
      <div className="card">
        <div className="card-body">
          <h4 className="fw-bold">Confirm & Pay</h4>
          <div className="mt-2">
            <h5 className="mb-0">{props.package.title}</h5>
            <div className="fs-sm text-uppercase text-success fw-bolder">{packageTierName()}</div>
            {maybeHtml(props.package.copilotIndividual, (copilot) =>
              <div className="d-flex align-items-center mt-2">
                <div className="avatar avatar-sm">
                  <img className="avatar-img rounded-circle" src={copilot.imageUrl}/>
                </div>
                <div className="ms-1">{copilot.preferredName}</div>
              </div>
            )}
            <div className="d-md-flex mt-2">
              {htmlIf(!isHourlyPackage,
                <div className="fs-md d-flex align-items-center me-md-3">
                  Quantity:
                  <select className="form-select form-select-sm ms-1"
                    value={quantity}
                    onChange={(e) => setQuantity(parseInt(e.target.value))}
                  >
                    {Array.from({ length: 20 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="fs-md d-flex align-items-center">
                Request type:
                <span className="text-dark ms-1 fw-bold">
                  { isHourlyPackage ? "Hourly rate" : "Fixed rate project" }
                </span>
              </div>
              <div className="fs-md d-flex align-items-center ms-md-3">
                { isHourlyPackage ? "Rate" : "Price" }
                <span className="text-dark ms-1 fw-bold">
                  { isHourlyPackage
                  ? formatUSDWithCents(props.package.hourlyRateCents)
                  : formatUSDWithCents(packagePriceCents())
                  }
                </span>
              </div>
            </div>
          </div>
          {htmlIf(!isHourlyPackage,
            <div>
              <hr className="mt-3" />
              <div className="mt-3 fs-md">
                <div className="row">
                  <div className="col">Subtotal:</div>
                  <div className="col-auto text-end">{formatUSDWithCents(packagePriceCents())}</div>
                </div>
                {htmlIf(props.platformFeePercent > 0,
                  <div className="row">
                    <div className="col">Service Fee:</div>
                    <div className="col-auto text-end">{formatUSDWithCents(platformFeeAmountCents())}</div>
                  </div>
                )}
              </div>
              <hr className="mt-2" />
              <div className="mt-2 fs-xl text-dark">
                <div className="row">
                  <div className="col">Total:</div>
                  <div className="col-auto text-end fw-bold">{formatUSDWithCents(totalAmountCents())}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <h4 className="fw-bold">Notes</h4>
          <div className="fs-md">
            Share any additional details that may be helpful to your CoPilot.
            <textarea
              className="form-control mt-2 t--package-agent-notes"
              rows={5}
              placeholder="Add some details…"
              value={agentNotes}
              onChange={(event) => setAgentNotes(event.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <h4 className="fw-bold">Payment</h4>
          <div className="card rounded-2">
            <div className="card-header p-2 mx-0 w-100 text-dark fw-bold">
              Saved payment options
            </div>
            <div className="card-body p-2">
              {availablePaymentMethods.map((paymentMethod, index) => (
                <div className={`form-check ${index === availablePaymentMethods.length - 1 ? 'mb-0' : 'mb-1'}`} key={paymentMethod.id}>
                  <input
                    className="form-check-input"
                    type="radio"
                    id={paymentMethod.id}
                    name="pm-radio"
                    value={paymentMethod.id}
                    onChange={(e) => setSelectedPaymentMethodId(e.target.value)}
                    checked={selectedPaymentMethodId === paymentMethod.id}
                    />
                  <label className="form-check-label" htmlFor={paymentMethod.id}>
                    <b>{formatCardBrand(paymentMethod.card.brand)} •••• {paymentMethod.card.last4}</b>
                    <br />
                    expiring {paymentMethod.card.expiration}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="card rounded-2 mt-3">
            <div className="cursor-pointer d-flex card-body p-2 justify-content-between align-items-center fs-md text-dark fw-bold"
              onClick={() => setShowAddPaymentModal(true)}
            >
              <div>Add credit/debit card</div>
              <i className="ai-chevron-right" />
            </div>
          </div>
          {htmlIf(!isHourlyPackage,
            <GiftCardEntry creditCodeCodeSetter={setCreditCodeCode} validationKey={props.creditValidationKey}/>
          )}
          <div className="mt-3 fs-sm">
            Your personal information will be used to process your order, to
            support your experience on this site and for other purposes described
            in the{" "}
            <a href="/terms-of-service">privacy policy</a>.
          </div>
          <div className="form-check mt-2">
            <input className="form-check-input t--agree-terms"
              type="checkbox" id="agree-terms" checked={agreeTerms} onChange={handleAgreeTermsChange}
            />
            <label className="form-check-label" htmlFor="agree-terms">
              I agree to the <a href={termsAndConditionsPath} target="_blank">Terms of Service</a>
            </label>
          </div>
          {htmlIf((showErrors && !agreeTerms),
            <div className="fs-sm mt-1 text-danger fw-bold">Please agree to the Terms of Service.</div>
          )}
          <div className="mt-3">
            <button
              className="btn btn-success px-md-6 t--submit-package-purchase"
              onClick={submitRequest}
              disabled={isSubmittingRequest}
            >
              Confirm & Pay
            </button>
          </div>
          {htmlIf(submitRequestError !== null,
            <div className="mt-2 text-danger fw-bold">{submitRequestError}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PackagePurchase;
