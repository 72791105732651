import * as React from 'react';
import { useState, useEffect } from 'react';
import { BlogPost } from '../Types/Blog';

type Props =
  { posts: BlogPost[]
  }

function blogPostPath(post: BlogPost) {
  return `/blog/${post.slug}`
}

const AllPosts = (props: Props) => {
  const [searchText, setSearchText] = useState('');
  const [filteredPosts, setFilteredPosts] = useState(props.posts);

  // This hook serves to re-sort and re-filter the list of posts when the search text is changed
  useEffect(() => {
    const filtered = props.posts.filter((post) => {
      const searchFields = [
        post.title
      ];
      return (
        searchFields.some((field) =>
          field?.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    });

    setFilteredPosts(filtered);
  }, [searchText])

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchText(query);
  }

  const ViewPost = (post: BlogPost) => (
    <a className="py-3 border-bottom row align-items-center text-decoration-none cursor-pointer" href={blogPostPath(post)}>
      <div className="col-7">
        <h5 className="mb-0">{post.title}</h5>
      </div>
      <div className="col-5 text-center">
        <div className="bg-secondary rounded-3 text-center" style={{ width: '100%', height: '0', paddingTop: '56.25%', position: 'relative' }}>
          {/* Set for a 16:9 aspect ratio */}
          <img
            src={post.coverImageUrl}
            style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%'}}
            className="rounded-3"
            loading='lazy'
          />
        </div>
      </div>
    </a>
  )

  return (
    <div className="offset-xl-2 col-xl-8">
      <div className="row mt-3 align-items-center">
        <div className="input-group align-items-center p-0 bg-white">
          <div className="input-group-prepend ms-2">
            <i className="ai-search"></i>
          </div>
          <input
            className="form-control"
            type="search"
            value={searchText}
            onChange={handleSearchInputChange}
            placeholder="Search…"
          />
        </div>
      </div>
      <div className="row mt-3">
        {filteredPosts.map((post) => (
        <div className="">
          {ViewPost(post)}
        </div>
        ))}
      </div>
    </div>
  )
}

export default AllPosts;
