import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import * as Livestream from '../Types/Livestream';
import { htmlIf, formatMarkdown, maybeHtml, formatMultiParagraphString } from '../Utils/HTML';
import { isInTheFuture, isInThePast } from '../Utils/DateTime';
import { CurrencyCode, formatExchangeCurrencyWithoutCents, ExchangeRate } from '../Utils/Currency';
import { Copilot, copilotProfileUrl } from '../Types/Copilot';
import { Tag } from '../Types/Tag';
import { User } from '../Types/User';
import { DismissibleModal } from '../Components/Modal';
import EventGrid from './EventGrid';
import Perspective from '../Types/Perspective';
import PackageCard from '../Common/PackageCard';

type Props =
  { event: Livestream.Event
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  , user?: User
  , isRegistered: boolean
  , utmLcSource?: string
  , otherEvents: Livestream.Event[]
  , perspective: Perspective
  }

enum Modal
  { NoModal
  , RegistrationConfirmed
  , AnonymousDetails
  }

function formatDate(dateString: string):string {
  const date = new Date(dateString)
  return (
    date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      timeZoneName: 'short'
    })
  )
}

const EventDetail = (props: Props) => {
  const [isRegistering, setIsRegistering] = useState(false);
  const [isRegistered, setIsRegistered] = useState(props.isRegistered);
  const [modal, setModal] = useState(Modal.NoModal);

  const [anonFirstName, setAnonFirstName] = useState('');
  const [anonLastName, setAnonLastName] = useState('');
  const [anonEmail, setAnonEmail] = useState('');
  const [showErrors, setShowErrors] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  function handleRegisterClicked() {
    setIsRegistering(true);
    if (props.user) {
      const body = { eventId: props.event.id, utmLcSource: props.utmLcSource }
      API.post("api_livestreams_register_path", body).then(function (result) {
        if (result['error']) {
        } else {
          setIsRegistered(true);
          setModal(Modal.RegistrationConfirmed);
          setIsRegistering(false);
        }
      })
    } else {
      setModal(Modal.AnonymousDetails);
    }
  }

  function handleAnonRegisterClicked() {
    if (anonFirstName.length > 0 && anonLastName.length > 0 && anonEmail.length > 5) {
      const body = {
        eventId: props.event.id,
        firstName: anonFirstName,
        lastName: anonLastName,
        email: anonEmail,
        utmLcSource: props.utmLcSource
      }
      API.post("api_livestreams_register_path", body).then(function (result) {
        if (result['error']) {
          setErrorMessage(result['error']);
          setShowErrors(true);
        } else {
          setIsRegistered(true);
          setModal(Modal.RegistrationConfirmed);
          setIsRegistering(false);
        }
      })
    } else {
      setShowErrors(true);
    }
  }

  const ViewTags = (tags: Tag[]) => {
    const firstCoupleTags = tags.slice(0, 7)
    const numTagsRemaining = tags.length - firstCoupleTags.length

    return (
      <>
        {firstCoupleTags.map((tag) => (
          <div key={`Tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.name}</div>
        ))}
        {htmlIf(numTagsRemaining > 0,
          <div key='more-tags' className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">+{numTagsRemaining} skills</div>
        )}
      </>
    )
  }

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.RegistrationConfirmed:
        return (
          <DismissibleModal
            body={
              <>
                <div className="d-flex justify-content-center">
                  <div className="avatar avatar-48 rounded-circle bg-success d-flex align-items-center justify-content-center">
                    <i className="ai-check fs-xxl text-white"/>
                  </div>
                </div>
                <div className="mt-3 text-center text-dark">
                  <h5 className="mb-1">You’re registered!</h5>
                  <div className="fs-md">Check your email for more details and a calendar invitation. See you soon!</div>
                </div>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
      case Modal.AnonymousDetails:
        return (
          <DismissibleModal
            title={<h5 className="mb-0">Register</h5>}
            body={
              <>
                <div className="mt-3 text-dark">
                  <h6 className="mb-0">{props.event.title}</h6>
                  <div className="fs-sm">{formatDate(props.event.startTime)}</div>
                </div>
                <div className="row mt-3">
                  <div className="col-md-6">
                    <label className="form-label">First name</label>
                    <input
                      className="form-control t--first-name" type="text"
                      value={anonFirstName}
                      onChange={(e) => setAnonFirstName(e.target.value)}
                    />
                    {htmlIf(showErrors && !anonFirstName.length,
                      <div className="fs-sm mt-1 text-danger fw-bold">Please enter your first name.</div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label className="form-label">Last name</label>
                    <input
                      className="form-control t--last-name" type="text"
                      value={anonLastName}
                      onChange={(e) => setAnonLastName(e.target.value)}
                    />
                    {htmlIf(showErrors && !anonLastName.length,
                      <div className="fs-sm mt-1 text-danger fw-bold">Please enter your last name.</div>
                    )}
                  </div>
                </div>
                <div className="mt-3">
                  <label className="form-label">Email</label>
                  <input
                    className="form-control t--email" type="email"
                    value={anonEmail}
                    onChange={(e) => setAnonEmail(e.target.value)}
                  />
                  {htmlIf(showErrors && anonEmail.length < 6,
                    <div className="fs-sm mt-1 text-danger fw-bold">Please enter a valid email.</div>
                  )}
                </div>
                <button className="btn btn-primary mt-3 t--register-anonymous" onClick={handleAnonRegisterClicked}>
                  Register
                </button>
                {htmlIf(showErrors && errorMessage,
                  <div className="fs-sm mt-1 text-danger fw-bold">{errorMessage}</div>
                )}
              </>
            }
            onDismiss={() => {
              setIsRegistering(false);
              setModal(Modal.NoModal);
            }}
          />
        )
    }
  }

  return (
    <>
      {ViewModal()}
      <div className="section bg-accent pt-3 pt-md-6 pb-md-1">
        <div className="container mb-md-n15">
          <div className="row">
            <div className="col-md-6 order-2 order-md-1 pb-5 pb-md-15 pt-3">
              {htmlIf(isInThePast(props.event.startTime) && !props.event.recordingUrl,
                <div className="badge bg-primary text-white mb-1">Recording coming soon</div>
              )}
              {htmlIf(isInTheFuture(props.event.startTime) && !props.event.registrationUrl && !props.event.onPlatformRegistration,
                <div className="badge bg-primary text-white mb-1">Registration opening soon</div>
              )}
              <h4 className="mb-1 text-dark">{props.event.title}</h4>
              <div className="fs-sm text-dark">
                {formatDate(props.event.startTime)}
                {maybeHtml(props.event.durationMinutes, (duration) => (
                  <>
                    {" • "}{duration} minutes
                  </>
                ))}
              </div>
              {htmlIf(isInThePast(props.event.startTime) && props.event.recordingUrl,
                <div className="mt-2">
                  <a className="btn btn-primary mb-2" href={props.event.recordingUrl} target="_blank">Watch recording</a>
                </div>
              )}
              {htmlIf(isInTheFuture(props.event.startTime) && (props.event.registrationUrl || props.event.onPlatformRegistration),
                <div className="mt-2">
                  { props.event.onPlatformRegistration
                  ? isRegistered
                    ? <div className="badge bg-success text-white mb-1">We’ll see you there!</div>
                    : <button className="btn btn-primary mb-2"
                      onClick={handleRegisterClicked} disabled={isRegistering}
                      >Register</button>
                  : <a className="btn btn-primary mb-2" href={props.event.registrationUrl} target="_blank">Register</a>
                  }
                </div>
              )}
            </div>
            <div className="col-md-6 order-1 order-md-2">
              <div style={{ width: '100%', height: '0', paddingTop: '56.25%', position: 'relative' }}>
                {/* Set for a 16:9 aspect ratio */}
                <img
                  src={props.event.imageUrl}
                  style={{ position: 'absolute', top: '0', left: '0', width: '558px', height: 'auto'}}
                  className="rounded-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section pt-6 mb-6">
        <div className="container">
          <div className="col-md-6">
            <div className="">
              <div className="fs-xl fw-bold text-dark mb-1">What we’ll cover</div>
              <div className="">{formatMarkdown(props.event.description)}</div>
            </div>
            {maybeHtml(props.event.copilotIndividual, (copilot) => (
              <div className="mt-6">
                <div className="fs-xl fw-bold text-dark mb-1">Your host</div>
                <div>
                  <div className="d-flex align-items-center">
                    <a className="avatar avatar-lg me-2" href={copilotProfileUrl(copilot)} target="_blank">
                      <img className="avatar-img rounded-circle" src={copilot.imageUrl} />
                    </a>
                    <div className="w-100">
                      <div className="d-flex justify-content-between align-items-center fs-md">
                        <a className="text-gray-900 fw-semibold text-decoration-none" href={copilotProfileUrl(copilot)} target="_blank">
                          {copilot.preferredName}
                        </a>
                        <div className="text-dark d-flex align-items-center">
                          <i className="ai-star me-1"/>
                          {copilot.averageRating?.toFixed(1)}
                          <span className="text-secondary ms-1">({copilot.numRatings} {copilot.numRatings === 1 ? 'review' : 'reviews'})</span>
                        </div>
                      </div>
                      {htmlIf((copilot.hourlyRateCents !== null && copilot.hourlyRateCents > 0),
                        <div className="text-dark mt-1">
                          {formatExchangeCurrencyWithoutCents(props.displayCurrency, copilot.hourlyRateCents, props.exchangeRates)}/hour
                        </div>
                      )}
                      {ViewTags(copilot.tags)}
                    </div>
                  </div>
                  <div className="mt-3">
                    <div className="fs-md truncate-10-lines">{formatMultiParagraphString(copilot.bio)}</div>
                  </div>
                  <div className="mt-1">
                    <a href={copilotProfileUrl(copilot)} target="_blank" className="fs-md fw-semibold text-primary text-decoration-none">
                      View more
                    </a>
                  </div>
                </div>
              </div>
            ))}
            {maybeHtml(props.event.package, (pkg) => (
              <div className="col-xl-8 mt-3">
                {PackageCard(pkg)}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="section py-8 mb-n8 bg-white">
        <div className="container">
          <h4 className="">Other Great Livestreams</h4>
          <EventGrid
            events={props.otherEvents.sort((a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime())}
            perspective={props.perspective} targetBlank={true}
          />
        </div>
      </div>
    </>
  )
}

export default EventDetail;
