import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import * as API from '../../Utils/API';
import { Copilot } from '../../Types/Copilot';
import { htmlIf } from '../../Utils/HTML';

type Props =
  { individual: Copilot
  , funFacts: AllFunFacts
  }

export type AllFunFacts =
  { advice: string
  , bucketListDestinations: string
  , favoriteAirline: string
  , favoriteDestination: string
  , favoriteFood: string
  , favoriteHotel: string
  , firstJob: string
  , fiveWordDescription: string
  , hobbies: string
  , destinationExpertise: string
  }


export const EditFunFacts = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const [state, setState] = useState<AllFunFacts>(props.funFacts);

  function saveFunFacts() {
    const postBody = {
      copilotIndividualId: props.individual.id,
      advice: state.advice,
      bucketListDestinations: state.bucketListDestinations,
      favoriteAirline: state.favoriteAirline,
      favoriteDestination: state.favoriteDestination,
      favoriteFood: state.favoriteFood,
      favoriteHotel: state.favoriteHotel,
      firstJob: state.firstJob,
      fiveWordDescription: state.fiveWordDescription,
      hobbies: state.hobbies,
      destinationExpertise: state.destinationExpertise
    }

    API.post("copilot_account_update_fun_facts_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  const handleChange = (key: keyof AllFunFacts, value: string) => {
    setState(prevState => ({ ...prevState, [key]: value }));
  };

  const renderFunFact = (emoji: string, question: string, value?: string, key?: keyof AllFunFacts) => {
    if (isEditing) {
      return (
        <div className="mb-1">
          {question} {emoji}
          <input
            type="text"
            maxLength={140}
            value={value || ''}
            onChange={(event) => handleChange(key!, event.target.value)}
            className="form-control"
          />
        </div>
      );
    } else {
      return (
        <div className="row g-0 no-gutters align-items-center mb-2">
          <div className="col-auto justify-content-center">
            <div className="avatar avatar-md me-2">
              <div className="avatar-title bg-accent rounded-1 lead">{emoji}</div>
            </div>
          </div>
          <div className="col">
            <div className="fs-sm">{question}</div>
            {value
            ? <div className="fs-md text-dark">{value}</div>
            : <div className="fs-md text-secondary">Not set</div>
            }
          </div>
        </div>
      );
    }
  };

  return (
    <div className="card">
      <div className="card-header d-flex align-items-center justify-content-between py-1">
        <h4 className="mb-0">Fun Facts</h4>
        {htmlIf(!isEditing,
          <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
            <i className="ai-edit-alt me-1"></i>
            Edit
          </button>
        )}
      </div>
      <div className="card-body">
        <div className="row">
          {renderFunFact('💬', 'Describe yourself in five words', state.fiveWordDescription, 'fiveWordDescription')}
          {renderFunFact('💭', 'Best piece of advice', state.advice, 'advice')}
          {renderFunFact('🧑🏼‍💼', 'First job', state.firstJob, 'firstJob')}
          {renderFunFact('🌎', 'Favorite destination', state.favoriteDestination, 'favoriteDestination')}
          {renderFunFact('🍽', 'Favorite food', state.favoriteFood, 'favoriteFood')}
          {renderFunFact('🗺', 'Bucket list destinations', state.bucketListDestinations, 'bucketListDestinations')}
          {renderFunFact('🏨', 'Favorite hotel', state.favoriteHotel, 'favoriteHotel')}
          {renderFunFact('✈️', 'Favorite airline', state.favoriteAirline, 'favoriteAirline')}
          {renderFunFact('⭐', 'Hobbies', state.hobbies, 'hobbies')}
          {renderFunFact('🌴', 'Destination Expertise', state.destinationExpertise, 'destinationExpertise')}
        </div>
        {isEditing && (
          <button className="btn btn-primary px-3" onClick={() => saveFunFacts()}>
            Save
          </button>
        )}
      </div>
    </div>
  );
}
