import * as React from 'react';
import { Copilot, CopilotWorkStyle } from '../../Types/Copilot';
import { Tag } from '../../Types/Tag';
import AboutMe from './AboutMe';
import { AllFunFacts, EditFunFacts } from './FunFacts';
import { CopilotCard, emptyClickHandler } from '../../Agent/Components/CopilotCard';
import { CurrencyCode, ExchangeRate } from '../../Utils/Currency';

type Props =
  { individual: Copilot
  , allTags: Tag[]
  , allWorkStyles: CopilotWorkStyle[]
  , funFacts: AllFunFacts
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate
  }

const Profile = (props: Props) => (
  <div>
    <AboutMe individual={props.individual} allTags={props.allTags} allWorkStyles={props.allWorkStyles} />
    <div className="mt-3" />
    <EditFunFacts individual={props.individual} funFacts={props.funFacts} />
    <div className="mt-3" />
    <div className="">
      <h4 className="mb-1">Profile Preview</h4>
      <div className="text-secondary mb-2">
        This is what your profile looks like to users!
        Note that changes made above will only be reflected after you save and reload the page.
      </div>
      <CopilotCard
        copilot={props.individual}
        cardButtons={[
          { btnClass: "btn btn-outline-primary t--view-copilot-profile w-100 w-md-auto",
            btnLabel: "View Full Profile",
            onClick: emptyClickHandler,
            isViewProfileModalButton: true
          }
        ]}
        displayCurrency={props.displayCurrency}
        exchangeRates={props.exchangeRates}
        featuredTags={[]}
      />
    </div>
  </div>
)

export default Profile;
