export type CurrencyCode = string;

/**
 * The ExchangeRate type is the Typescript manifestation of the result of the Rails
 * CurrencyService.exchange_rates method, which returns a hash of currency codes and the conversion to USD.
*/
export type ExchangeRate = Record<CurrencyCode, number>;

// This list is repeated in Ruby in `currency_service.rb`.
const currenciesMap: Record<CurrencyCode, string> = {
  "USD": "US Dollar",
  "GBP": "Great British Pound",
  "CAD": "Canadian Dollar",
  "EUR": "Euro",
  "AUD": "Australian Dollar",
  "CHF": "Swiss Franc"
};

export function name(currencyCode: CurrencyCode): string {
  return currenciesMap[currencyCode]
}

export function asSelectOption(currencyCode: CurrencyCode): {value: any, label: string} {
  return ({ value: currencyCode, label: name(currencyCode) || "" })
}

export function allAsSelectOptions(): {value: any, label: string}[] {
  return Object.entries(currenciesMap).map(([value, label]) => ({ label, value }))
}

/**
  * Takes a number of cents and formats it into USD (with cents displayed).
  * @param {number} cents - The amount (in cents) to display as USD.
  * @return {string} - The USD value (for example: $32.65)
*/
export function formatUSDWithCents(cents: number): string {
  return Number(cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

/**
 * Takes a number of cents and formats it into USD (without cents displayed).
 * @param {number} cents - The amount (in cents) to display as USD.
 * @return {string} - The USD value (for example: $32)
 */
export function formatUSDWithoutCents(cents: number): string {
  return Number(cents / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 0 });
}


/**
  * Takes a number of USD cents and converts & formats it into the supplied currency (with cents displayed).
  * @param {CurrencyCode} currency – The CurrencyCode (three-letter ISO code) representing the currency.
  * @param {number} USDcents - The amount (in USD cents) to display as the desired currency.
  * @param {ExchangeRate} exchangeRates – A Record, from Rails, containing the latest exchange rate data. See CurrencyService.
  * @return {string} - The value (for example: $32.65, or £45.26)
*/
export function formatExchangeCurrencyWithCents(currency: CurrencyCode, USDcents: number, exchangeRates: ExchangeRate): string {
  const convertedAmountCents = convertUSDCentsToCurrency(currency, USDcents, exchangeRates);
  return Number(convertedAmountCents / 100).toLocaleString('en-US', { style: 'currency', currency: currency})
}

/**
  * Takes a number of USD cents and converts & formats it into the supplied currency, rounded to the nearest whole denomination.
  * @param {CurrencyCode} currency – The CurrencyCode (three-letter ISO code) representing the currency.
  * @param {number} USDcents - The amount (in USD cents) to display as the desired currency.
  * * @param {ExchangeRate} exchangeRates – A Record, from Rails, containing the latest exchange rate data. See CurrencyService.
  * @return {string} - The value (for example: $33 or £45)
*/
export function formatExchangeCurrencyWithoutCents(currency: CurrencyCode, USDcents: number, exchangeRates: ExchangeRate): string {
  const convertedAmountCents = convertUSDCentsToCurrency(currency, USDcents, exchangeRates);
  return Math.round(Number(convertedAmountCents / 100)).toLocaleString(
    'en-US', { style: 'currency', currency: currency, minimumFractionDigits: 0}
  )
}

/**
 * Takes a currency and a number of cents (in that currency) and formats it as a string.
 *
 * NOTE: This function DOES NOT utilize any exchange rates or currency conversion. To display an amount
 * of USD cents as a foreign currency (and do the required currency exchange), use `formatExchangeCurrencyWithCents` instead.
 * @param {CurrencyCode} currency The CurrencyCode (three-letter ISO code) representing the currency.
 * @param {number} currencyCents The amount (in the supplied currency's cents) to display.
 * @returns {string} The value (for example: £45.26)
 */
export function formatCurrencyWithCents(currency: CurrencyCode, currencyCents: number): string {
  return Number(currencyCents / 100).toLocaleString('en-US', { style: 'currency', currency: currency})
}

/**
 * Returns the currency symbol for the supplied CurrencyCode
 * @param currency The CurrencyCode (three-letter ISO code) representing the currency.
 * @returns {string} The symbol (for example: £ or CA$)
 */
export function currencySymbol(currency: CurrencyCode): string {
  const currencyString = Number(0).toLocaleString(
    'en-US', { style: 'currency', currency: currency, minimumFractionDigits: 0}
  )
  return currencyString.replace(/0/g, '');
}

/**
 * Typescript version of the Rails CurrencyService#convert_to_usd_cents method. Given a currency and an amount of
 * cents in that currency, as well as exchange rate data, converts the amount to USD cents using latest exchange rates.
 * @param currency The CurrencyCode that the `cents` are in
 * @param cents The amount of the `currency`, in cents, to be converted to USD cents
 * @param exchangeRates A Record, from Rails, containing the latest exchange rate data. See CurrencyService.
 * @returns {number} An amount, in USD cents.
 */
export function convertToUSDCents(currency: CurrencyCode, cents: number, exchangeRates: ExchangeRate): number {
  const currencyToUSD = exchangeRates[currency];
  return Math.round(cents / currencyToUSD);
}

// Typescript version of the Rails CurrencyService#convert_usd_cents_to_currency method.
/**
 * Typescript version of the Rails CurrencyService#convert_usd_cents_to_currency method. Given a currency and an amount of
 * USD cents, as well as exchange rate data, converts the amount of USD cents to cents in the supplied currency using latest exchange rates.
 * @param currency The CurrencyCode that the result should be in
 * @param cents The amount of USD cents, to be converted to the supplied currrency
 * @param exchangeRates A Record, from Rails, containing the latest exchange rate data. See CurrencyService.
 * @returns An amount, in the supplied Currency's cents.
 */
export function convertUSDCentsToCurrency(currency: CurrencyCode, cents: number, exchangeRates: ExchangeRate): number {
  const currencyToUsd = exchangeRates[currency];
  return Math.round(cents * currencyToUsd);
}
