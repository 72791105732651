import * as React from 'react';
import { useState } from 'react';
import * as API from '../../Utils/API.js';
import { Copilot } from '../../Types/Copilot';
import { lastSeenAtDescription } from '../../Utils/DateTime'
import { htmlIf, maybeHtml, formatMultiParagraphString } from '../../Utils/HTML';
import { Tag } from '../../Types/Tag';
import Perspective from '../../Types/Perspective';
import * as CopilotProfile from '../../Common/CopilotProfile';
import { DismissibleModal } from '../../Components/Modal';
import { CurrencyCode, ExchangeRate, formatExchangeCurrencyWithoutCents } from '../../Utils/Currency';

type Props =
  { copilot: Copilot
  , cardButtons: ButtonData[]
  , displayCurrency: CurrencyCode
  , exchangeRates: ExchangeRate

  // featuredTags allows us to pass an array of tags that are most important in the current context
  // (i.e. tags that a user added to a potential request). If the CoPilot in question has any of these featured tags,
  // they will be prioritized in the display of tags on the CoPilot's card.
  , featuredTags: Tag[]
  // allowedTags allows us to pass an (optional) array of tags that are allowed to be displayed on the CoPilot card.
  // If allowedTags is set, the set of possible tags we could display on a CoPilot card is limited to only the tags
  // included in the allowedTags array.
  // This is primarily used to only show relevant Property or Agent tags (depending on the user's perspective).
  , allowedTags?: Tag[]
  }

export type ButtonData =
  { btnClass: string
  , btnLabel: string
  , onClick: (event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot) => void
  , isViewProfileModalButton?: boolean
  }

/**
 * This is a click handler that doesn't do anything, meant to supplied as the `onClick` property in `CopilotCard.ButtonData`
 * when `isViewProfileModalButton` is TRUE.
 *
 * The click handler for the "View Profile" button is already defined and handled
 * in the CopilotCard component itself (it brings up a modal of the Copilot profile).
 */
export function emptyClickHandler(event: React.MouseEvent<HTMLButtonElement>, copilot: Copilot): void {}

const expertCopilotIds = [15, 17, 22, 37, 41, 44, 52, 58, 95, 140];

export const CopilotCard = (props: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [profileProps, setProfileProps] = useState<CopilotProfile.Props>();

  function handleViewProfileModalClicked() {
    const body = { copilotIndividualId: props.copilot.id, perspective: Perspective.ViewOnly }
    API.post("agent_fetch_copilot_profile_props_path", body).then(function (result) {
      setProfileProps(result);
      setShowProfileModal(true);
    })
  }

  const ViewTags = () => {
    let allowedCopilotTags = props.copilot.tags;
    if (props.allowedTags !== null && props.allowedTags !== undefined) {
      allowedCopilotTags = props.copilot.tags.filter(copilotTag =>
        props.allowedTags.some(allowedTag => allowedTag.id === copilotTag.id)
      )
    }

    const commonTags = allowedCopilotTags.filter(copilotTag =>
      props.featuredTags.some(featuredTag => featuredTag.id === copilotTag.id)
    );

    const firstCoupleTags = allowedCopilotTags.slice(0, 7)

    const numTagsRemaining =
      commonTags.length > 0
      ? allowedCopilotTags.length - commonTags.length
      : allowedCopilotTags.length - firstCoupleTags.length

    const tagsToDisplay = commonTags.length > 0 ? commonTags : firstCoupleTags

    return (
      <>
        {tagsToDisplay.map((tag) => (
          <div key={`Tag-${tag.id}`} className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">{tag.name}</div>
        ))}
        {htmlIf(numTagsRemaining > 0,
          <div key='more-tags' className="badge bg-accent badge-sm text-dark me-1 d-inline-block mt-1">+{numTagsRemaining} skills</div>
        )}
      </>
    )
  }

  return (
    <>
      {maybeHtml(profileProps, (profProps) =>
          <>
            {htmlIf(showProfileModal,
              <DismissibleModal
                dialogClass="modal-xl"
                bodyClass=""
                body={<div className="mt-1">{CopilotProfile.CopilotProfile(profProps)}</div>}
                onDismiss={() => setShowProfileModal(false)}
              />
            )}
          </>
        )}
      <div className="card position-relative rounded-1">
        <div className="card-body py-2">
          <div className="row align-items-center">
            <div className="col-md">
              <div className="d-flex align-items-center">
                <div className="avatar avatar-xl me-2">
                  <img className="avatar-img rounded-circle" src={props.copilot.imageUrl} loading='lazy'/>
                </div>
                <div className="">
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0">{props.copilot.preferredName}</h6>
                    {htmlIf(expertCopilotIds.includes(props.copilot.id),
                      <div className="ms-1 badge bg-success d-flex align-items-center">
                        <i className="ai-star fs-md me-1" />
                        Expert CoPilot
                      </div>
                    )}
                  </div>
                  {htmlIf((props.copilot.averageRating !== null && props.copilot.numRatings > 0),
                    <div className="text-dark d-flex align-items-center mt-1">
                      <i className="ai-star me-1"/>
                      {props.copilot.averageRating?.toFixed(1)}
                      <span className="text-secondary ms-1">({props.copilot.numRatings} {props.copilot.numRatings === 1 ? 'review' : 'reviews'})</span>
                    </div>
                  )}
                  {htmlIf((props.copilot.hourlyRateCents !== null && props.copilot.hourlyRateCents > 0),
                    <div className="text-dark mt-1">
                      {formatExchangeCurrencyWithoutCents(props.displayCurrency, props.copilot.hourlyRateCents, props.exchangeRates)}/hour
                    </div>
                  )}
                  <div className="mt-1 d-none d-md-block">
                    {/* Tag view on md+ screens */}
                    <ViewTags />
                  </div>
                  {htmlIf(props.copilot.lastSeenAt,
                    <div className="mt-1 fs-xs">{lastSeenAtDescription(props.copilot.lastSeenAt)}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-1 d-md-none">
              {/* Tag view on xs/sm screens */}
              <ViewTags />
            </div>
            <div className="col-md-auto mt-2 mt-md-0 align-items-center">
              <div className="d-flex flex-column">
                {props.cardButtons?.map((buttonData) => (
                  <button
                    key={buttonData.btnLabel}
                    className={buttonData.btnClass}
                    onClick={
                      buttonData.isViewProfileModalButton
                      ? handleViewProfileModalClicked
                      : (event: React.MouseEvent<HTMLButtonElement>) => buttonData.onClick(event, props.copilot)
                    }
                  >
                    {buttonData.btnLabel}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
