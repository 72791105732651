import * as React from 'react';
import { useState } from 'react';
import * as API from '../Utils/API.js';
import { BlogContent, BlogContentType, BlogCtaContent, BlogPost, BlogPostSection, BlogQuoteContent, BlogTextContent } from '../Types/Blog';
import { htmlIf, maybeHtml, formatMultiParagraphString, parameterize } from '../Utils/HTML';
import { DismissibleModal } from '../Components/Modal';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';


type Props =
  { post: BlogPost
  }

enum Modal
  { NoModal
  , AddBlockModal
  }

type SaveNewBlockFunction = (content: BlogContent, contentType: BlogContentType) => void;

// Wrote a lot of this component with help from ChatGPT!
const BlogPostManager = (props: Props) => {
  const [post, setPost] = useState(props.post);
  const [modal, setModal] = useState(Modal.NoModal);
  const [modalContentType, setModalContentType] = useState<BlogContentType>(null);

  const [postTitle, setPostTitle] = useState(props.post.title);
  const [postSlug, setPostSlug] = useState(props.post.slug);
  const [postSeoDescription, setPostSeoDescription] = useState(props.post.seoDescription);

  const ModalContentTypes = [
    { label: 'Text', value: BlogContentType.TEXT },
    { label: 'Image', value: BlogContentType.IMAGE },
    { label: 'Quote', value: BlogContentType.QUOTE },
    { label: 'CTA', value: BlogContentType.CTA },
  ];

  function savePost() {
    const apiPostBody = {
      blogPostId: post.id,
      title: postTitle,
      slug: postSlug,
      seoDescription: postSeoDescription,
      sections: post.sections
    }

    API.post("admin_blog_update_post_path", apiPostBody).then(function (result) {
      if (result["publicBlogPostUrl"]) {
        window.open(result["publicBlogPostUrl"])
      }
    })
  }

  function cancelNewBlock() {
    setModal(Modal.NoModal);
    setModalContentType(null);
  }

  function saveNewBlock(content: BlogContent, contentType: BlogContentType) {
    const sections = [...post.sections]; // Ensure immutability
    const newSection: BlogPostSection = {
      id: null,
      position: sections.reduce((max, section) => section.position > max ? section.position : max, 0) + 1,
      contentType: contentType,
      contentId: null,
      content: content
    }

    setPost(prevPost => ({
      ...prevPost,
      sections: [...prevPost.sections, newSection] // Ensure immutability
    }));
    cancelNewBlock(); // We're not really cancelling, but we want to close and reset the modal.
  }

  const ViewTextContentBlock = (textContent: BlogTextContent) => {
    return (
      <div className={textContent.htmlClass}>{formatMultiParagraphString(textContent.text)}</div>
    )
  }

  const ViewQuoteContentBlock = (quoteContent: BlogQuoteContent) => {
    return (
      <div className="d-flex">
        <img src='/img/blog/top-quote.svg' className="img-fluid position-absolute" style={{top: 0, left: 0}}/>
        <div className="flex-grow-1 d-flex flex-column justify-content-center py-7 text-dark">
          <div className="fs-32">{quoteContent.quote}</div>
          {maybeHtml(quoteContent.author, (author) => (
            <div className="fs-xl mt-2">— {author}</div>
          ))}
        </div>
        <img src='/img/blog/bottom-quote.svg' className="img-fluid position-absolute" style={{bottom: 0, right: 0}}/>
      </div>
    )
  }

  const ViewCtaContentBlock = (ctaContent: BlogCtaContent) => {
    return (
      <div className={ctaContent.containerClass}>
        <div className={ctaContent.titleClass}>{ctaContent.title}</div>
        <div className={ctaContent.bodyClass}>{ctaContent.body}</div>
        <a className={ctaContent.buttonClass} href={ctaContent.buttonUrl} target="_blank">
          {ctaContent.buttonText}
        </a>
      </div>
    )
  }

  const ViewSectionContent = (section: BlogPostSection) => {
    switch (section.contentType) {
      case BlogContentType.TEXT:
        return ViewTextContentBlock(section.content as BlogTextContent)
      case BlogContentType.QUOTE:
        return ViewQuoteContentBlock(section.content as BlogQuoteContent)
      case BlogContentType.CTA:
        return ViewCtaContentBlock(section.content as BlogCtaContent)
    }
  }

  const ViewModal = () => {
    switch(modal) {
      case Modal.NoModal:
        return null
      case Modal.AddBlockModal:
        return (
          <DismissibleModal
            dialogClass='modal-xl'
            body={
              <>
                <div className="col-md-6">
                  <div className="mb-2 fw-semibold">Block Type</div>
                  <select className="form-select"
                    value={modalContentType || ''}
                    onChange={(e) => setModalContentType(e.target.value as BlogContentType)}
                  >
                    <option value="" disabled>Select Content Type</option>
                    {ModalContentTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mt-3">
                  {maybeHtml(modalContentType, (contentType) => {
                    switch(contentType) {
                      case BlogContentType.TEXT:
                        return <ViewTextInputDetails onSave={saveNewBlock}/>
                      case BlogContentType.QUOTE:
                        return <ViewQuoteInputDetails onSave={saveNewBlock}/>
                      case BlogContentType.CTA:
                        return <ViewCtaInputDetails onSave={saveNewBlock}/>
                      case BlogContentType.IMAGE:
                        return <div>Images within the content are not yet supported.</div>
                      default:
                        return null;
                    }
                  })}
                </div>
                <div className="mt-5 text-center">
                  <button className="btn btn-danger" onClick={() => cancelNewBlock()}>
                    Cancel
                  </button>
                </div>
              </>
            }
            onDismiss={() => setModal(Modal.NoModal)}
          />
        )
    }
  }

  // Courtesy of ChatGPT
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedSections = Array.from(post.sections);
    const [movedSection] = reorderedSections.splice(result.source.index, 1);
    reorderedSections.splice(result.destination.index, 0, movedSection);

    // Update positions
    const updatedSections = reorderedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));

    // Update the post's sections with reordered ones
    setPost({ ...post, sections: updatedSections });
  };

  // Courtesy of ChatGPT
  function deleteSection(targetSection: BlogPostSection) {
    const updatedSections = post.sections.filter((section) => section !== targetSection);

    // Reassign positions after deletion
    const reorderedSections = updatedSections.map((section, index) => ({
      ...section,
      position: index + 1,
    }));

    // Update the post's sections with the filtered and reordered ones
    setPost({ ...post, sections: reorderedSections });
  }


  return (
    <>
      {ViewModal()}
      <div>
        <div className="fw-semibold mb-1">Post Title</div>
        <input className="form-control form-control-lg"
          value={postTitle}
          onChange={(event) => setPostTitle(event.target.value)}
          placeholder="Enter post title…"
        />
        <div className="d-flex mt-2 mb-1">
          <div className="fw-semibold">Post URL Slug</div>
          <button className="btn btn-link ms-2 p-0" onClick={() => setPostSlug(parameterize(postTitle))}>
            Set to title
          </button>
        </div>
        <input className="form-control form-control-lg"
          value={postSlug}
          onChange={(event) => setPostSlug(event.target.value)}
          placeholder="Enter post slug…"
        />
        <div className="fw-semibold mt-2 mb-1">SEO Description ({postSeoDescription?.length ?? 0} of ~150 characters)</div>
        <input className="form-control form-control-lg fs-sm"
          value={postSeoDescription}
          onChange={(event) => setPostSeoDescription(event.target.value)}
          placeholder="Enter description…"
        />
      </div>
      <div>
        {/* DragDropContext info is all courtesy of ChatGPT & react-beautiful-dnd */}
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="sections">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {post.sections.map((section, index) => (
                  <Draggable key={section.id} draggableId={String(section.id)} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className="bg-gray-200 my-3 position-relative"
                      >
                        <button className="btn btn-link text-danger fw-bold p-0 position-absolute" style={{top: 0, right: 0}}
                          onClick={() => deleteSection(section)}
                        >
                          <i className="ai-circle-minus fs-xxl" />
                        </button>
                        {ViewSectionContent(section)}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="mt-3">
          <button className="btn btn-irish-green w-100" onClick={() => setModal(Modal.AddBlockModal)}>
            Add block
          </button>
          <button className="btn btn-success w-100 mt-5" onClick={() => savePost()}>
            Save & update post
          </button>
        </div>
      </div>
    </>
  )
}

export default BlogPostManager;

const ViewTextInputDetails = ({onSave}: {onSave: SaveNewBlockFunction}) => {
  const [text, setText] = useState('');

  const classOptions = [
    { value: 'fs-md', label: 'Body text'},
    { value: 'h4 mb-0', label: 'Subtitle'}
  ]
  const [htmlClass, setHtmlClass] = useState(classOptions[0].value);

  function handleSave() {
    const newContent:BlogTextContent = {
      id: null,
      text: text,
      htmlClass: htmlClass
    }
    onSave(newContent, BlogContentType.TEXT);
  }

  return (
    <>
      <div className="fw-semibold">Text Type</div>
      <select className="form-select mt-1"
        value={htmlClass || ''}
        onChange={(e) => setHtmlClass(e.target.value)}
      >
        {classOptions.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="fw-semibold mt-2">Text</div>
      <textarea
        className="form-control mt-1"
        rows={5}
        placeholder="Enter the text you’d like in this block…"
        value={text}
        onChange={(event) => setText(event.target.value)}
      />

      <button className="btn btn-primary mt-2 w-100" onClick={handleSave}>
        Add block
      </button>
    </>
  )
}

const ViewQuoteInputDetails = ({onSave}: {onSave: SaveNewBlockFunction}) => {
  const [quote, setQuote] = useState('');
  const [author, setAuthor] = useState(null);

  function handleSave() {
    const newContent:BlogQuoteContent = {
      id: null,
      quote: quote,
      author: author
    }
    onSave(newContent, BlogContentType.QUOTE);
  }

  return (
    <>
      <div className="fw-semibold">Quote</div>
      <textarea
        className="form-control mt-1"
        rows={5}
        placeholder="Enter the quote you’d like in this block…"
        value={quote}
        onChange={(event) => setQuote(event.target.value)}
      />
      <div className="fw-semibold mt-2">Author (optional)</div>
      <input className="form-control mt-1"
        placeholder="If applicable, enter the name of the author of the quote"
        value={author}
        onChange={(event) => setAuthor(event.target.value)}
      />

      <button className="btn btn-primary mt-2 w-100" onClick={handleSave}>
        Add block
      </button>
    </>
  )
}

const ViewCtaInputDetails = ({onSave}: {onSave: SaveNewBlockFunction}) => {
  const [buttonText, setButtonText] = useState('');
  const [buttonUrl, setButtonUrl] = useState(null);
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);

  enum CtaType
    { BUTTON = 'Button'
    , BOX = 'Box'
    }

  const [ctaType, setCtaType] = useState(CtaType.BUTTON);

  function handleSave() {
    let newContent:BlogCtaContent

    switch (ctaType) {
      case CtaType.BUTTON:
        newContent = {
          id: null,
          containerClass: null,
          title: null,
          titleClass: null,
          body: null,
          bodyClass: null,
          buttonText: buttonText,
          buttonUrl: buttonUrl,
          buttonClass: 'btn btn-primary'
        }
        break;
      case CtaType.BOX:
        newContent = {
          id: null,
          containerClass: 'p-5 bg-primary text-center rounded-4',
          title: title,
          titleClass: 'h4 mb-0 text-white',
          body: body,
          bodyClass: 'fs-md my-2 text-white',
          buttonText: buttonText,
          buttonUrl: buttonUrl,
          buttonClass: 'btn btn-light'
        }
        break;
    }

    onSave(newContent, BlogContentType.CTA);
  }

  return (
    <>
      <div className="fw-semibold">CTA Type</div>
      <select className="form-select mt-1"
        value={ctaType}
        onChange={(e) => setCtaType(e.target.value as CtaType)}
      >
        {Object.values(CtaType).map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      {htmlIf(ctaType === CtaType.BOX,
        <>
          <div className="fw-semibold mt-2">Title Text</div>
          <input className="form-control mt-1"
            placeholder="What should the CTA title say?"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
          />
          <div className="fw-semibold mt-2">Body Text</div>
          <input className="form-control mt-1"
            placeholder="What should the CTA body say?"
            value={body}
            onChange={(event) => setBody(event.target.value)}
          />
        </>
      )}
      <div className="fw-semibold mt-2">Button Text</div>
      <input className="form-control mt-1"
        placeholder="What should the CTA button say?"
        value={buttonText}
        onChange={(event) => setButtonText(event.target.value)}
      />
      <div className="fw-semibold mt-2">Button Link</div>
      <input className="form-control mt-1"
        placeholder="Where should the button link to?"
        value={buttonUrl}
        onChange={(event) => setButtonUrl(event.target.value)}
      />

      <button className="btn btn-primary mt-2 w-100" onClick={handleSave}>
        Add block
      </button>
    </>
  )
}
