import * as React from 'react';
import { useState } from 'react';
import { htmlIf, formatMarkdown } from '../Utils/HTML';

type Props =
  { sections: Section[]
  }

type Section =
  { title: string
  , questions: Question[]
  }

type Question =
  { question: string
  , answer: string
  }

const FAQ = (props: Props) => {
  const [currentSection, setCurrentSection] = useState(props.sections[0]);

  const ViewQuestionCard = ({ question }: { question: Question }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
      <div className="card rounded-2 mb-2">
        <div className="card-body px-2 py-3">
          <div className="row cursor-pointer" onClick={() => setIsExpanded(!isExpanded)}>
            <div className="col d-flex align-items-center">
              <h6 className="fw-bold mb-0">{question.question}</h6>
            </div>
            <div className="col-auto text-end">
              <i className={"ai-chevron-" + (isExpanded ? "up" : "down")} />
            </div>
          </div>
          {htmlIf(isExpanded,
            <div className="fs-sm mt-2">
              {formatMarkdown(question.answer)}
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <div className="row">
      <div className="col-lg-4">
        <ul className="nav nav-tabs flex-column" role="tablist">
          {props.sections.map((section) =>
            <li className="nav-item" onClick={() => setCurrentSection(section)} key={section.title}>
              <a className={`cursor-pointer nav-link ${currentSection === section ? 'active' : ''}`} data-bs-toggle="tab" role="tab">
                {section.title}
              </a>
            </li>
          )}
        </ul>
      </div>
      <div className="col-lg-8">
        {currentSection.questions.map((question, index) =>
          <div key={`${currentSection}-${index}`}>
            <ViewQuestionCard key={`${currentSection}-${index}`} question={question} />
          </div>
        )}
      </div>
    </div>
  )
}

export default FAQ;
