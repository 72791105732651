import * as React from 'react';
import Select from 'react-select';
import { useState } from 'react';
import * as API from '../Utils/API';
import { Property } from '../Types/Property';
import { Tag, TagType, tagToReactSelectItem, reactSelectItemToTag } from '../Types/Tag';
import { htmlIf, formatMultiParagraphString } from '../Utils/HTML';
import * as Currency from '../Utils/Currency';
import * as Location from '../Utils/Location';

type Props =
  { property: Property
  , allTags: Tag[]
  , canEdit: Boolean
  }

type ReactSelectOption =
  { value: any
  , label: string
  }

const PropertyInfo = (props: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const [name, setName] = useState(props.property.name);
  const [country, setCountry] = useState(Location.countryAsSelectOption(props.property.country));
  const [location, setLocation] = useState(props.property.location);
  const [currency, setCurrency] = useState(Currency.asSelectOption(props.property.currency));
  const [bio, setBio] = useState(props.property.bio);
  const [website, setWebsite] = useState(props.property.website);

  const [technologyTags, setTechnologyTags] = useState(
    props.property.tags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))
  );
  const [supportTags, setSupportTags] = useState(
    props.property.tags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))
  );

  function savePropertyInfo() {
    const allSelectedTagItems = Array.from(new Set([...technologyTags, ...supportTags]));
    const tags: Tag[] = allSelectedTagItems.map((item) => (reactSelectItemToTag(item, props.allTags)));

    const postBody = {
      name: name,
      country: country.value,
      location: location,
      currency: currency.value,
      bio: bio,
      website: website,
      tags: tags
    }

    API.post("property_account_update_property_info_path", postBody).then(function (result) {
      setIsEditing(false)
    })
  }

  const viewValue = (value: string): React.ReactElement => (
    <>
      { value?.length > 0
      ? <div>{value}</div>
      : <div className="text-muted">Not set</div>
      }
    </>
  )

  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex align-items-center justify-content-between mb-3">
          <h3 className="mb-0">{`About ${name}`}</h3>
          {htmlIf(!isEditing && props.canEdit,
            <button onClick={() => setIsEditing(true)} className="btn btn-link d-flex">
              <i className="ai-edit-alt me-1"></i>
              Edit
            </button>
          )}
        </div>
        <div className="">
          <div className="mb-1 text-gray-900 fw-semibold">Name</div>
          { isEditing
          ? <input
              onChange={(e) => setName(e.target.value)}
              className="form-control"
              value={name}
            />
          : viewValue(name)
          }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Location</div>
          { isEditing
          ? <input
              onChange={(e) => setLocation(e.target.value)}
              className="form-control"
              value={location}
            />
          : viewValue(location)
          }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Country</div>
          { isEditing
          ? <Select
              className="rounded mt-1 t--country-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={Location.allCountriesAsSelectOptions()}
              onChange={(newCountry: ReactSelectOption) => setCountry(newCountry)}
              value={country}
              placeholder="Select the country you are based in…"
            />
          : viewValue(country.label)
          }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Currency</div>
          { isEditing
          ? <Select
              className="rounded mt-1 t--curency-select"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              options={Currency.allAsSelectOptions()}
              onChange={(newCurrency: ReactSelectOption) => setCurrency(newCurrency)}
              value={currency}
              placeholder="Select your preferred currency…"
            />
          : viewValue(currency.label)
          }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Website</div>
            { isEditing
            ? <input
                onChange={(e) => setWebsite(e.target.value)}
                className="form-control"
                value={website}
                placeholder="https://www.example.com"
              />
            : viewValue(website)
            }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Technologies</div>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.TECHNOLOGY).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newTechnologyTags: ReactSelectOption[]) => setTechnologyTags(newTechnologyTags)}
              value={technologyTags}
            />
          : <div>
              {technologyTags.map((tag) => (
                <div key={tag.label} className="badge bg-accent text-dark me-1 mb-1 d-inline-block">{tag.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Where We Need Support</div>
          { isEditing
          ? <Select
              className="rounded"
              classNames={{
                control: (state) => 'form-control p-1 rounded-2',
                multiValue: (state) => 'rounded-1',
                dropdownIndicator: (state) => 'py-0'
              }}
              isMulti={true}
              options={props.allTags.filter((tag) => tag.tagType === TagType.SUPPORT).map((tag) => tagToReactSelectItem(tag))}
              onChange={(newSupportTags: ReactSelectOption[]) => setSupportTags(newSupportTags)}
              value={supportTags}
            />
          : <div>
              {supportTags.map((tag) => (
                <div key={tag.label} className="badge bg-accent text-dark me-1 mb-1 d-inline-block">{tag.label}</div>
              ))}
            </div>
          }
        </div>
        <div className="mt-2">
          <div className="mb-1 text-gray-900 fw-semibold">Propety Bio</div>
          { isEditing
          ? <textarea
              onChange={(e) => setBio(e.target.value)}
              className="form-control"
              rows={5}
              placeholder='Help CoPilots get to know you…'
              maxLength={5000}
              value={bio}
            />
          : <div>{formatMultiParagraphString(bio)}</div>
          }
        </div>
        {htmlIf(!props.canEdit,
          <div className="fs-md text-secondary fw-bold mt-3">Note: Profile information can only be updated by team managers.</div>
        )}
        {htmlIf(isEditing,
          <button onClick={savePropertyInfo} className="btn btn-primary mt-3 px-3">Save</button>
        )}
      </div>
    </div>
  )
}

export default PropertyInfo;
