import * as React from 'react';
import AgentRequestCard from './Components/AgentRequestCard';
import { RequestCard } from '../Types/RequestCard';

type Props =
  { requests: RequestCard[]
  }

const StandaloneRequestCards = (props: Props) => (
  <div className="row mt-3">
    {props.requests.map((request) => (
      <AgentRequestCard
        request={request}
        key={`${request.requestType}-${request.requestId}`}
      />
    ))}
  </div>
)

export default StandaloneRequestCards;
