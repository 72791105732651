import Axios from "axios";

/**
  Posts JSON data to a Rails endpoint. This helper function should be used for all standard API `POST` requests.
  @param railsPath - A string representing a Rails route helper for the action you want to POST to.
  @param bodyJson - The dictionary or JSON body that should be sent in the POST request.
  @returns A Promise that resolves to the response data, or an error message if the request failed.
*/
export async function post(railsPath, bodyJson) {
  // Lucia.pathInfo is a function defined in `layouts/javascript/_client_api_endpoints.haml`. It checks to see
  // if the `railsPath` has been included on the page via the `client_side_endpoints` helper function in Rails.
  // If the path was included, it returns a hash, like so: { path: "path/to/url", token: "csrf-token-from-rails" }.
  // If the path was NOT included, it will log an error (and, if in development/test, display a big warning on the page).
  const endpoint = Lucia.pathInfo(railsPath)

  if (endpoint == null) {
    console.error("Missing client-side endpoint");
  }
  else {
    let result = await Axios.post(
      endpoint.path,
      bodyJson,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          // A CSRF token is required by Rails in order to verify the authenticity of the request.
          'X-CSRF-Token': endpoint.token
        }
      }
    ).then(function (response) {
      console.log('Request returned status code', response.status);
      return response.data
    }).catch(function (error) {
      console.log('There was an error making the request:', error.message);
      return { error: error.message }
    });

    return result
  }
}

/**
  Posts form data to a Rails endpoint. This helper function should only be used when sending form data —
  all other `POST` requests that send standard JSON data should use `API.post` instead.
  @param railsPath - The path to the Rails endpoint.
  @param formData - The form data to include in the request.
  @returns A promise that resolves to the data returned by the endpoint or an error object if the request fails.
*/
export async function postFormData(railsPath, formData) {
  const endpoint = Lucia.pathInfo(railsPath)

  if (endpoint == null) {
    console.error("Missing client-side endpoint");
  }
  else {
    let result = await Axios.post(
      endpoint.path,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          // A CSRF token is required by Rails in order to verify the authenticity of the request.
          'X-CSRF-Token': endpoint.token
        }
      }
    ).then(function (response) {
      console.log('Request returned status code', response.status);
      return response.data
    }).catch(function (error) {
      console.log('There was an error making the request:', error.message);
      return { error: error.message }
    });

    return result
  }
}

export function getEndpoint(railsPath) {
  if (typeof Lucia !== 'undefined') {
    return Lucia.pathInfo(railsPath);
  } else {
    // During server-side rendering, our pathInfo function may not yet be defined.
    return { path: '', token: '' };
  }
}
